import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  oldPass:string = '';
  newPass:string = '';
  confirmNewpass:string = '';

  loading$ = this.loader.loading$;
  constructor(public authService: AuthService,public loader: SpinnerService, private router: Router) { }

  ngOnInit(): void {
    
  }

  validateNewpassword(){
    if(this.newPass != this.confirmNewpass){
      this.resetNewPassFields(); 
      alert("New Passwords Don't Match!");
      return;
    }
    if(this.newPass == this.oldPass){
      this.resetNewPassFields(); 
      alert("Old password can't be the same as new password!");
      return;
    }
    if(this.newPass.length >= 8 && this.newPass.length <= 12){
      var errorMsg = '';

      if(!this.containsUpperCase(this.newPass)){
        errorMsg += "It does not contain any uppercase characters.\n";
      }
      if(!this.containsLowerCase(this.newPass)){
        errorMsg += "It does not contain any lowercase characters.\n";
      }
      if(!this.containsNumber(this.newPass)){
        errorMsg += "It does not contain any numeric characters.\n";
      }
      if(!this.containsSpecialChars(this.newPass)){
        errorMsg += "It does not contain any special characters.";
      }

      if(errorMsg === ''){
        this.updatePassword();
      } else {
        alert(errorMsg);
        return;
      }
    } else if(this.newPass.length < 8){
      alert("New Password is less than 8 characters!");
      return;
    } else {
      alert("New Password is greater than 12 characters!");
      return;
    }
  }

  resetNewPassFields(){
    this.newPass = '';
    this.confirmNewpass = '';
  }

  updatePassword(){
    this.authService.changePassword(this.oldPass,this.newPass).subscribe(
      (response: any) => {
        // console.log(response);
        this.authService.setPassExp('false');
        alert(response);
        this.router.navigateByUrl('home');
      },
      (error: HttpErrorResponse) => {
        if(error.status == 200){
          alert(error.error.text);
          this.authService.setPassExp('false');
          this.router.navigateByUrl('home');
        }else{
          alert(error.error);
        }
      }
    );
  }

  containsSpecialChars(str:string) {
    const specialChars = `\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`;
    const result = specialChars.split('').some(specialChar => {
      if (str.includes(specialChar)) {
        return true;
      }
      return false;
    });
    return result;
  }
  containsNumber(str:string) {
    return /\d/.test(str);
  }
  containsUpperCase(str:string) {
    return /[A-Z]/.test(str);
  }
  containsLowerCase(str:string) {
    return /[a-z]/.test(str);
  }
}
