import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Employer } from 'src/app/model/userManagement/employer';
import { UserManagement } from 'src/app/model/userManagement/user-management';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  private baseUrl = environment.apiBaseUrl + '/spring';

  constructor(private http: HttpClient) { }

  getUserInfo(): Observable<UserManagement>{
		// return this.http.get<any>(this.baseUrl + '/User/Employer/Info',{responseType: 'json'}).pipe(map((resp) => {
		// 	return resp;
		// }));
    return this.http.get<any>(`${this.baseUrl}/User/Employer/Info`);
	}
  getEmployerDetails(id:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('guid', id);
    return this.http.get<any>(`${this.baseUrl}/User/Employer/Details`,{ params: Params });
	}
  getRatesForLiaison(id:any,d:any,guid:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('erGuid', id);
    Params = Params.append('periodFrom', d);
    Params = Params.append('agreementGuid', guid);
    return this.http.get<any>(`${this.baseUrl}/User/Employer/Liaison/rates`,{ params: Params });
	}
  getUserReportOptions(id:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('guid', id);
    return this.http.get<any>(`${this.baseUrl}/User/Employer/ReportOptions`,{ params: Params });
	}
  getReportsInChunks(id:any,data:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('guid', id);
    Params = Params.append('data', data);
    return this.http.post<any>(`${this.baseUrl}/User/Employer/ReportOptions/GetReportList`,data);
	}
  getUserListReports(id:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('guid', id);
    return this.http.get<any>(`${this.baseUrl}/User/Employer/ListReports`,{ params: Params });
	}
  getUserRemittanceReport(agreementId:any,guid:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('guid', guid);
    Params = Params.append('agreementId',agreementId);
    return this.http.get<any>(`${this.baseUrl}/User/Employer/RemittanceReport`,{ params: Params });
    // return this.http.post<any>(`${this.baseUrl}/User/Employer/RemittanceReport`,id);
	}
  makeOOPDefaults(agreementId:any,guid:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('guid', guid);
    Params = Params.append('agreementId',agreementId);
    return this.http.get<any>(`${this.baseUrl}/User/Employer/makeOOPDefaults`,{ params: Params });
	}
  getUserMassImportInfo(id:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('guid', id);
    return this.http.get<any>(`${this.baseUrl}/User/Employer/getUserMassImportInfo`,{ params: Params });
	}
}
