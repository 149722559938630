<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>
<body>
    <h3>
        Webmaster
    </h3>
    <span class="pageHeader">Webmaster Utilities Processes</span>

    <p>Select an option to manually run processes to generate WebERF output files.&nbsp;The method of delivery is configured in the system parameters.</p>


    <ul>
		<li type="disc">
            <!-- <a href="javascript:activate('AdminFundScheduler');">Manually generate and send WebERF Reports Export File</a> -->
            <button mat-raised-button color="primary" (click)="activate('AdminFundScheduler');">Manually generate and send WebERF Reports Export File</button>
        </li> 
		<!-- <li type="disc"><a href="javascript:activate('AdminPOAScheduler');">Manually generate and send WebERF POA Export File</a></li>   -->
        <li type="disc">
            <button mat-raised-button color="primary" (click)="activate('AdminPOAScheduler');">Manually generate and send WebERF POA Export File</button>
        </li>
        <ng-container *ngIf="systemParameters[0] != null && systemParameters[0] != undefined && isAffirmative(systemParameters[0].parameterValue1!)">
            <!-- <li type="disc"><a href="javascript:activate('AdminEFTScheduler');">Manually generate and send EFT File</a></li> -->
            <li type="disc">
                <button mat-raised-button color="primary" (click)="activate('AdminEFTScheduler');">Manually generate and send EFT File</button>
            </li>
        </ng-container>
		<li type="disc">
            <button mat-raised-button color="primary" (click)="getHolidays()">Setup/Maintain Holiday Calendar</button>
        </li>
	</ul>
</body>



<!-- Process Results Modal -->
<ng-template #processModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Webmaster Utility Process Confirmation</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Process has completed.</p>
        <ul>
            <!-- <ng-container *ngIf="reportResults != null && reportResults.key.length > 0;else email"> -->
            <ng-container *ngIf="reportResults != null;else email">
                <ng-container *ngFor="let msg of reportResults | keyvalue: asIsOrder; let i = index">
                    <li>{{msg.value}}</li>
                </ng-container>
            </ng-container>
        </ul>
        <ng-template #email>
            <p>An email containing additional details has been sent to the Administrator.</p>
        </ng-template>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>

<!-- Holiday Modal -->
<ng-template #holidayModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Holiday Setup</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body flex-container">
        <div class="cal flex-child">
            <mat-card class="holiday-inline-calendar-card">
                <mat-calendar [dateClass]="dateClass" [(selected)]="selected"></mat-calendar>
            </mat-card>
            <p style="color: blue;"><span style="font-weight: bold;">Selected date:</span> {{selected | date:"fullDate"}}</p>
            <ng-container *ngIf="selected != null">
                <button mat-raised-button color="primary" (click)="addHoliday()">Add Holiday</button>
            </ng-container>
        </div>
        <div class="list flex-child">
            <ng-container *ngFor="let d of holidayList;let i = index">
                <!-- <label>
                    <button mat-icon-button color="warn" aria-label="Example icon button with a heart icon">
                        <mat-icon>delete</mat-icon>
                    </button>
                    {{d.holiday | date:"fullDate"}}
                </label>
                <br> -->
                <button mat-icon-button color="warn" (click)="deleteHoliday(d)">
                    <mat-icon>delete</mat-icon>
                </button>
                {{d.holiday.dateString | date:"fullDate"}}
                <br>
            </ng-container>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>
