import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModalOptions, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TermsofuseModule } from 'src/app/model/termsofuse/termsofuse.module';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { ProcessesService } from 'src/app/services/webmaster/processes.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  modalOptions: NgbModalOptions;
  closeResult: string = '';
  @ViewChild('createTerms') createTerms : any;

  
  displayedColumns: string[] = ['id','title','inUse'];
  dataSource = new MatTableDataSource<any>([]);
  originalList:any;
  termsObject:any;

  loading$ = this.loader.loading$;
  constructor(public loader: SpinnerService, private modalService: NgbModal, public authService: AuthService, private processesService: ProcessesService) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      size:'xl',
    };
   }

  ngOnInit(): void {
    this.loadData();
  }


  loadData(){
    this.processesService
      .getAllTermsOfUse()
      .subscribe(
        (response: any) => {
          console.log(response);
          this.dataSource.data = response.result;
          this.originalList = JSON.parse(JSON.stringify(this.dataSource.data));
          this.authService.refreshToken().subscribe((result)=> {}, () => {});
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      });
  }

  // Set data used to save informatino
  editTerms(row:any){
    this.termsObject = row;

    this.open(this.createTerms);
  }
  saveTermsOfUse(){
    if(!this.validation()){
      return;//Bad Data
    }
    this.processesService
      .saveTermsOfUse(this.termsObject)
      .subscribe(
        (response: any) => {
          this.modalService.dismissAll();
          this.loadData();
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      });
  }
  validation(){
    if(this.termsObject.title == "" || this.termsObject.title == null) {
      alert("Title is required.");
      return false;
    }
    if(this.termsObject.paragraph == "" || this.termsObject.paragraph == null) {
      alert("Body is required.");
      return false;
    }
    return true;
  }
  clearData(){
    this.termsObject = new TermsofuseModule();
  }
  closeModal(){
    for(var i = 0; i < this.originalList.length; i++){
      if(this.originalList[i].guid == this.termsObject.guid){
        if(this.originalList[i].footer != this.termsObject.footer ||
          this.originalList[i].title != this.termsObject.title ||
          this.originalList[i].paragraph != this.termsObject.paragraph ||
          this.originalList[i].inUse != this.termsObject.inUse ||
          this.originalList[i].obsolete != this.termsObject.obsolete){
          this.loadData();
        }
      }
    }
  }
  obsoleteTerms(){
    if(confirm("This will delete the Terms Of Use. Proceed to delete?")){
      this.termsObject.obsolete = true;
      this.termsObject.inUse = false;
      this.saveTermsOfUse();
    }
  }




























  open(content: any) {
    console.log('open');
    this.modalService.open(content, this.modalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}