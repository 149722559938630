import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { AngularMaterialModule } from '../angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { LoginComponent } from './layout/body/auth/login/login.component';
import { PageNotFoundComponent } from './layout/body/error/page-not-found/page-not-found.component';
import { HomeComponent } from './layout/body/home/home.component';
import { SystemParametersComponent } from './layout/body/system-parameters/system-parameters.component';

import { HttpInterceptorService } from './services/auth/http-interceptor.service';
import { ReportOptionsComponent } from './layout/body/report-options/report-options.component';
import { ListReportsComponent } from './layout/body/list-reports/list-reports.component';
import { RemittanceReportComponent } from './layout/body/remittance-report/remittance-report.component';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { IsLoadingComponent } from './layout/common/isLoading/is-loading/is-loading.component';
import { EmployeeDemographicsComponent } from './layout/body/employee-demographics/employee-demographics.component';
import { CreateProjectComponent } from './layout/body/project/create/create-project/create-project.component';
import { EditProjectComponent } from './layout/body/project/edit/edit-project/edit-project.component';
import { SearchEmployersComponent } from './layout/body/search-employers/search-employers/search-employers.component';
import { ChangePasswordComponent } from './layout/body/change-password/change-password/change-password.component';
import { AdministrativeReportsComponent } from './layout/body/administrative-reports/administrative-reports/administrative-reports.component';
import { StaffUserAccountsComponent } from './layout/body/setup-edit/set-edit/staff-user-accounts/staff-user-accounts.component';
import { EmployerEftAccountDetailsComponent } from './layout/body/setup-edit/set-edit/employer-eft-account-details/employer-eft-account-details.component';
import { ProcessesComponent } from './layout/body/webmaster/utilities/processes/processes.component';
import { DatabaseComponent } from './layout/body/webmaster/utilities/database/database.component';
import { CreateEditComponent } from './layout/body/webmaster/announcements/create-edit/create-edit.component';
import { CensusImportComponent } from './layout/body/census-import/census-import.component';
import { SsnPipe } from './pipes/ssn.pipe';
import { MassImportComponent } from './layout/body/report-options/mass-import/mass-import.component';
import { SpecialMappingsComponent } from './layout/body/specialMappings/special-mappings/special-mappings.component';
import { ContactUsComponent } from './layout/body/contact-us/contact-us.component';
import { DefaultComponent } from './layout/body/liaison/default/default.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { ViewListComponent } from './layout/body/project/view/view-list/view-list.component';
import { ManageStaffComponent } from './layout/body/manage-staff/manage-staff/manage-staff.component';
import { TermsOfUseComponent } from './layout/body/webmaster/TermsOfUse/terms-of-use/terms-of-use.component';
import { AcceptTermsOfUseComponent } from './layout/body/auth/accept-terms-of-use/accept-terms-of-use.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    PageNotFoundComponent,
    HomeComponent,
    SystemParametersComponent,
    ReportOptionsComponent,
    ListReportsComponent,
    RemittanceReportComponent,
    PhoneNumberPipe,
    IsLoadingComponent,
    EmployeeDemographicsComponent,
    CreateProjectComponent,
    EditProjectComponent,
    SearchEmployersComponent,
    ChangePasswordComponent,
    AdministrativeReportsComponent,
    StaffUserAccountsComponent,
    EmployerEftAccountDetailsComponent,
    ProcessesComponent,
    DatabaseComponent,
    CreateEditComponent,
    CensusImportComponent,
    SsnPipe,
    MassImportComponent,
    SpecialMappingsComponent,
    ContactUsComponent,
    DefaultComponent,
    ViewListComponent,
    ManageStaffComponent,
    TermsOfUseComponent,
    AcceptTermsOfUseComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    NgxPaginationModule,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
