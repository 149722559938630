<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>
<body>

    <span class="pageHeader">Change Password</span>

    <p>You can change your Password any time you wish. Your new Password will take effect the next time you login.</p>
    <p>Enter your old and new Passwords in the fields below and then click the Update button. Fields marked with an asterisk
        (&#8220;<span class="required">*</span>&#8221;) are required.</p>
    <h4 class="tight">Passwords:</h4>
    <ul class="tight">
        <li><strong>are</strong> case sensitive</li>
        <li><strong>must</strong> be a minimum length of 8 characters and a maximum length of 12 characters.</li>
        <li><strong>must</strong> contain at least one numeral.</li>
        <li><strong>must</strong> contain at least one upper and one lower case letter.</li>
        <li><strong>must</strong> contain at least one special character (&#36;, &#37;, &#38;, etc.)</li>
        <li><strong>must not</strong> be the same as a previous password.</li>
    </ul>
    <p>Your new password will take effect the next time you login.</p>


    <div class="tbldiv">
        <table class="tbl">
            <tr>
                <td style="padding-top: 10px;" class="right nowrap"><label for="oldPW">Old Password<span class="required">*</span>:</label>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td class="nowrap">
                    <input type="password" property="oldPassword" size="14" maxlength="12" title="Enter old Password" tabindex="4"
                        errorStyleClass="boxoutline" [(ngModel)]="oldPass" />&nbsp;&nbsp;
                    <!-- <html:errors property="oldPassword" header="empty" footer="empty" /> -->
                </td>
            </tr>
            <tr>
                <td class="right nowrap"><label for="newPW">New Password<span class="required">*</span>:</label>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td class="nowrap">
                    <input type="password" property="newPassword" size="14" maxlength="12" title="Enter new Password" tabindex="5"
                        errorStyleClass="boxoutline" [(ngModel)]="newPass"/>&nbsp;&nbsp;
                    <!-- <html:errors property="newPassword" header="empty" footer="empty" /> -->
                </td>
            </tr>
            <tr>
                <td class="right nowrap"><label for="confirmPW">Confirm Password<span class="required">*</span>:</label>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td class="nowrap">
                    <input type="password" property="confirmPassword" size="14" maxlength="12"
                        title="Enter new Password again to confirm" tabindex="6" errorStyleClass="boxoutline" [(ngModel)]="confirmNewpass"/>&nbsp;&nbsp;
                    <!-- <html:errors property="confirmPassword" header="empty" footer="empty" /> -->
                </td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            <tr>
                <td colspan="4" class="center" style="text-align: center; padding-bottom: 10px;">
                    <button color="primary" mat-raised-button (click)="validateNewpassword()">Update Password</button>
                    <!-- <html:submit property="updateLogin" value="Update" styleClass="btn"
                        onclick="return checkUserPasswordOnClick();" title="Update Password" tabindex="7" /> -->
                </td>
            </tr>
        </table>
    </div>
</body>