export class TrustFund {
    public adminServiceEnvGUID: number | null;
    public createdByGUID: number | null;
    public createdByID: string | null;
    public createdDate: string | null;
    public displayGroup: any | null;
    public displayOrder: number | null;
    public erfDisplayGroup: string | null;
    public federalTIN: string | null;
    public fundColumnHeading: string | null;
    public fundLegalName: string | null;
    public fundRules: any | null;
    public fundShortName: string | null;
    public fundType: any | null;
    public guid: number | null;
    public lastUpdateByGUID: number | null;
    public lastUpdateByID: string | null;
    public lastUpdateDate: string | null;
    public mainFund: string | null;
    public mid: string | null;
    public obsolete: boolean | null;
    public subFunds: any | null;
    public surchargeFunds: any | null;
    public updateRequired: boolean | null;
    public version: number | null;

    constructor(adminServiceEnvGUID: number | null,createdByGUID: number | null,createdByID: string | null,createdDate: string | null,displayGroup: any | null,displayOrder: number | null,
        erfDisplayGroup: string | null,federalTIN: string | null,fundColumnHeading: string | null,fundLegalName: string | null,fundRules: any | null,fundShortName: string | null,
        fundType: any | null,guid: number | null,lastUpdateByGUID: number | null,lastUpdateByID: string | null,lastUpdateDate: string | null,mainFund: string | null,mid: string | null,
        obsolete: boolean | null,subFunds: any | null,surchargeFunds: any | null,updateRequired: boolean | null,version: number | null){
            this.adminServiceEnvGUID = adminServiceEnvGUID;
            this.createdByGUID = createdByGUID;
            this.createdByID = createdByID;
            this.createdDate = createdDate;
            this.displayGroup = displayGroup;
            this.displayOrder = displayOrder;
            this.erfDisplayGroup = erfDisplayGroup;
            this.federalTIN = federalTIN;
            this.fundColumnHeading = fundColumnHeading;
            this.fundLegalName = fundLegalName;
            this.fundRules - fundRules;
            this.fundShortName = fundShortName;
            this.fundType = fundType;
            this.guid = guid;
            this.lastUpdateByGUID = lastUpdateByGUID;
            this.lastUpdateByID = lastUpdateByID;
            this.lastUpdateDate = lastUpdateDate;
            this.mainFund = mainFund;
            this.mid = mid;
            this.obsolete = obsolete;
            this.subFunds = subFunds;
            this.surchargeFunds = surchargeFunds;
            this.updateRequired = updateRequired;
            this.version = version;
    }
}
