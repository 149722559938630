<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>
<body *ngIf="employer" class="pageBody">
    <h3>{{employer.organizationName}} : {{employer.organizationID}}</h3>
    <span class="pageHeader">Create&#47;Edit Report</span>

    <table>
        <tr>
            <td class="detailsPadding"><strong>Employer ID:</strong>&nbsp;{{employer.organizationID}}</td>
            <td class="detailsPadding"><strong>Address 1:</strong>&nbsp;{{employerAddress ? employerAddress.addressLine1: null}}</td>
            <td class="detailsPadding"><strong>Report Period:</strong>&nbsp; {{employerReport.workPeriodBegin.substring(5,7) + '/' + employerReport.workPeriodBegin.substring(8,10) + '/' + employerReport.workPeriodBegin.substring(0,4)}} </td>
        </tr>
        <tr>
            <td class="detailsPadding"><strong>Name:</strong>&nbsp;{{employer.organizationName}}</td>
            <td class="detailsPadding"><strong>Address 2:</strong>&nbsp;{{employerAddress ? employerAddress.addressLine2: null}}</td>
            <td class="detailsPadding"><strong>Agreement:</strong>&nbsp;{{agreement.agreement.cbaName}}{{setAgreementLineEnd(agreement)}}</td>
        </tr>
        <tr>
            <td class="detailsPadding"><strong>Federal ID Number:</strong>&nbsp;{{employer.federalEIN}}</td>
            <td class="detailsPadding"><strong>Address 3:</strong>&nbsp;{{employerAddress ? employerAddress.addressLine3: null}}</td>
            <td class="detailsPadding"><strong>Report Type:</strong>&nbsp;{{employerReport.erfType.name}}</td>
        </tr>
        <tr>
            <td class="detailsPadding"><strong>EDI Reference Number:</strong>&nbsp;
                <ng-container *ngIf="ediRefNo">
                    employerReport.ediRefNo
                </ng-container>
                <ng-container *ngIf="!ediRefNo">
                    Report Not Yet Finalized
                </ng-container>
            </td>
            <td class="detailsPadding"><strong>City, State, Zip Code:</strong>&nbsp;{{employerAddress ? employerAddress.city: null}}, {{employerAddress ? employerAddress.state: null}} {{employerAddress ? employerAddress.zipPostalCode: null}}</td>
            <td class="detailsPadding">
                    <strong>Job Class Type:</strong>&nbsp;
                    <ng-container *ngIf="!!employerReport.jobClassType; else jc">
                        {{employerReport.jobClassType.jcTypeName}}
                    </ng-container>
                    <ng-template #jc>
                        All
                    </ng-template>
            </td>
        </tr>
        <tr>
            <td class="detailsPadding"></td>
            <td class="detailsPadding"><strong>Phone:</strong>&nbsp;{{(employerPhone && employerPhone.phoneNumber) ? ((employerPhone.phoneNumber.areaCode + employerPhone.phoneNumber.localNumber) | phoneNumber) : null}}
                &nbsp;&nbsp;<strong>Ext:</strong>&nbsp;{{(employerPhone && employerPhone.phoneNumber) ? employerPhone.phoneNumber.extension : null}}
            </td>
            <td class="detailsPadding">
                <ng-container *ngIf="!!employerReport.contributionReportId">
                    <strong>Employer Report Form ID:</strong>&nbsp;
                    {{employerReport.contributionReportId}}
                </ng-container>
            </td>
        </tr>
        <!-- <tr>
            <td class="detailsPadding"></td>
            <td class="detailsPadding"><strong>Fax:</strong>&nbsp;{{(employerPhone && employerPhone.faxNumber) ? ((employerPhone.faxNumber.areaCode + employerPhone.faxNumber.localNumber) | phoneNumber) : null}}</td>
            <td class="detailsPadding"></td>
        </tr> -->
    </table>
    <ng-container *ngIf="employerReport.erfType.code === 'N';else NCH">
        <div class="center">
			<strong>Entry of employees is not permitted on a &#8220;
                <span class="EDIref" style="color: #0000ee;">
                    {{employerReport.erfType.name}}
                </span>&#8221; report form.</strong>
            </div>
        <!-- Action Buttons -->
        <table class="actionButtons" style="margin-bottom: 5px;">
            <button class="spaceBtns raisebtn" color="warn" mat-raised-button matTooltip="Delete report" (click)="open(deleteReportModal);" [disabled]="employerReport.massImportInfo != null">Delete Rpt</button>
            <button class="spaceBtns raisebtn" color="primary" mat-raised-button matTooltip="Pend report" (click)="validation('PEND');">Pend</button>
            <button class="spaceBtns raisebtn" color="primary" mat-raised-button matTooltip="Calculate amounts due" (click)="validation('CALCULATE')">Calculate</button>
        </table>
    </ng-container>
    <ng-template #NCH>

        <!-- EMPLOYER NOTES & SORT ORDER & DEFAULTS -->
        <table style="background-color: lightgray; border-radius: 4px; width: 100%;">


            <mat-grid-list cols="3" rowHeight="50px" class="topData">
                <mat-grid-tile
                    *ngFor="let tile of tiles"
                    [colspan]="tile.cols"
                    [rowspan]="tile.rows">
                    <!-- [style.background]="tile.color" -->
                    <ng-container *ngIf="tile.text == 'One'">
                        <tr>
                            <ng-container *ngIf="employerReport.firstReportInArea == true;else firstReportInArea">
                                <mat-checkbox color="primary" checked="true" (change)="changeFirstBool()"> 1st Report in Area </mat-checkbox>
                            </ng-container>
                            <ng-container *ngIf="employerReport.lastReportInArea == true;else lastReportInArea">
                                <mat-checkbox color="primary" class="example-margin" checked="true" (change)="changeLastBool()"> Last Report in Area </mat-checkbox>
                            </ng-container>
                            <ng-container *ngIf="employerReport.isSupplementalReport == 'Y';else isSupplementalReport">
                                <mat-checkbox color="primary" class="example-margin" checked="true" (change)="changeSupReport()"> Supplemental Report </mat-checkbox>
                            </ng-container>
                            <ng-template #firstReportInArea>
                                <mat-checkbox color="primary" (change)="changeFirstBool()"> 1st Report in Area </mat-checkbox>
                            </ng-template>
                            <ng-template #lastReportInArea>
                                <mat-checkbox color="primary" class="example-margin" (change)="changeLastBool()"> Last Report in Area </mat-checkbox>
                            </ng-template>
                            <ng-template #isSupplementalReport>
                                <mat-checkbox color="primary" class="example-margin" (change)="changeSupReport()"> Supplemental Report </mat-checkbox>
                            </ng-template>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="tile.text == 'Two'">
                        <ng-container *ngIf="systemParameters[0] != null && systemParameters[0] != undefined && isAffirmative(systemParameters[0].parameterValue1!)">
                            <div class="maxWidth pright">
                                <div>
                                    <label>Employer Notes (maximum of 250 characters)</label>
                                </div>
                                <div>
                                    <!-- <input type="text" property="employerNotes"  maxlength="250" [(ngModel)]="employerReport.employerNotes"/> -->
                                    <textarea name="Text1" class="maxWidth" rows="4" maxlength="250" [(ngModel)]="employerReport.employerNotes"></textarea>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="tile.text == 'Three'">
                        <tr>
                            <td class="right nowrap"><strong>Sort Order</strong></td>
                            <td style="padding: 0px 0px 0px 20px;">First:&nbsp;&nbsp;
                                <select (change)="selectSort1Change(sort1);checkSorter();" name="sort1" [(ngModel)]="sort1">
                                    <option *ngFor="let item of erfSortOrder" [ngValue]="item.guid">
                                        {{ item.name }}
                                    </option>
                                </select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Second:&nbsp;&nbsp;
                                <select (change)="selectSort2Change(sort2);checkSorter();" name="sort2" [(ngModel)]="sort2">
                                    <option *ngFor="let item of erfSortOrder" [ngValue]="item.guid">
                                        {{ item.name }}
                                    </option>
                                </select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Third:&nbsp;&nbsp;
                                <select (change)="selectSort3Change(sort3);checkSorter();" name="sort3" [(ngModel)]="sort3">
                                    <option *ngFor="let item of erfSortOrder" [ngValue]="item.guid">
                                        {{ item.name }}
                                    </option>
                                </select>
                            </td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="tile.text == 'Four'">
                        <tr>
                            <th>Set Defaults</th>
                        
                            <td [ngClass]="isColumnHidden('workingLocal',true)">
                                <tr>Local</tr>
                                <ng-container *ngIf="!blankErfForm.showUnionLocalList">
                                    <input name="blankErfForm" property="defaultLocal" size="5" maxlength="10" readonly="true" tabindex="99" />
                                </ng-container>
                                <ng-container *ngIf="blankErfForm.showUnionLocalList">
                                    <select name="blankErfForm" property="defaultLocal">
                                        <option value="-"></option>
                                        <ng-container *ngFor="let local of blankErfForm.unionLocals; let i = index">
                                            <option [ngValue]="local.guid">{{local.organizationID}}</option>
                                        </ng-container>
                                    </select>
                                </ng-container>
                            </td>
                        
                            <td class="defaultJobClass" *ngIf="blankErfForm.jobClassList.length > 1">
                                <tr>Job Class</tr>
                                <select name="defaultJobClass" id="defaultJC" [(ngModel)]="defaultJob">
                                    <option value="-"></option>
                                    <ng-container *ngFor="let job of blankErfForm.jobClassList; let i = index">
                                        <option [ngValue]="job.guid">{{jcdisplayoption(job)}}</option>
                                    </ng-container>
                                </select>
                            </td>
                        
                            <td class="defaultProject" *ngIf="employerReport.erCba.projects.length > 1">
                                <tr style="white-space: nowrap;">
                                    Project
                                    <ng-container
                                        *ngIf="isAffirmative(systemParameters[2].parameterValue1!) && (employerReport.erCba.signatoryType == null || employerReport.erCba.signatoryType.parameterValue3 == null || isAffirmative(employerReport.erCba.signatoryType.parameterValue3))">
                                        <!-- (<a href="#" onclick="submitNewProj()" title="Create new Employer Project">new</a>) -->
                                        (<button mat-button color="primary" (click)="open(createprojectModal)" title="Create new Employer Project" style="display: contents;line-height: inherit;">new</button>)
                                    </ng-container>
                                </tr>
                        
                                <ng-container
                                    *ngIf="(employerReport.erCba.signatoryType != null) && (isMatch(employerReport.erCba.signatoryType.parameterValue1,'SINGLE') || isMatch(employerReport.erCba.signatoryType.parameterValue1,'APPROVAL')); else pc">
                                    <select name="defaultProject" id="defaultProject" [(ngModel)]="defaultProject">
                                        <option value="0">-</option>
                                    </select>
                                </ng-container>
                                <ng-template #pc>
                                    <select name="defaultProject" id="defaultProject" [(ngModel)]="defaultProject">
                                        <option value="0">-</option>
                                        <ng-container *ngFor="let project of employerReport.erCba.projects;let i = index">
                                            <option value="{{project.guid}}">{{project.employerProjectID}}</option>
                                        </ng-container>
                                    </select>
                                </ng-template>
                            </td>
                        <!-- TODO:???? -->
                            <!-- <td class="clsEeCraft">
                                            <tr>EE Class</tr>
                                            <select name="blankErfForm" property="defaultCraft" [(ngModel)]="defaultCraft">
                                                <nested:optionsCollection name="blankErfForm" property="eeCraftList" value="value" label="label" /> 
                                            </select>
                                        </td> -->
                            <td class="pleft" [ngClass]="isColumnHidden('hoursClass',true)" *ngIf="blankErfForm.allowedHoursClasses.length > 1">
                                <tr>Hrs Class</tr>
                                <select name="blankErfForm" property="defaultHoursClassGuid" [(ngModel)]="defaultHoursClassGuid" (change)="setDefaultValues()">
                                    <option value="-"></option>
                                    <ng-container *ngFor="let hc of blankErfForm.allowedHoursClasses;let i = index">
                                        <option value="{{hc.guid}}">{{hc.parameterDesc}}</option>
                                    </ng-container>
                                </select>
                            </td>
                        </tr>
                    </ng-container>
                </mat-grid-tile>
              </mat-grid-list>

            <!-- if required by report format, special fund display -->
            <!-- TODO:  1783,1852-->
            <ng-container *ngIf="blankErfForm">
                <div class="specfund">
                    <p class="defaults">
                        <!-- <span class="sectionlabel">&nbsp;Report <%=""+session.getAttribute("EFFundName")%>?</span><br /><br />
                        &nbsp;&nbsp;<input type="radio" name="erFlatRate" value="yes" id="errateyes" <%=yesSelected%> />
                        <label for="errateyes">Yes</label>&nbsp;
                        <input type="radio" name="erFlatRate" value="no" id="errateno" <%=noSelected%> />
                        <label for="errateno">No</label> -->
                    </p>
                </div> 
            </ng-container>
            <!-- Select Rates // editblankerform -> 1884, 2717-2743 -->
            <ng-container *ngIf="blankErfForm.hasSelectRate">
                <div class="entryform">
                    <ng-container *ngFor="let sr of employerReport.selectRates">
                        <tr>
                            <td>{{sr.trustFund.fundLegalName}} ({{sr.trustFund.fundShortName}}):&nbsp;</td>
                            <ng-container *ngFor="let srv of sr.rateValues">
                                <td>
                                    <input type="radio" name="selRate{{sr.trustFund.guid}}" [value]="srv.rate" [id]="'selRate'+sr.trustFund.guid+''+srv.rate" />
                                    <label for="'selRate'+{{sr.trustFund.guid}}+''+srv.rate">{{srv.rate}}</label>&nbsp;
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </div>
            </ng-container>
            <!-- end special fund display -->
        
                <ng-container *ngIf="employerReport.erfFormat.allowDavisBacon">
                    <div class="specfund">
                        <p class="defaults"><span class="sectionlabel">Davis&#45;Bacon Hours</span><br /><br />
                        Court rulings require that the Pacific Northwest Regional Council trace dues deductions based on whether hours were worked on Federal Davis&#45;Bacon jobs versus all other projects.<br /><br />
                        Please record the total hours on Davis&#45;Bacon jobs for fund and dues purposes in the space to the right.&nbsp;&nbsp;&nbsp;
                        {{employerReport.davisBacon}}
                        <!-- <nested:text property="employerReport.davisBacon" styleClass="numeric"size="8" maxlength="8" /> -->
                    </p>
                        </div> <!-- end special fund -->
                </ng-container>

            <!-- Employees section title -->
            <tr>
                <th class="pleft pright">
                    <span class="sectionlabel">Employees</span> (fields marked with an asterisk (&#8220;<span class="required">*</span>&#8221;) are required)<span class="sectionlabel">
                    <ng-container *ngIf="ownerOperatorActive && systemParameters[4].parameterValue1! != undefined && systemParameters[4].parameterValue1! != 'undefined'">
                        <br/>
                        <span style="color: #8aa6bd;">Edits are restricted on {{systemParameters[4].parameterValue1!}} row(s)</span>
                    </ng-container>
                </span>
                    <!-- Action Buttons -->
                    <table class="actionButtons" style="margin-bottom: 5px;">
                        <button class="spaceBtns raisebtn" color="warn" mat-raised-button matTooltip="Delete report" (click)="open(deleteReportModal);" [disabled]="employerReport.massImportInfo != null">Delete Rpt</button>
                        <button class="spaceBtns raisebtn" color="accent" mat-raised-button matTooltip="Clear all numeric values" (click)="open(clearModal)">Clr Form</button>
                        <button class="spaceBtns raisebtn" color="accent" mat-raised-button matTooltip="Update data from import file" (click)="saveBeforeImport()">Import</button>
                        <button class="spaceBtns raisebtn" color="primary" mat-raised-button matTooltip="Re-sort data" (click)="validation('SAVE')">Re-Sort</button>
                        <button class="spaceBtns raisebtn" color="primary" mat-raised-button matTooltip="Pend report" (click)="validation('PEND');">Pend</button>
                        <button class="spaceBtns raisebtn" color="primary" mat-raised-button matTooltip="Save rows" (click)="validation('SAVE')">Save</button>
                        <button class="spaceBtns raisebtn" color="primary" mat-raised-button matTooltip="Calculate amounts due" (click)="validation('CALCULATE')">Calculate</button>
                    </table>
                </th>
            </tr>
            <!-- end of Employees section title -->
        </table>


        <!-- Report Table -->

        <div class="tableData" *ngIf="displayedColumnsData.length > 0">
            
            <mat-table [dataSource]="dataSource">
                <ng-container [matColumnDef]="column.name" *ngFor="let column of initColumnsData" [sticky]="isSticky(column.name)">
        
                    <mat-header-cell *matHeaderCellDef> {{column.display}} <span class="required" style="font-size: large;" *ngIf="column.required">*</span></mat-header-cell>
                    <!-- This is for Demographics Button -->
                    <ng-container *ngIf="column.name == 'Demographics'">
                        <mat-cell *matCellDef="let row; let i = index" style="text-align: center;">
                            <button mat-mini-fab color="warn" [disabled]="row.ownerOperator || checkDisableInputs(row,i)"
                                (click)="setDemoEmpData(row,i);open(demographicsModal)" color="primary" aria-label="Delete Data">
                                <mat-icon>person_outline</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <!-- This is for Delete Button -->
                    <ng-container *ngIf="column.name == 'obsolete'">
                        <mat-cell *matCellDef="let row; let i = index">
                            <button mat-icon-button color="warn" [disabled]="row.ownerOperator || checkDisableInputs(row,i) || checkRemoveDisabled(row)"
                                (click)="setDeleteEmpData(row,i);open(deleteModal)" color="warn" aria-label="Delete Data">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <!-- This is for JobClass -->
                    <ng-container *ngIf="column.name == 'jobClass'">
                        <mat-cell *matCellDef="let row; let i = index">
                            <select [id]="column.name+i" (change)="changeSelectField(column.name+i,row,'JobClassVO')" class="selectList" (focusout)="checkAddRow(column.name,i,row)" [disabled]="row.ownerOperator || checkDisableInputs(row,i)">
                                <!-- <ng-container *ngIf="!column.required"> -->
                                    <option value="-"></option>
                                <!-- </ng-container> -->

                                <ng-container *ngFor="let job of blankErfForm.jobClassList; let i = index">
                                    <option [ngValue]="job.guid" [selected]="getSelectedValue(job.parameterDesc, row[column.name],'JobClassVO')">{{jcdisplayoption(job)}}</option>
                                </ng-container>
                            </select>
                        </mat-cell>
                    </ng-container>
                    <!-- This is for Project -->
                    <ng-container *ngIf="column.name == 'projectID'">
                        <mat-cell *matCellDef="let row; let i = index">
                            <ng-container *ngIf="row[column.name] != null;else projectListClass">
                                <input [id]="column.name+i" autocomplete="off" type="text" list="projects" value="{{row[column.name]}}" [(ngModel)]="row[column.name]" (change)="changeProject(column.name+i,row)"/>
                                <!-- <input [id]="column.name+i" (focusout)="checkAddRow(column.name,i,row)" type="text" list="projects" value="{{row[column.name]}}" [(ngModel)]="row[column.name]"/> -->
                            </ng-container>
                            <ng-template #projectListClass>
                                <input [id]="column.name+i" autocomplete="off" type="text" list="projects" value="-"[disabled]="checkDisableInputs(row,i)" (change)="changeProject(column.name+i,row)"/>
                                <!-- <input [id]="column.name+i" (focusout)="checkAddRow(column.name,i,row)" type="text" list="projects" value="-"[disabled]="checkDisableInputs(row)" /> -->
                            </ng-template>
                            <datalist id="projects">
                                <option value="-"></option>
                                <ng-container *ngFor="let project of employerReport.erCba.projects; let i = index">
                                    <option value="{{project.employerProjectID}}">
                                </ng-container>
                            </datalist>
                        </mat-cell>
                    </ng-container>
                    <!-- This is for HoursClass -->
                    <ng-container *ngIf="column.name == 'hoursClass'">
                        <mat-cell *matCellDef="let row; let i = index">
                            <select [id]="column.name+i" (change)="changeSelectField(column.name+i,row,'HoursClassVO')" class="selectList" (focusout)="checkAddRow(column.name,i,row)" [disabled]="row.ownerOperator || checkDisableInputs(row,i)">
                                <!-- <ng-container *ngIf="!column.required"> -->
                                    <option value="-"></option>
                                <!-- </ng-container> -->
                                <ng-container *ngFor="let hc of blankErfForm.allowedHoursClasses; let i = index">
                                    <option [ngValue]="hc.guid" [selected]="getSelectedValue(hc.parameterDesc, row[column.name],'HoursClassVO')">{{hcdisplayoption(hc)}}</option>
                                </ng-container>
                            </select>
                        </mat-cell>
                    </ng-container>
                    <!-- This is for WorkingLocal -->
                    <ng-container *ngIf="column.name == 'workingLocal'">
                        <mat-cell *matCellDef="let row; let i = index">
                            <select [id]="column.name+i" (change)="changeSelectField(column.name+i,row,'OrganizationDisplayFlagVO')" class="selectList" (focusout)="checkAddRow(column.name,i,row)" [disabled]="row.ownerOperator || checkDisableInputs(row,i)">
                                <!-- <ng-container *ngIf="!column.required"> -->
                                    <option value="-"></option>
                                <!-- </ng-container> -->
                                <ng-container *ngFor="let wl of blankErfForm.unionLocals; let i = index">
                                    <option [ngValue]="wl.guid" [selected]="getSelectedValue(wl.organizationID, row[column.name],'OrganizationDisplayFlagVO')">{{wldisplayoption(wl)}}</option>
                                </ng-container>
                            </select>
                        </mat-cell>
                    </ng-container>
                    <!-- This is for EmploymentReason  -->
                    <ng-container *ngIf="column.name == 'emplStatusReasonGuid'">
                        <mat-cell *matCellDef="let row; let i = index">
                            <ng-container *ngIf="row[column.name] != null;else emplStatus">
                                <mat-icon matDatepickerToggleIcon (click)="clearEmploymentStatus(row)" class="clearStatus" title="Clear Status Field">clear</mat-icon>
                                <input [id]="column.name+i" type="text" list="emplStatus" value="{{esrdisplayoption(row[column.name])}}" (change)="changeEmployementStatusReason(column.name+i,row)" style="text-align: center;margin-bottom: 6px;"/>
                            </ng-container>
                            <ng-template #emplStatus>
                                <input [id]="column.name+i" type="text" list="emplStatus" value="{{esrdisplayoption(row[column.name])}}" [disabled]="checkDisableInputs(row,i)" (change)="changeEmployementStatusReason(column.name+i,row)" style="text-align: center;"/>
                            </ng-template>
                            <datalist id="emplStatus">
                                <ng-container *ngFor="let esr of employmentStatusReason; let i = index">
                                    <!-- <option value="{{hc.guid}}"> -->
                                    <option value="{{esrdisplayoption(esr)}}">
                                </ng-container>
                            </datalist>
                        </mat-cell>
                    </ng-container>
                    <!-- This is for EmploymentDate  -->
                    <ng-container *ngIf="column.name == 'emplStatusDate'">
                        <mat-cell *matCellDef="let row; let i = index">
                            <ng-container *ngIf="row[column.name] != null;">

                                <mat-icon matDatepickerToggleIcon (click)="clearEmploymentStatus(row)" class="clearDate" title="Clear Date Field" [disabled]="checkDisableInputs(row,i)">clear</mat-icon>
                            </ng-container>
                            <input [id]="column.name+i" [matDatepicker]="picker1" [readonly]="true" [disabled]="checkDisableInputs(row,i)" [(ngModel)]="row[column.name]" style="text-align-last: center;">
                            <mat-datepicker-toggle matSuffix [for]="picker1" [disabled]="checkDisableInputs(row,i)" style="margin-left: -34px;"></mat-datepicker-toggle>
                            <mat-datepicker #picker1 [disabled]="checkDisableInputs(row,i)"></mat-datepicker>
                        </mat-cell>
                    </ng-container>
                    <!-- Everything that isnt TrustFund OR SSN OR FIRST/LAST Name -->
                    <ng-container
                        *ngIf="column.name != 'obsolete' && column.name != 'ssn' && column.name != 'lastName' && column.name != 'firstName' && column.tFundGuid == 0">
                        <mat-cell *matCellDef="let row; let i = index">
                            <ng-container *ngIf="column.name == 'mi'">
                                <input [id]="column.name+i" (focusout)="checkAddRow(column.name,i,row)" [disabled]="checkDisableInputs(row,i)" [(ngModel)]="row[column.name]" [disabled]="row.ownerOperator"/>
                            </ng-container>
                            <ng-container *ngIf="column.name != 'mi'">
                                <input [id]="column.name+i" (focusout)="checkAddRow(column.name,i,row)" [disabled]="checkDisableInputs(row,i)" [(ngModel)]="row[column.name]" />
                            </ng-container>
                            <!-- <input [id]="column.name+i" (focusout)="checkAddRow(column.name,i,row)" [disabled]="checkDisableInputs(row)" [ngModel]="row[column.name]  | number : '1.0-2'" (ngModelChange)="updateRowData($event,column.name,i,row)" /> -->
                            <!-- <input [id]="column.name+i" (focusout)="checkAddRow(column.name,i,row)" [disabled]="checkDisableInputs(row)" [ngModel]="row[column.name]" (ngModelChange)="updateRowData($event,column.name,i,row)" /> -->
                        </mat-cell>
                    </ng-container>
                    <!-- SSN & First/Last Name -->
                    <ng-container *ngIf="column.name == 'ssn' || column.name == 'lastName' ||  column.name == 'firstName'">
                        <mat-cell *matCellDef="let row; let i = index">
                            <!-- This is for SSN -->
                            <ng-container *ngIf="column.name == 'ssn'">
                                <!-- <input  [id]="column.name+i" (focusout)="checkAddRow(column.name,i,row)" [(ngModel)]="row[column.name]" maxlength="9" /> -->
                                <input #ssn maxlength="9" [id]="column.name+i" (click)="snnFormatEditer(ssn)" (focusout)="ssn.value=ssnFormater(row[column.name]);ssnValidationOnly(column.name,i,row);checkAddRow(column.name,i,row)" [value]="ssnFormater(row[column.name])" (change)="updateSsnRowData($event,column.name,i,row)" [disabled]="row.ownerOperator"/>
                                <!-- <input #ssn maxlength="9" [id]="column.name+i" (click)="snnFormatEditer(ssn)" (focusout)="checkAddRow(column.name,i,row)" [value]="row[column.name] | ssn" (change)="updateSsnRowData($event,column.name,i,row)"/> -->
                            </ng-container>
                            <!-- This is for First/Last Name -->
                            <ng-container *ngIf="column.name != 'ssn'">
                                <input [id]="column.name+i" (keydown.tab)="checkAddRow(column.name,i,row)" (focusout)="checkAddRow(column.name,i,row)" [(ngModel)]="row[column.name]" [disabled]="row.ownerOperator"/>
                            </ng-container>
                        </mat-cell>
                    </ng-container>
                    <!-- This is for Tund Fund Data -->
                    <ng-container
                        *ngIf="column.tFundGuid != 0">
                        <mat-cell *matCellDef="let row; let i = index">
                            <!-- <ng-continaer *ngIf="isVeba(column.index,i,column.column);else notVeba">
                                <input [ngClass]="isVebaChanged(column.index,i,column.column)" [id]="column.name+i" (focusout)="checkAddRow(column.name,i,row)" [disabled]="checkDisableInputs(row)" [(ngModel)]="empTFundData[column.index][i][column.column]"/>
                            </ng-continaer>
                            <ng-template #notVeba>
                                <input [id]="column.name+i" (focusout)="checkAddRow(column.name,i,row)" [disabled]="checkDisableInputs(row)" [(ngModel)]="empTFundData[column.index][i][column.column]"/>
                            </ng-template> -->
                            <ng-continaer *ngIf="isVeba(column.name,i,row);else notVeba">
                                <input [ngClass]="isVebaChanged(column.name,i,row)" [id]="column.name+i" (focusout)="checkAddRow(column.name,i,row)" [disabled]="checkDisableInputs(row,i) || displayOnly(column)"  [(ngModel)]="row.fundDetails[column.name].original" (ngModelChange)="updateFundRowData($event,column.name,i,row)"/>
                            </ng-continaer>
                            <ng-template #notVeba>
                                <input [id]="column.name+i" (focusout)="checkAddRow(column.name,i,row)" [disabled]="checkDisableInputs(row,i) || displayOnly(column)" [(ngModel)]="row.fundDetails[column.name].original" (ngModelChange)="updateFundRowData($event,column.name,i,row)"/>
                            </ng-template>
                           
                        </mat-cell>
                    </ng-container>
        
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumnsData; sticky: true"></mat-header-row><!--sticky: true-->
                <mat-row *matRowDef="let row; columns: displayedColumnsData;" [ngClass]="{ highlight: row.ownerOperator == true }"></mat-row>
            </mat-table>
        </div>
    </ng-template>
</body>


<!-- Delete Modal -->
<ng-template #deleteModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Employee Data</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="deleteEmpData.guid">
            Are you sure you want to delete this Employee Data?
        </ng-container>
        <ng-container *ngIf="!deleteEmpData.guid">
            Remove the data in this row?
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" color="warn" mat-raised-button class="btn btn-outline-dark" (click)="deleteErfEmployee();modal.close('Save click')">Yes</button>
    </div>
</ng-template>
<!-- Validation Modal -->
<ng-template #validationModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Can't Save With Bad Data</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Fix input fields that are highlighted red or yellow.
    </div>
    <div class="modal-footer">
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Proceed To Fix Data</button>
    </div>
</ng-template>
<!-- PDF Modal -->
<ng-template #pdfModal let-modal class="modal-dialog modal-fullscreen">
    <div class="modal-header pdfHeader">
        <h1>Computation of Payments</h1>
    </div>
    <div class="modal-header" style="white-space: nowrap;">
        <!-- TODO:// Check if ADMIN and CAN UNFINALIZE REPORT -->
        <div style="float: left;">
            <ng-container *ngIf="isAffirmative(systemParameters[12].parameterValue1!)|| 
			(systemParameters[12].parameterValue1 != 'Y' && systemParameters[12].parameterValue1 != 'N') ||
			(systemParameters[12].parameterValue1 == 'N' && (employerReport.referenceNo != null && employerReport.referenceNo != ''))">
                <ng-container *ngIf="!pdfButton; else pdfBtn">
                    <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');processPayment();">Payment Coupon</button>
                </ng-container>
                <ng-template #pdfBtn>
                    <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');processCalculatePdf();">Calculated Report</button>
                </ng-template>
            </ng-container>
        </div>
        <div style="float: right;" *ngIf="!finalize">
            <button tabindex="-1" type="button" color="warn" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');open(deleteReportModal);" style="margin-left:5px" [disabled]="employerReport.massImportInfo != null">Delete Report</button>
            <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');loadData();resetPdfButton();" style="margin-left:5px">Edit</button>
            <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');redirectTo(employerReport.massImportInfo != null ? '/reportOptions/massImport':'/reportOptions');" style="margin-left:5px">Pend</button>
            <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');openPdf(finalizeModal)" style="margin-left:5px" [disabled]="employerReport.massImportInfo != null">Finalize</button>
            <ng-container *ngIf="isAffirmative(systemParameters[15].parameterValue1!)  && (employerReport.referenceNo != null && employerReport.referenceNo != '') && role === 'Admin'">
                <input type="button" name="Unfinalize" value="Unfinalize Report" title="Unfinalize Report" (click)="processUnfinalize()" />
            </ng-container>
        </div>
        <div style="float: right;" *ngIf="finalize">
            <ng-container *ngIf="!pdfButton; else pdfBtn">
                <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');processPayment();">Payment Coupon</button>
            </ng-container>
            <ng-template #pdfBtn>
                <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');processCalculatePdf();">Calculated Report</button>
            </ng-template>
            <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');redirectTo('/home');" style="margin-left:5px">Home</button>
        </div>
    </div>
    <div class="modal-body">
        <p >
            <object [data]="pdfURL()" type="application/pdf" width="100%" height="800px" ></object>
        </p>
    </div>
</ng-template>
<!-- Clear Form Modal -->
<ng-template #clearModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Clear Form</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        This action will clear all numeric values. Are you sure you want to clear these values?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-outline-dark"
            (click)="clearForm();modal.close('Save click');">Yes</button>
    </div>
</ng-template>
<!-- Demographics Form Modal -->
<ng-template #demographicsModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Enter Employee Demographics</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="margin: auto;">
        <table>
            <tr>
                <td><strong>Address 1: </strong></td>
                <td><input name="address1" title="Address 1" [(ngModel)]="tmpDemoQ.demoAddressLine1"/></td>
            </tr>
            <tr>
                <td><strong>Address 2: </strong></td>
                <td><input name="address2" title="Address 2" [(ngModel)]="tmpDemoQ.demoAddressLine2"/></td>
            </tr>
            <tr>
                <td><strong>State: </strong></td>
                <td>
                    <select class="states" name="states" id="states" [(ngModel)]="tmpDemoQ.demoState">
                        <option value="null">-</option>
                        <ng-container *ngFor="let state of statesList; let i = index">
                            <option  [ngValue]="state.parameterCode">{{state.parameterDesc}}</option>
                        </ng-container>
                    </select>
                </td>
            </tr>
            <tr>
                <td><strong>City: </strong></td>
                <td><input name="city" title="City" [(ngModel)]="tmpDemoQ.demoCity"/></td>
            </tr>
            <tr>
                <td><strong>Zip: </strong></td>
                <td><input name="zip" title="Zip Code" [(ngModel)]="tmpDemoQ.demoZipPostalCode"/></td>
            </tr>
            <tr>
                <td><strong>Home Phone: </strong></td>
                <td><input name="homePhone" title="Home Phone" [(ngModel)]="tmpDemoQ.demoHomePhone"/></td>
                <!-- <input name="home1" type="text" id="home1" size="2" title="Area Code" maxlength="3" [(ngModel)]="tmpHomeAreaCode" class="phone">-<input name="home2" type="text" id="home2" size="2" maxlength="3" [(ngModel)]="tmpHomePhoneMid" class="phone">-<input name="home3" type="text" id="home3" size="3" maxlength="4" [(ngModel)]="tmpHomePhoneEnd" class="phone"> -->
            </tr>
            <tr>
                <td><strong>Cell Phone: </strong></td>
                <td><input name="cellPhone" title="Cell Phone" [(ngModel)]="tmpDemoQ.demoCellPhone"/></td>
                <!-- <input name="cell1" type="text" id="cell1" size="2" title="Area Code" maxlength="3" [(ngModel)]="tmpHellAreaCode" class="phone">-<input name="cell2" type="text" id="cell2" size="2" maxlength="3" [(ngModel)]="tmpCellPhoneMid" class="phone">-<input name="cell3" type="text" id="cell3" size="3" maxlength="4" [(ngModel)]="tmpCellPhoneEnd" class="phone"> -->
            </tr>
            <tr>
                <td><strong>Email: </strong></td>
                <td><input name="email" title="Email" type="email" [(ngModel)]="tmpDemoQ.demoEmail"/></td>
            </tr>
            <tr>
                <td><strong>Date Of Birth: </strong></td>
                <td><input name="dob" title="Date Of Birth" [(ngModel)]="tmpDemoQ.demoDateOfBirth"/></td>
            </tr>
            <tr>
                <td><strong>Gender: </strong></td>
                <td> 
                    <select name="gender" [(ngModel)]="tmpDemoQ.demoGender">
                        <option value=""></option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                    </select>
                </td>
            </tr>
            <tr>
                <td><strong>Communication Method: </strong></td>
                <td>
                    <select name="method" id="method" [(ngModel)]="tmpDemoQ.demoPreferredCommMethod">
                        <ng-container *ngFor="let item of deliveryMethodList; let i = index">
                            <option  [ngValue]="item.parameterCode">{{item.parameterDesc}}</option>
                        </ng-container>
                    </select>
                </td>
            </tr>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>
<!-- Finalize Modal -->
<ng-template #finalizeModal let-modal class="modal-dialog modal-fullscreen">
    <div class="modal-header" style="white-space: nowrap;">
        <h4 class="modal-title" id="modal-basic-title">
        <span class="pymtHeader">Details for {{agreement.agreement.cbaName}}<br/>
            Report Period:&nbsp;{{employerReport.workPeriodBegin.substring(5,7) + '/' + employerReport.workPeriodBegin.substring(8,10) + '/' + employerReport.workPeriodBegin.substring(0,4)}}</span>
        </h4>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="checkReportedEmployeesExceptions()">
            <p class="defaults"><strong>Entry Issues/Warnings:</strong></p>
            <div>
                <table class="pmtTable">
                    <tr class="pmtTable">
                        <th class="pmtTable">Row</th>
                        <th class="pmtTable">SSN</th>
                        <th class="pmtTable">Name</th>
                        <th class="pmtTable">Description</th>
                    </tr>

                    <ng-container *ngFor="let emp of employerReport.reportedEmployees;let i = index">
                        <ng-container *ngFor="let ex of emp.exceptions">
                            <tr class="pmtTable">
                                <td class="pmtTable">{{i+1}}</td>
                                <td class="pmtTable">{{emp.ssn | slice:0:3}}-{{emp.ssn | slice:3:5}}-{{emp.ssn | slice:5}} </td>
                                <td class="pmtTable">{{emp.lastName}}, {{emp.firstName}}</td>
                                <td class="pmtTable">{{ex.exception.parameterDesc}}</td>
                            </tr>
                        </ng-container>
                    </ng-container>

                </table>
            </div>
        </ng-container>

        <ng-container *ngIf="erfPayment.length !=0">
            <p class="defaults"><strong>Make Payment(s) As Follows:</strong></p>
            <div class="payable">
                <table class="pmtTable">
                    <ng-container *ngFor="let p of erfPayment;let i = index">
                        <ng-container *ngIf="i === 0">
                            <tr class="pmtTable" style="border: solid;">
                                <th [ngClass]="isEftColumnHidden(eftCheck(p))" style="border: solid;">EFT</th>
                                <th class="pmtTable" style="border: solid;">Payment</th>
                                <th class="pmtTable" style="border: solid;">Payable To</th>
                                <th class="pmtTable" style="border: solid;">Amount</th>
                                <th [ngClass]="isEftColumnHidden(eftCheck(p))" style="border: solid;">Payment Date</th>
                                <ng-container *ngIf="!isAffirmative(systemParameters[22].parameterValue1!)">
                                    <th class="pmtTable" style="border: solid;">Mail To</th>
                                </ng-container>
                                <th class="pmtTable" style="border: solid;">Instructions</th>
                            </tr>
                        </ng-container>
                        <tr class="pmtTable" style="border: solid;">
                            <td [ngClass]="isEftColumnHidden(eftCheck(p))" style="border: solid;">
                                <ng-container *ngIf="eftCheck(p);else eftpmt">
                                    <input [id]="'ERF_Pmt_Type'+i" type="checkbox" class="center" />
                                </ng-container>
                                <ng-template #eftpmt>
                                    <!-- <input type="checkbox" class="center" name="ERF_Pmt_Type{{i}}" /> -->
                                </ng-template>
                            </td>
                            <td class="pmtTable" style="border: solid;">{{p.PmtDesc}}</td>
                            <td class="pmtTable" style="border: solid;"><strong>{{p.PmtPayableTo}}</strong>&nbsp;</td>
                            <td class="pmtTable" style="border: solid;"><strong>{{p.erfPmtAmtDue | currency}}</strong>&nbsp;</td>
                            <td [ngClass]="isEftColumnHidden(eftCheck(p))" style="border: solid;">
                                <ng-container *ngIf="eftCheck(p);else eftpmtdate">
                                <!-- TODO: add date selector here finalizePayment.jsp 404-415  -->
                                    <p>
                                        <mat-form-field color="primary" appearance="fill">
                                        <mat-label>Payment Date</mat-label>
                                        <input [id]="'eftPmtDate'+i" matInput [matDatepicker]="dp3" [matDatepickerFilter]="myDateFilter" disabled>
                                        <mat-hint>MM/DD/YYYY</mat-hint>
                                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                                        <mat-datepicker (opened)="streamOpened($event)" [dateClass]="dateClass" #dp3 disabled="false" color="primary" panelClass="endDate"></mat-datepicker>
                                        </mat-form-field>
                                    </p>
                                </ng-container>
                                <ng-template #eftpmtdate>
                                    <!-- <input type="checkbox" class="center" name="ERF_Pmt_Type{{i}}" /> -->
                                    <!-- <input type="checkbox" class="center" /> -->
                                </ng-template>
                            </td>
                            <ng-container *ngIf="!isAffirmative(systemParameters[22].parameterValue1!)">
                                <td class="pmtTable" style="border: solid;">
                                    {{p.PmtPayableTo==null?"":p.PmtPayableTo}}
                                    <ng-container *ngIf="p.Pmt_Addr_Line1==null || p.Pmt_Addr_Line1.trim().length == 0;else pmtaddr1"></ng-container>
                                    <ng-template #pmtaddr1>
                                        <br />{{p.Pmt_Addr_Line1}}
                                    </ng-template>
                                    <ng-container *ngIf="p.Pmt_Addr_Line2==null || p.Pmt_Addr_Line2.trim().length == 0;else pmtaddr2"></ng-container>
                                    <ng-template #pmtaddr2>
                                        <br />{{p.Pmt_Addr_Line2}}
                                    </ng-template>
                                    <ng-container *ngIf="p.Pmt_Addr_Line3==null || p.Pmt_Addr_Line3.trim().length == 0;else pmtaddr3"></ng-container>
                                    <ng-template #pmtaddr3>
                                        <br />{{p.Pmt_Addr_Line3}}
                                    </ng-template>

                                    <br />
                                    {{p.Pmt_Addr_City==null?"":p.Pmt_Addr_City}},&nbsp;
                                    {{p.Pmt_Addr_State==null?"":p.Pmt_Addr_State}}&nbsp;
                                    {{p.Pmt_Addr_ZipCode==null?"":p.Pmt_Addr_ZipCode}}
                                    <br />
                                    <ng-container *ngIf="p.Pmt_Phone==null || p.Pmt_Phone.trim().length == 0;else pmtphone"></ng-container>
                                    <ng-template #pmtphone>
                                        Phone #: {{p.Pmt_Phone | slice:0:3}}-{{p.Pmt_Phone | slice:3:6}}-{{p.Pmt_Phone | slice:6}}
                                    </ng-template>
                                </td>
                            </ng-container>
                            <td class="pmtTable" style="border: solid;">
                                {{p.PmtInstruction==null?"":p.PmtInstruction}}&nbsp;
                                <!-- {{p.PmtInstruction==null && !isEmployerOrStaff()?"":systemParameters[18].parameter_VALUE}} -->
                                <ng-container *ngIf="p.PmtInstruction==null && !isEmployerOrStaff();else pmtinst"></ng-container>
                                <ng-template #pmtinst>
                                    <div class="link" [innerHTML]="systemParameters[18].parameterValue1"></div>
                                </ng-template>
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </div>
            <ng-container *ngIf="isAffirmative(systemParameters[17].parameterValue1!) && erfPayment.length == 1">
                <table class="pmtTable">
                    <tr class="pmtTable">
                        <th class="pmtTable">Credit Amount</th>
                        <th class="pmtTable">Employer Notes (maximum of 250 characters)</th>
                    </tr>
                    <tr class="pmtTable">
                        <td class="pmtTable">
                            <input size="10" maxlength="10" name="blankErfForm" property="eftCreditAmount" [id]="'eftCreditAmount'">
                        </td>
                        <td class="pmtTable">
                            <!-- <input size="110" maxlength="250" name="blankErfForm" property="eftCreditNotes" [id]="'eftCreditNotes'"/> -->
                            <input size="110" maxlength="250" name="blankErfForm" [(ngModel)]="employerReport.employerNotes"/>
                        </td>
                    </tr>
                </table>
            </ng-container>
        </ng-container>
        <!-- finalizePyament.jsp 468-874 -->
        <ng-container *ngIf="employerReport.employerFundContributionsDue.length > 0">
            <hr />
            <div class="recon">
                <h2>Computation of Payments</h2>
                <table class="pmtTable" style="width: 100%;">
                    <tr> <!-- totals for Local, Job Type and Fund heading row -->
                        <th><span class="nowrap">Local</span></th>
                        <th><span class="nowrap">Job Type</span></th>
                        <th><span class="nowrap">J&#47;C</span></th>
                        <th><span class="nowrap">Fund</span></th>
                        <th class="right"><span [ngClass]="isColumnHidden('hoursReportedST',true)">Hours</span></th>
                        <th class="right"><span [ngClass]="isColumnHidden('hoursReportedST',true)">Rate</span></th>
                        <th class="right"><span [ngClass]="isColumnHidden('hoursReportedOT',true)">OT Hours</span></th>
                        <th class="right"><span [ngClass]="isColumnHidden('hoursReportedOT',true)">Rate</span></th>
                        <th class="right"><span [ngClass]="isColumnHidden('hoursReportedDT',true)">DT Hours</span></th>
                        <th class="right"><span [ngClass]="isColumnHidden('hoursReportedDT',true)">Rate</span></th>
                        <!-- <th class="right"><span [ngClass]="isColumnHidden('allowTravelHours',false)">Trvl Hours</span></th>
                        <th class="right"><span [ngClass]="isColumnHidden('allowTravelHours',false)">Rate</span></th> -->
                        <th class="right"><span [ngClass]="isColumnHidden('wages',true)">Wages</span></th>
                        <th class="right"><span class="right nowrap">Amt Due</span></th>
                    </tr> <!-- end totals for Local, Job Type and Fund heading row -->
                    <ng-container *ngFor="let efcd of employerReport.employerFundContributionsDue;let i = index">
                        <tr> <!-- totals by Local, Job Type and Amt Due -->
                            <td>
                                <!-- {{(efcd.agreementRate.rateLevel.workingLocal == null|| efcd.agreementRate.rateLevel.workingLocal.organizationName === '')? " " : efcd.agreementRate.rateLevel.workingLocal.organizationName}} -->
                                {{(efcd.agreementRate.workingLocal == null|| efcd.agreementRate.workingLocal.organizationName === '')? " " : efcd.agreementRate.workingLocal.organizationName}}
                            </td>
                            <td>
                                <!-- {{(efcd.agreementRate.rateLevel.jobClass == null || efcd.agreementRate.rateLevel.jobClass.parameterValue1 === '') &&
                                (efcd.agreementRate.rateLevel.workingLocal == null|| efcd.agreementRate.rateLevel.workingLocal.organizationName === '')? " " : 
                                (efcd.agreementRate.rateLevel.jobClass.parameterValue1 == null ? "Others" : efcd.agreementRate.rateLevel.jobClass.parameterValue1)}} -->
                                {{(efcd.agreementRate.jobClass == null || efcd.agreementRate.jobClass.parameterValue1 === '') &&
                                (efcd.agreementRate.workingLocal == null|| efcd.agreementRate.workingLocal.organizationName === '')? " " : 
                                (efcd.agreementRate.jobClass.parameterValue1 == null ? "Others" : efcd.agreementRate.jobClass.parameterValue1)}}
                            </td>
                            <td>
                                <!-- <ng-container *ngIf="(efcd.agreementRate.rateLevel.jobClass == null || efcd.agreementRate.rateLevel.jobClass.parameterValue1 === '') &&
                                (efcd.agreementRate.rateLevel.workingLocal == null|| efcd.agreementRate.rateLevel.workingLocal.organizationName === '') ;else efcdjc">&nbsp;</ng-container>
                                <ng-template #efcdjc>
                                    {{efcd.agreementRate.rateLevel.jobClass.parameterCode}}
                                </ng-template> -->
                                <ng-container *ngIf="(efcd.agreementRate.jobClass == null || efcd.agreementRate.jobClass.parameterValue1 === '') &&
                                (efcd.agreementRate.workingLocal == null|| efcd.agreementRate.workingLocal.organizationName === '') ;else efcdjc">&nbsp;</ng-container>
                                <ng-template #efcdjc>
                                    {{efcd.agreementRate.jobClass.parameterCode}}
                                </ng-template>
                            </td>
                            <td>
                                {{efcd.agreementRate.trustFund.fundShortName}}
                            </td>
                            <td>
                                {{efcd.hoursAppliedST | number : '1.2-2'}}
                            </td>
		                    <td>
                                <ng-container *ngIf="efcd.rateFormula === 'WAGE%';else efcdratest">
                                    {{efcd.agreementRate.value1 | number : '1.4-4'}}
                                </ng-container>
                                <ng-template #efcdratest>
                                   {{efcd.agreementRate.value1 | number : '1.4-4'}}
                                </ng-template>
                            </td>
                            <td>
                                {{efcd.hoursAppliedOT | number : '1.2-2'}}
                            </td>
                            <td>
                                <ng-container *ngIf="efcd.rateFormula === 'WAGE%';else efcdrateot">
                                    {{efcd.agreementRate.value2 | number : '1.4-4'}}
                                </ng-container>
                                <ng-template #efcdrateot>
                                   {{efcd.agreementRate.value2 | number : '1.4-4'}}
                                </ng-template>
                            </td>
                            <td>
                                {{efcd.hoursAppliedDT | number : '1.2-2'}}
                            </td>
                            <td>
                                <ng-container *ngIf="efcd.rateFormula === 'WAGE%';else efcdratedt">
                                    {{efcd.agreementRate.value3 | number : '1.4-4'}}
                                </ng-container>
                                <ng-template #efcdratedt>
                                   {{efcd.agreementRate.value3 | number : '1.4-4'}}
                                </ng-template>
                            </td>
                            <!-- <td>
                                <span class="<%=clsHoursTrvl%>">&nbsp;</span>
                            </td>
		                    <td>
                                <span class="<%=clsHoursTrvl%>">&nbsp;</span>
                            </td> -->
                            <td>
                                {{efcd.wages | number : '1.2-2'}}
                            </td>
                            <td>
                                {{efcd.contributionsDue | number : '1.2-2'}}
                            </td>
                        </tr><!-- end totals by Local, Job Type and Amt Due -->
                    </ng-container>
                    <!--685-718 -->
                    <tr> <!-- totals by Local, Job Type and Amt Due -->
                        <!-- <%
                        if (ulNameUnchanged || efcd == null) { -->
                            <td><span class="nowrap"><strong>&#36;{{getColumnFundStats('contributionsDue') | number : '1.2-2'}}</strong></span></td>
                            <!-- <td><span class="nowrap"><strong>&#36;<%=ICIUtilities.formatNumbers(jobClassTypeTotal)%></strong></span></td> -->
<!--                                 
                            jobClassTypeTotal = new java.math.BigDecimal("0.00");
                        } else {
                            %> <td><span class="nowrap">&nbsp;</span></td>
                            <%
                        }
                        if (!sameJCTName.equals(newJCTName) || ulNameUnchanged || efcd == null) {
                            %>  -->
                            <td><span class="nowrap"><strong>&#36;{{getColumnFundStats('contributionsDue') | number : '1.2-2'}}</strong></span></td>
                            <!-- <td><span class="right nowrap"><strong>&#36;<%=ICIUtilities.formatNumbers(jobClassTotal)%></strong></span></td> -->
                            <!-- <%
                            jobClassTotal = new java.math.BigDecimal("0.00");
                        } else {
                            %> <td><span class="nowrap">&nbsp;</span></td>
                            <%
                        }
                        %> -->
                        <td><span class="nowrap">&nbsp;</span></td>
                        <td><span class="nowrap">&nbsp;</span></td>
                        <th class="right"><span [ngClass]="isColumnHidden('hoursReportedST',true)">&nbsp;</span></th>
                        <th class="right"><span [ngClass]="isColumnHidden('hoursReportedST',true)">&nbsp;</span></th>
                        <th class="right"><span [ngClass]="isColumnHidden('hoursReportedOT',true)">&nbsp;</span></th>
                        <th class="right"><span [ngClass]="isColumnHidden('hoursReportedOT',true)">&nbsp;</span></th>
                        <th class="right"><span [ngClass]="isColumnHidden('hoursReportedDT',true)">&nbsp;</span></th>
                        <th class="right"><span [ngClass]="isColumnHidden('hoursReportedDT',true)">&nbsp;</span></th>
                        <!-- <th class="right"><span [ngClass]="isColumnHidden('allowTravelHours',false)">&nbsp;</span></th>
                        <th class="right"><span [ngClass]="isColumnHidden('allowTravelHours',false)">&nbsp;</span></th> -->
                        <th class="right"><span [ngClass]="isColumnHidden('wages',true)">&nbsp;</span></th>
                        <td class="right">
                            <span class="nowrap"><strong>&#36;{{getColumnFundStats('contributionsDue') | number : '1.2-2'}}</strong></span>
                            <!-- <span class="nowrap"><strong>&#36;<%=ICIUtilities.formatNumbers(fundTotal)%></strong></span> -->
                        </td>
                    </tr> <!-- end totals by Local, Job Type and Amt Due -->

                    <tr> <!-- blank row for printed version -->
                        <td><span class="nowrap">&nbsp;</span></td>
                        <td><span class="nowrap">&nbsp;</span></td>
                        <td><span class="nowrap">&nbsp;</span></td>
                        <td><span class="nowrap">&nbsp;</span></td>
                        <td><span class="nowrap">&nbsp;</span></td>
                        <td><span class="nowrap">&nbsp;</span></td>
                        <td><span class="nowrap">&nbsp;</span></td>
                        <td><span class="nowrap">&nbsp;</span></td>
                        <td><span class="nowrap">&nbsp;</span></td>
                        <td><span class="nowrap">&nbsp;</span></td>
                        <td><span class="nowrap">&nbsp;</span></td>
                        <!-- <td><span class="nowrap">&nbsp;</span></td>
                        <td><span class="nowrap">&nbsp;</span></td> -->
                        <td><span class="nowrap">&nbsp;</span></td>
                    </tr>

                    <ng-container>
                        <tr> <!-- Grand Totals section title -->
                            <th><span class="sectionlabel">Grand Totals</span></th>
                        </tr> <!-- end Grand Totals section title -->
                        <tr> <!-- Grand Totals heading row -->
                            <th><span class="nowrap hidden">Local</span></th>
                            <th><span class="nowrap hidden">Job Type</span></th>
                            <th><span class="nowrap hidden">J&#47;C</span></th>
                            <th><span class="nowrap">Fund</span></th>
                            <th>
                                <span [ngClass]="isColumnHidden('hoursReportedST',true)">Hours</span>
                            </th>
                            <th><span class="nowrap hidden">Rate</span></th>
                            <th>
                                <span [ngClass]="isColumnHidden('hoursReportedOT',true)">OT Hours</span>
                            </th>
                            <th><span class="nowrap hidden">Rate</span></th>
                            <th>
                                <span [ngClass]="isColumnHidden('hoursReportedDT',true)">DT Hours</span>
                            </th>
                            <th><span class="nowrap hidden">Rate</span></th>
                            <!-- <th>
                                <span [ngClass]="isColumnHidden('allowTravelHours',false)">Trvl Hours</span>
                            </th>
                            <th><span class="nowrap hidden">Rate</span></th> -->
                            <th>
                                <span [ngClass]="isColumnHidden('wages',true)">Wages</span>
                            </th>
                            <th><span class="nowrap">Amt Due</span></th>
                        </tr> <!-- end Grand Totals heading row -->

                        <ng-container *ngFor="let efcd of employerReport.employerFundContributionsDue;let i = index">
                            <ng-container *ngIf="efcd.agreementRate.trustFund != null">
                                <tr> <!-- Grand Totals detail -->
                                    <td><span class="nowrap hidden">&nbsp;</span></td>
                                    <td><span class="nowrap hidden">&nbsp;</span></td>
                                    <td><span class="nowrap hidden">&nbsp;</span></td>
                                    <td><span class="nowrap">{{efcd.agreementRate.trustFund.fundShortName}}</span></td>
                                    <td>
                                        <!-- <span [ngClass]="isColumnHidden('hoursReportedST')">{{getColumnFundStats('hoursAppliedST') | number : '1.2-2'}}</span> -->
                                        <span [ngClass]="isColumnHidden('hoursReportedST',true)"> {{efcd.hoursAppliedST | number : '1.2-2'}}</span>
                                    </td>
                                    <td><span class="nowrap">&nbsp;</span></td>
                                    <td>
                                        <span [ngClass]="isColumnHidden('hoursReportedOT',true)">{{efcd.hoursAppliedOT | number : '1.2-2'}}</span>
                                    </td>
                                    <td><span class="nowrap">&nbsp;</span></td>
                                    <td>
                                        <span [ngClass]="isColumnHidden('hoursReportedDT',true)">{{efcd.hoursAppliedDT | number : '1.2-2'}}</span>
                                    </td>
                                    <td><span class="nowrap">&nbsp;</span></td>
                                    <!-- <td>
                                        <span [ngClass]="isColumnHidden('allowTravelHours',false)">Trvl Hours</span>
                                    </td>
                                    <td><span class="nowrap">&nbsp;</span></td> -->
                                    <td>
                                        <span [ngClass]="isColumnHidden('wages',true)">{{efcd.wages | number : '1.2-2'}}</span>
                                    </td>
                                    <td><span class="numeric">{{efcd.contributionsDue | number : '1.2-2'}}</span></td>
                                </tr> <!-- end Grand Totals detail -->
                            </ng-container>
                        </ng-container>
                        <tr> <!-- Grand Total amount due row -->
                            <td><span class="nowrap hidden">&nbsp;</span></td>
                            <td><span class="nowrap hidden">&nbsp;</span></td>
                            <td><span class="nowrap hidden">&nbsp;</span></td>
                            <td class="right"><span class="<%=clsHours%>">&nbsp;</span></td>
                            <td><span class="nowrap">&nbsp;</span></td>
                            <td class="right"><span class="<%=clsHoursOT%>">&nbsp;</span></td>
                            <td><span class="nowrap">&nbsp;</span></td>
                            <td class="right"><span class="<%=clsHoursDT%>">&nbsp;</span></td>
                            <td><span class="nowrap">&nbsp;</span></td>
                            <!-- <td class="right"><span class="<%=clsHoursTrvl%>">&nbsp;</span></td>
                            <td><span class="nowrap">&nbsp;</span></td> -->
                            <td class="right"><span class="<%=clsWages%>">&nbsp;</span></td>
                            <td class="right"><span class="nowrap"><strong>Pay This Amount:</strong></span></td>
                            <td class="right"><span class="rpttotals numeric"><strong>&#36;{{getColumnFundStats('contributionsDue') | number : '1.2-2'}}</strong></span></td>
                        </tr> <!-- end Grand Total amount due row -->
                        <tr> <!-- blank row for printed version -->
                            <td><span class="nowrap">&nbsp;</span></td>
                            <td><span class="nowrap">&nbsp;</span></td>
                            <td><span class="nowrap">&nbsp;</span></td>
                            <td><span class="nowrap">&nbsp;</span></td>
                            <td><span class="nowrap">&nbsp;</span></td>
                            <td><span class="nowrap">&nbsp;</span></td>
                            <td><span class="nowrap">&nbsp;</span></td>
                            <td><span class="nowrap">&nbsp;</span></td>
                            <td><span class="nowrap">&nbsp;</span></td>
                            <td><span class="nowrap">&nbsp;</span></td>
                            <!-- <td><span class="nowrap">&nbsp;</span></td>
                            <td><span class="nowrap">&nbsp;</span></td> -->
                            <td><span class="nowrap">&nbsp;</span></td>
                            <td><span class="nowrap">&nbsp;</span></td>
                        </tr>
                    </ng-container>
                </table>
                <!-- Cant have on SPFPA becuase they are not allowed to send checks -->
                <ng-container *ngIf="!isAffirmative(systemParameters[22].parameterValue1!)">
                    <p class="message">Be sure to include the EDI Reference Number in the Memo portion of check(s).</p>
                </ng-container>
            </div>
        </ng-container>
        
    </div>
    <div class="modal-footer">
        <!-- <button type="button" class="btn btn-outline-dark" (click)="finalizeOnClick(erfPayment[0].erfPmtAmtDue,(eftCheck(erfPayment[0]) && isAffirmative(systemParameters[17].parameter_VALUE)));modal.close('Save click')">Finalize</button> -->
        <button type="button" class="btn btn-outline-dark" (click)="finalizeOnClick((eftCheck(erfPayment[0]) && isAffirmative(systemParameters[17].parameterValue1!)));">Finalize</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
    </div>
</ng-template>

<!-- Create Project Modal -->
<ng-template #createprojectModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create Project</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Please save the report before proceeding. Any unsaved data will not be retained.
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');redirectTo('/listProject')">Add/Edit Projects</button>
    </div>
</ng-template>


<!-- Alert Modal -->
<ng-template #alertModal let-modal>
    <div class="modal-header" style="justify-content: center;">
        <h4 class="modal-title" id="modal-basic-title">Alert</h4>
    </div>
    <div class="modal-body" style="align-self: center;">
        {{alertMsg}}
    </div>
    <div class="modal-footer">
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');foucsAfterAlert()">Close</button>
    </div>
</ng-template>





<!-- Import Select File Modal -->
<ng-template #importSelectFile let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Upload Payroll Import File</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5>Step 1: Select File</h5>
        <input type="file" style="padding-left: 60px;" (change)="selectFile($event)"/>
        <br>
        <br>
        
        <h5>Step 2: Enter Header Row #</h5>
        <div style="padding-left: 60px;">
            <input property="headerRow" size="10" maxlength="16" title="Excel Sheet Header Row" [(ngModel)]="headerRow" />
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="checkImportFile();">Continue</button>
    </div>
</ng-template>

<!-- Import Report Setup Modal -->
<ng-template #importSetup let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Report Import Setup</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p style="font-weight: bold;">Assign a column number to each applicable field matching the import file specified:</p>
        <p style="color: red;">If import file has a single name field, map that to Last Name; leave First and Middle Name mappings blank. Import field must be in the following format:<br>Last, First Middle (e.g., Smith, Joe J.)</p>
        <div class="erfFormatList">
            <table style="border: solid;">
                <tr style="border: solid;">
                    <td style="border: solid;">Position</td>
                    <td>Column</td>
                </tr>
                <ng-container *ngFor="let column of agreementColumns;let i = index">
                    <ng-container *ngIf="!isDisplayOnly(column)">
                        <tr style="border: solid;">
                            <td style="border: solid;">
                                <ng-container *ngIf="defaultMapping == null;else noDefault">
                                    <input property="headerRow" size="4" maxlength="3" title="Assigned Mapping" [(ngModel)]="agreementColumnsSortOrder[i]" [disabled]="isDisplayOnly(column)" />
                                </ng-container>
                                <ng-template #noDefault>
                                    <input property="headerRow" size="4" maxlength="3" title="Assigned Mapping" [(ngModel)]="agreementColumnsSortOrder[i]" [disabled]="isDisplayOnly(column)"/>
                                </ng-template>
                            </td>
                            <td>{{column}}</td>
                        </tr>
                    </ng-container>
                </ng-container>
            </table>
        </div>
        <div class="fileFormatList">
            <p>Here are the the column headings from the import file
                with their corresponding column numbers.&nbsp; Fill in the appropriate
                column numbers in the boxes on the left.</p>

            <label style="font-weight: bold;padding-right: 10px;">Mapping Format: </label>
            <select (change)="getMapping();" name="selectMapping"[(ngModel)]="defaultMapping">
                <ng-container *ngIf="importNamesList.length > 0;else noMapping" >
                    <option *ngFor="let item of importNamesList" [ngValue]="item.guid">
                        {{ item.name }}
                    </option>
                </ng-container>
                <ng-template #noMapping>
                    <option>Create New Mapping On Preview!</option>
                </ng-template>
            </select>
            
            <mat-icon color="primary" title="Mapping can be saved on next screen." style="position: absolute;margin-top: 5px;">info</mat-icon>
            
            <ng-container *ngIf="importNamesList.length > 0">
                <button style="margin-left: 30px;" color="warn" mat-raised-button matTooltip="Delete mapping format permanently." (click)="deleteImportMapping()">Delete Import Mapping</button>
            </ng-container>

            <table style="border: solid;">
                <tr style="border: solid;">
                    <td style="border: solid;">Position</td>
                    <td>Column</td>
                </tr>
                <ng-container *ngFor="let fHeaders of fileImportFormat; let i = index">
                    <tr style="border: solid;">
                        <td style="border: solid;">{{i + 1}}.</td>
                        <td>{{fHeaders}}</td>
                    </tr>
                </ng-container>
            </table>
        </div>

    </div>
    <div class="modal-footer">
        <p class="fileNamePos">{{currentFile?.name}}</p>
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');openPdf(importPreview)">Preview Report</button>
    </div>
</ng-template>

<!-- Import Preview Modal -->
<ng-template #importPreview let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Report Import Setup</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Click 'Make Changes' button to go back to the mapping window. If this is a new or revised mapping, enter a mapping name in the field below and click 'Save'. Otherwise, click 'Update Report' to complete the import.</p>
        <div class="erfFormatList">
            <table style="border: solid;">
                <tr style="border: solid;">
                    <ng-container *ngFor="let column of agreementColumns;let i = index">
                        <ng-container *ngIf="initColumnsData[i].displayOnly != 'Y'">
                            <td style="border: solid;padding: 0px 5px 0px 5px;">{{column}}</td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr style="border: solid;">
                    <ng-container *ngFor="let column of agreementColumnsSortOrder;let i = index">
                        <ng-container *ngIf="sortPreview(column);">
                            <ng-container *ngIf="initColumnsData[i].displayOnly != 'Y'">
                                <td style="border: solid;padding: 0px 5px 0px 5px;white-space: nowrap;">{{previewData(fileColumnsPreview[column-1],i)}}</td>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!sortPreview(column);">
                            <ng-container *ngIf="initColumnsData[i].displayOnly != 'Y'">
                                <td style="border: solid;padding: 0px 5px 0px 5px;"></td>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <!-- <ng-template #noMatch>
                        <td style="border: solid;padding: 0px 5px 0px 5px;"></td>
                    </ng-template> -->
                </tr>
            </table>
        </div>
        <div style="float: left;">
            <br>
            <p>If this mapping has not been saved before, enter a name and click 'Save' before continuing.</p>
            <label style="font-weight: bold;">Mapping Format: </label>
            <input property="headerRow" size="20" maxlength="20" title="Mapping Format" [(ngModel)]="newMappingName" />
            <button type="button" class="btn btn-outline-dark" (click)="saveNewmapping()">Save</button>
        </div>
    </div>
    <div class="modal-footer">
        <p class="fileNamePos">{{currentFile?.name}}</p>
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="previewReport()">Make Changes</button>
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="importUpdateReport()">Update Report</button>
    </div>
</ng-template>
<!-- Delete Report Modal -->
<ng-template #deleteReportModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Unfinished Report</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Are you sure you want to delete this report? 
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark"
            (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-outline-dark"
            (click)="deleteReport();modal.close('Save click')">Yes</button>
    </div>
</ng-template>
<!-- Employer Class Mapping Modal -->
<ng-template #employerClassMappingModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Additional Mapping</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Some fields do not match expected data. Please provide the mappings below.</p>
        <table style="border: solid;">
            <tr style="border: solid;">
                <td style="border: solid;">Type</td>
                <td style="border: solid;">ID From Import</td>
                <td style="border: solid;">Mapped ID</td>
            </tr>
            <ng-container *ngFor="let item of hoursNotMapped;let i = index">
                <tr style="border: solid;">
                    <td style="border: solid;">Hours Class</td>
                    <td style="border: solid;">{{item.idFromEmployer}}</td>
                    <td style="border: solid;">
                        <select (change)="hoursClassMapping($event,item);" name="selectMapping">
                            <option>--Select--</option>
                            <option *ngFor="let hours of hoursToBeMapped" [ngValue]="hours.guid">
                                {{ hours.parameterCode }}
                            </option>
                        </select>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngFor="let item of jobsNotMapped;let i = index">
                <tr style="border: solid;">
                    <td style="border: solid;">Job Class</td>
                    <td style="border: solid;">{{item.idFromEmployer}}</td>
                    <td style="border: solid;">
                        <select (change)="jobClassMapping($event,item);" name="selectMapping">
                            <option>--Select--</option>
                            <option *ngFor="let jobs of jobsToBeMapped" [ngValue]="jobs.guid">
                                {{ jobs.parameterCode }}
                            </option>
                        </select>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngFor="let item of workingLocalNotMapped;let i = index">
                <tr style="border: solid;">
                    <td style="border: solid;">Working Local</td>
                    <td style="border: solid;">{{item.idFromEmployer}}</td>
                    <td style="border: solid;">
                        <select (change)="workLocalClassMapping($event,item);" name="selectMapping">
                            <option>--Select--</option>
                            <option *ngFor="let wl of workingLocalToBeMapped" [ngValue]="wl.guid">
                                {{ wl.parameterCode }}
                            </option>
                        </select>
                    </td>
                </tr>
            </ng-container>

            <ng-container *ngFor="let item of employmentReasonNotMapped;let i = index">
                <tr style="border: solid;">
                    <td style="border: solid;">Employment Reason</td>
                    <td style="border: solid;">{{item.idFromEmployer}}</td>
                    <td style="border: solid;">
                        <select (change)="employmentReasonClassMapping($event,item);" name="selectMapping">
                            <option>--Select--</option>
                            <option *ngFor="let hours of employmentReasonToBeMapped" [ngValue]="hours.guid">
                                {{ hours.parameterCode }}
                            </option>
                        </select>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="saveClassMapping()">Save Mapping</button>
    </div>
</ng-template>