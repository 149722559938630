export class DateString {
    dateString = new Date();

    getDay() {
      return this.dateString.getDay();
    }
    getMonth() {
        return this.dateString.getMonth();
    }
    getFullYear() {
        return this.dateString.getFullYear();
    }
    //example 2022-06-01T07:00:00.000+00:00
    //
    // JAVA & TYPESCRIPT MONTHS(0-11)
    // SQL MONTH(1-12)
    // 
    constructor(str?:string){
        var d = new Date();
        if(str != null || str != undefined){
            var arr = str.split("-", 3);
            arr[2] = arr[2].substring(0,arr[2].lastIndexOf('T'));
            d.setFullYear(Number(arr[0]));//year
            d.setMonth(Number(arr[1])-1);//month
            d.setDate(Number(arr[2]));//day
        }
        d.setHours(0);//hours
        d.setMinutes(0);//minutes
        d.setSeconds(0);//seconds
        d.setMilliseconds(0);//mi-sec
        // return d;
        this.dateString = d;
    }
}
