import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staff-user-accounts',
  templateUrl: './staff-user-accounts.component.html',
  styleUrls: ['./staff-user-accounts.component.scss']
})
export class StaffUserAccountsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
