import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCalendarCellClassFunction, MatDatepicker } from '@angular/material/datepicker';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Moment } from 'moment';
import { Observable, take, takeWhile, timer } from 'rxjs';
import { DateString } from 'src/app/model/date-string';
import { SystemParameters } from 'src/app/model/systemParameters/system-parameters';
import { Employer } from 'src/app/model/userManagement/employer';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EmployerReportService } from 'src/app/services/employerReport/employer-report.service';
import { ImportService } from 'src/app/services/import/import.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { SystemParametersService } from 'src/app/services/systemParameter/system-parameters.service';
import { UserManagementService } from 'src/app/services/userManagement/user-management.service';
import { MY_FORMATS } from '../report-options.component';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-mass-import',
  templateUrl: './mass-import.component.html',
  styleUrls: ['./mass-import.component.scss'],
  providers: [DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},],
  encapsulation: ViewEncapsulation.None
})
export class MassImportComponent implements OnInit, OnDestroy  {

  selectedFiles:any;
  tiles: Tile[] = [
    {text: 'One', cols: 1, rows: 1, color: 'lightblue'},
    {text: 'Two', cols: 3, rows: 1, color: 'lightgoldenrodyellow'},
    {text: 'Three', cols: 4, rows: 12, color: 'lightgreen'},
  ];
  params: any = ["EFT_PAYMENT_HTML","EFT_ACTIVATED","EFT_CUTOFF_DAY","EFT_CUTOFF_MONTHS","ALLOW_WEEKENDS","EFT_ALLOW_SHORT_PAYMENT"];
  systemParameters: SystemParameters[] = [];

  role: any = '';
  employer!: Employer;
  employerId:any = '';
  erfPayment:any;
  private isRoleEmployer: any = 'EmployerUserVO';
  private isRoleEmployerStaff: any = 'EmployerStaffUserVO';
  private isPayRollCompany: any = "PayrollCompanyUserVO";
  calculate = false;
  modalOptions: NgbModalOptions;
  closeResult: string = '';
  massCouponAvaliable:Boolean = false;
  showingMassCoupon = false;
  pdfData:any;
  fileURL:any;
  @ViewChild('pdfModal') pdfModal : any;
  @ViewChild('selectByPayPeriodModal') selectByPayPeriodModal : any;
  @ViewChild('byPayPeriodModal') byPayPeriodModal : any;
  
  pdfButton = false;
  selectedReport = 0;

  employerReports = new MatTableDataSource<any>([]);
  massImportFiles: any[] = [];
  reportTotals: any[] = [];
  reportTotalColumns:any[] = [];
  displayedColumns: string[] = ['agreement'];
  columnsToDisplay: string[] = this.displayedColumns.slice();

  blankErfForm:any;
  dates:any = [];

  byPayPeriodDate:any;
  byPayPeriodDates: any[] = [];
  objToSave = {payRollPeriodEnd: new Date(),};
  reportDate = new DateString();
  date = new FormControl(moment());
  minDate = new DateString();
  maxDate = new DateString();

  private alive: boolean = false;
  private count = 0;

  loading$ = this.loader.loading$;
  constructor(public loader: SpinnerService, public authService: AuthService, private importService: ImportService,private sanitizer: DomSanitizer,
    private route: ActivatedRoute, private userManagementService: UserManagementService, private employerReportService: EmployerReportService,
    private systemParameterService: SystemParametersService,private renderer: Renderer2,private changeDet:ChangeDetectorRef,
    private modalService: NgbModal, private router: Router) {
      this.modalOptions = {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      };
  }
    

  ngOnDestroy(){
    this.alive = false;
  }
  ngOnInit(): void {
    this.getPageParams();
    this.loadData();
  }
  getPageParams():void{
    this.employerId = atob(this.route.snapshot.paramMap.get('employerId')!);
  }
  loadData() {
    // Get User Role
    this.role = this.authService.getSignedinUserRole();
    this.getData();
    this.getSystemParameters();
  }
  getData(){
    this.userManagementService
    .getUserMassImportInfo(this.employerId)
    .subscribe(
      (response: any) => {
        this.employerReports.data = [];
        this.employer = response.result.employer;
        this.blankErfForm = response.result.blankErfForm;
        this.erfPayment = response.result.erfPayment;
        this.employerReports.data = response.result.listReports;
        this.massImportFiles = response.result.massImportFiles;
        this.reportTotals = response.result.reportTotals;
        this.reportTotalColumns = response.result.reportTotalColumns;
        this.massCouponAvaliable = response.result.massCouponAvaliable;
        for(let i = 0; i < response.result.reportTotalColumns.length;i++){
          if(!this.displayedColumns.includes(response.result.reportTotalColumns[i])){
            this.displayedColumns.push(response.result.reportTotalColumns[i]);
          }
        }
        if(response.result.reportTotalColumns != null && response.result.reportTotalColumns.length > 0 && !this.displayedColumns.includes('action') && !this.displayedColumns.includes('totalSum')){
          this.displayedColumns.push('totalSum');
          this.displayedColumns.push('action2');
          this.displayedColumns.push('action1');
        }
        this.columnsToDisplay= this.displayedColumns.slice();
        //Calcualte reports to get totals for fund columns
        if(this.calculate){
          this.massCalculate();
          this.calculate = false;
        }else{
          // this.authService.refreshToken().subscribe((result)=> {}, () => {});
          this.userManagementService
        .getUserReportOptions(this.employerId)
        .subscribe(
          (response: any) => {
            this.reportDate = new DateString(response.result.beginDate);
            const ctrlValue = moment(this.reportDate.dateString);
            this.date.setValue(ctrlValue);
            this.minDate = new DateString(response.result.minDate);
            this.maxDate = new DateString(response.result.maxDate);
          },
          (error: HttpErrorResponse) => {
            alert(error.error.reason);
          }
        );
        }
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      }
    );
  }
  getSystemParameters(){
    this.systemParameterService
      .getSystemParameterDetails(this.params)
      .subscribe(
        (response: any) => {
          this.systemParameters = response.result;
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
  }
  getTotalCost(columnIndex:any){
    var tot = 0;
    for(let i = 0; i < this.reportTotals.length;i++){
      tot = tot + this.reportTotals[i][columnIndex];
    }
    return tot;
  }
  getReportTotal(reportIndex:any){
    var tot = 0;
    for(let i = 0; i < this.reportTotalColumns.length;i++){
      if(this.reportTotalColumns[i] != 'Hours'){
        tot = tot + this.reportTotals[reportIndex][i];
      }
    }
    return tot;
  }
  getAllReportTotal(){
    var tot = 0;
    for(let j = 0; j < this.employerReports.data.length; j++){
      for(let i = 0; i < this.reportTotalColumns.length;i++){
        if(this.reportTotalColumns[i] != 'Hours'){
          tot = tot + this.reportTotals[j][i];
        }
      }
    }
    return tot;
  }
  getTotalAmount(){
    var tot = 0;
    for(let i = 0; i < this.erfPayment.length;i++){
      tot = tot + this.erfPayment[i][0].erfPmtAmtDue;
    }
    return tot;
  }
  save(event: any): void {
    this.selectedFiles = event.target.files
  }
  //Only runs while alive == true
  stayAlive(){
    this.count = 0;
    this.alive = true;
    timer(0, 900000)//Run initally and every 15 minutes
    .pipe(
      takeWhile(() => this.alive)
    )
    .subscribe(() => {
      // this.count = this.count +1;
      // console.log('REFRESH API CALL RAN : ' + this.count + ' TIMES');
      this.authService.refreshToken().subscribe((result)=> {}, () => {});
    });
  }
  //Create reports based on imports then
  //   grab data and re-calc to update totals
  runImport(byPay:Boolean){
    this.stayAlive();
    this.importService
      .runMassImport(this.selectedFiles,this.employerId,byPay ? this.convertDate(this.objToSave.payRollPeriodEnd) : null)
      .subscribe(
        (response: any) => {
          this.authService.refreshToken().subscribe((result)=> {}, () => {});
          // alert(response.message);
          this.calculate = true;
          this.getData();
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        },() =>{
          // this.alive = false;
        }
      );
  }
  massCalculate(){
    var list = [];
    for(let i = 0; i < this.employerReports.data.length;i++){
      list.push(this.employerReports.data[i].guid);
    }

    this.employerReportService
      .massCalculate(list)
      .subscribe((response:any) => {
        this.pdfData = response.result;
        this.employerReportService.pdfCoupon(response.result[0])
        .pipe(take(1))
        .subscribe((response1:any) => {
          let file = new Blob([response1], { type: 'application/pdf' });           
          this.fileURL = URL.createObjectURL(file);
          this.openPdf(this.pdfModal);
          // this.authService.refreshToken().subscribe((result)=> {}, () => {});
          // this.loadData();
          this.getData();
        },
        (error: HttpErrorResponse) => {
          alert(error.message);
        },() =>{
          this.alive = false;
        });
        // this.pdfData = response;
        // this.getData();
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      });
  }
  isEmployerOrStaff() {
    if (
      this.role == this.isRoleEmployer ||
      this.role == this.isRoleEmployerStaff
    ) {
      return true;
    } else {
      return false;
    }
  }
  isPayRoll(){
    if(this.role == this.isPayRollCompany){
      return true;
    } else {
      return false;
    }
  }
  pdfURL(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.fileURL);
  }
  openPdf(content: any) {
    console.log('open');
    this.modalService.open(content, {backdrop: 'static',backdropClass: 'customBackdrop',size:'xl'}).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  reportSelected(index:any){
    this.selectedReport = Number(index);
  }
  processPayment(){
    this.employerReportService.pdfCoupon(this.pdfData)
    .pipe(take(1))
    .subscribe((response:any) => {
      this.pdfButton = !this.pdfButton;
      let file = new Blob([response], { type: 'application/pdf' });           
      this.fileURL = URL.createObjectURL(file);
      // saveAs(file,'WebERF-'+new Date()+'.pdf');
      this.openPdf(this.pdfModal);
      this.authService.refreshToken().subscribe((result)=> {}, () => {});
    });
  }
  processReport(){
    this.employerReportService.pdfReport(this.pdfData)
    .pipe(take(1))
    .subscribe((response1:any) => {
      this.pdfButton = !this.pdfButton;
      let file = new Blob([response1], { type: 'application/pdf' });           
      this.fileURL = URL.createObjectURL(file);
      this.openPdf(this.pdfModal);
      this.authService.refreshToken().subscribe((result)=> {}, () => {});
    });   
  }
  getPdf(data: any) {
    this.pdfButton = false;
    this.showingMassCoupon = false;
    this.employerReportService.getSingleMassImportPdf(data).subscribe(
      (response: any) => {
        this.pdfData = response;
        this.employerReportService.pdfReport(response)
        .pipe(take(1))
        .subscribe((response1:any) => {
          let file = new Blob([response1], { type: 'application/pdf' });           
          this.fileURL = URL.createObjectURL(file);
          this.openPdf(this.pdfModal);
          this.authService.refreshToken().subscribe((result)=> {}, () => {});
        });
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      }
    );
  }
  goToLink(data: any) {
    console.log(data);
    if(this.employerId === '' || this.employerId == null){
      this.router.navigate([
        '/remittanceReport',
        { agreementId: btoa(data.guid), reportId: btoa(data.erfType.guid) },
      ]);
    }else{
      this.router.navigate([
        '/remittanceReport',
        { agreementId: btoa(data.guid), reportId: btoa(data.erfType.guid),employerId: btoa(data.employer.guid) },
      ]);
    }
  }
  deleteAll(){
    var list = [];
    for(let i = 0; i < this.employerReports.data.length;i++){
      list.push(this.employerReports.data[i].guid);
    }
    //set back to default values
    this.displayedColumns = ['agreement'];
    this.employerReports.data = [];
    this.selectedFiles = null;
    const fileControl = document.getElementById("fileControl") as HTMLInputElement;
    fileControl.value = ''
    this.employerReportService
      .deleteAllFromMassImport(list)
      .subscribe((response:any) => {
        alert(response.message);
        this.getData();
      });
  }
  finalizeAll(){
    var listErf = [];
    var listPmt = [];
    for(let i = 0; i < this.employerReports.data.length;i++){
      listErf.push(this.employerReports.data[i]);
      listPmt.push(this.erfPayment[i]);
    }
    this.employerReportService
    .finalizeAllFromMassImport(listErf,listPmt)
    .subscribe((response:any) => {
      // this.pdfData = response;
      this.pdfData = response;
        this.employerReportService.pdfCoupon(response[0])
        .pipe(take(1))
        .subscribe((response1:any) => {
          let file = new Blob([response1], { type: 'application/pdf' });           
          this.fileURL = URL.createObjectURL(file);
          this.showingMassCoupon = true;
          this.openPdf(this.pdfModal);
        });
    },
    (error: HttpErrorResponse) => {
      alert(error.message);
    });
  }
  showMassCoupon(){
    this.showingMassCoupon = true;
    this.employerReportService.pdfMassCoupon(this.massImportFiles[0].massImportInfo.guid)
        .pipe(take(1))
        .subscribe((response1:any) => {
          let file = new Blob([response1], { type: 'application/pdf' });           
          this.fileURL = URL.createObjectURL(file);
          this.openPdf(this.pdfModal);
        });
  }
  eftCheck(p:any){
    if(this.employer.allowEFT == 1
      && this.employer.bankStatus == 3
      && this.employer.bankAccountNumber != null
      && p.AllowEFT == 1
      && p.EFT_AccountNo_TO != null
      && this.isAffirmative(this.systemParameters[1].parameterValue1!)
      && p.erfPmtAmtDue != 0){
        return true;
    }
    return false
  }
  isEftColumnHidden(isField:boolean){
    if(!isField){
      return 'noscreen';
    }else{
      return 'nowrap'
    } 
  }
  isAffirmative(s: string) {
    if (
      s != null &&
      (s.toUpperCase().match('Y') ||
        s.toUpperCase().match('YES') ||
        s.match('1'))
    ) {
      return true;
    } else {
      return false;
    }
  }
  checkByPayPeriod(){
    this.openPdf(this.selectByPayPeriodModal);
  }
  byPayPeriodGetDates(){
    var obj = {
      payRollPeriodEnd: this.objToSave.payRollPeriodEnd,
      beginDate : this.reportDate.dateString == undefined ? this.reportDate : this.reportDate.dateString
    };
    //check for reportByPayPeriod vs report by workPeriod
    this.employerReportService.checkAllReportByPayPeriod(obj,this.employerId).subscribe(
      (response: any) => {
        console.log(response);
        if(response.result.length > 0){//Select Pay period before report creation
          this.byPayPeriodDates = response.result;
          this.modalService.dismissAll();
          this.openPdf(this.byPayPeriodModal);
        } else {
          alert('No pay periods available for active agreements. Contact Administrator.');
        }
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      }
    );
  }
  byPayPeriod(){
    if(this.byPayPeriodDate == null || this.byPayPeriodDate == undefined || this.byPayPeriodDate == '' || this.byPayPeriodDate == '--Select--'){
      alert('Pay period date must be selected to create report.');
      return;
    }
    this.objToSave.payRollPeriodEnd = new Date(this.byPayPeriodDate);
    this.modalService.dismissAll();
    this.runImport(true);
  }
  selectPayPeriod(event:any){
    const value = event.target.value;
    this.byPayPeriodDate = value;
  }

  //Example filter out holidays:                                   https://www.itsolutionstuff.com/post/angular-material-datepicker-disable-specific-dates-exampleexample.html
  //Example for date range that will work with holiday exclusion : https://www.concretepage.com/angular-material/angular-material-datepicker-disable-past-and-future-dates
  public myDateFilter = (d: Date | null): boolean => {
    var minDate:Date = new Date();
    var maxDate: Date = new Date(this.employerReports.data[0].workPeriodBegin);
    // add EFT_CUTOFF_DAY
    maxDate.setDate(maxDate.getDate() + Number(this.systemParameters[2].parameterValue1) -1);
    // add EFT_CUTOFF_MONTHS
    maxDate.setMonth(maxDate.getMonth() + Number(this.systemParameters[3].parameterValue1));
    const date = d || new Date();

    //Add Holidays to
    var myHolidayDates:Date[] = [];
    for(let h of this.blankErfForm.holidays){
      myHolidayDates.push(new Date(h.holiday));
    }
    //Disable Weekends
    if(!this.isAffirmative(this.systemParameters[4].parameterValue1!)){
      return date >= minDate && date <= maxDate && d?.getDay() !== 0 && d?.getDay() !== 6 && !myHolidayDates.find(x=>x.getTime()==date.getTime());
    }else{
      return date >= minDate && date <= maxDate && !myHolidayDates.find(x=>x.getTime()==date.getTime());
    }
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    var dqDate;
    var achSubmitDate;
    var achDQDate;
    const date = cellDate;
    if(this.blankErfForm.dc != null){
      dqDate = this.blankErfForm.dc.paymentDelinquencyDate;
      achSubmitDate = this.blankErfForm.dc.submitAchByDate;
      achDQDate = this.blankErfForm.dc.achDelinquencyDate;
      if(dqDate != null){
        dqDate = new Date(dqDate);
        if(dqDate.getFullYear() === date.getFullYear() && dqDate.getMonth() === date.getMonth() && dqDate.getDate() === date.getDate()){
          this.dates.push({date: this.dateToString(dqDate), text: "Delinquency Date"});
          return new Date(dqDate) ? 'dqDateStyle' : 'example-custom-date';
        }
      }else if(dqDate != null && achSubmitDate != null){
        dqDate = new Date(dqDate);
        achSubmitDate = new Date(achSubmitDate);
        if(date.getMonth() == dqDate.getMonth() && date.getDate() > dqDate.getDate() && date.getFullYear() == dqDate.getFullYear() &&
        date.getMonth() == achSubmitDate.getMonth() && date.getDate() < achSubmitDate.getDate() && date.getFullYear() == achSubmitDate.getFullYear()){
          return new Date(dqDate) ? 'achSubmitGraceStyle' : 'example-custom-date';
        }
      }else if(achSubmitDate != null){
        achSubmitDate = new Date(achSubmitDate);
        if(date.getMonth() == achSubmitDate.getMonth() && date.getDate() == achSubmitDate.getDate() && date.getFullYear() == achSubmitDate.getFullYear()){
          this.dates.push({date: this.dateToString(achSubmitDate), text: "Submit ACH By Date"});
          return new Date(dqDate) ? 'achSubmitDateStyle' : 'example-custom-date';
        }
      }else if(achDQDate != null){
        achDQDate = new Date(achDQDate);
        if(date.getMonth() == achDQDate.getMonth() && date.getDate() == achDQDate.getDate() && date.getFullYear() == achDQDate.getFullYear()){
          this.dates.push({date: this.dateToString(achDQDate), text: "ACH Delinquency Date"});
          return new Date(dqDate) ? 'achDQStyle' : 'example-custom-date';
        }
      }
    }

    var minDate:Date = new Date();
    if(minDate.getFullYear() === date.getFullYear() && minDate.getMonth() === date.getMonth() && minDate.getDate() === date.getDate()){
      return 'example-custom-date-class';
    }

    // Only highligh dates inside the month view.
    if (view === 'month') {
      //Highlight Holidays light red
      for(let h of this.blankErfForm.holidays){
        var holiday = new Date(h.holiday);
        if(holiday.getFullYear() === date.getFullYear() && holiday.getMonth() === date.getMonth() && holiday.getDate() === date.getDate()){
          this.dates.push({date: this.dateToString(holiday), text: "Holiday!"});
          return 'example-custom-date-class';
        }
      }
    }
    return 'example-custom-date';
  };
  displayMonth() {
    let elements = document.querySelectorAll(".endDate");
    let x = elements[0].querySelectorAll(".mat-calendar-body-cell");
    x.forEach(y => {
      const dateSearch = this.dateToString(
        new Date(y.getAttribute("aria-label")!)
        // new Date(y.getAttribute("aria-label"))
      );
      const data = this.dates.find((f: { date: string; }) => f.date == dateSearch);
      if (data) y.setAttribute("aria-label", data.text);
    });
  }
  streamOpened(event:any) {
    setTimeout(() => {
      let buttons = document.querySelectorAll("mat-calendar .mat-icon-button");

      buttons.forEach(btn =>
        this.renderer.listen(btn, "click", () => {
          setTimeout(() => {
            //debugger
            this.displayMonth();
          });
        })
      );
      this.displayMonth();
    });
  }
  dateToString(date: any) {
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2)
    );
  }
  finalizeOnClick(){
    var firstPayment:any;
    var allowShort:any;
    for(var j=0; j<this.erfPayment.length; j++){
      firstPayment = this.erfPayment[j][0].erfPmtAmtDue;
      allowShort = (this.eftCheck(this.erfPayment[j][0]) && this.isAffirmative(this.systemParameters[5].parameterValue1!));
      var paymentDetailsRows = this.erfPayment[j].length;
      for(var i=0; i<paymentDetailsRows; i++){
        const pmtTypeObj = document.getElementById("ERF_Pmt_Type") as HTMLInputElement;
        var pmtType = pmtTypeObj;
  
        
        if(pmtType != null) {
          const eftCreditAmount = document.getElementById("eftCreditAmount") as HTMLInputElement;
          const pmtDateObj = document.getElementById("eftPmtDate") as HTMLInputElement;
          var pmtDate = pmtDateObj;
          if(pmtDate.value != "" && pmtType.checked == false) {
            alert("Select the EFT checkbox or delete the payment date");
            return;
          }
          var rmk = this.employerReports.data[0].employerNotes;
          var crd;
          if(eftCreditAmount == null){
            crd = null;
          } else {
            crd = eftCreditAmount.value;
          }
          if(pmtType != null && pmtType.checked == true ) {
            if(pmtDate.value == "") {
              alert("Payment Date is required for EFT");
              return;
            }
  
            if(this.from_to_date(pmtDate.value, this.convertDate(new Date()))) {
              alert("Payment Date cannot be on or before current date");
              pmtDate.value = "";
              return;
            }
  
            if(this.from_to_date(pmtDate.value,this.convertDate(new Date(this.employerReports.data[0].workPeriodBegin)))) {
            // if(!this.from_to_date(this.convertDate(new Date(this.employerReport.workPeriodBegin)), pmtDate.value)) {
              alert("Payment Date cannot be before Report Period");
              pmtDate.value = "";
              return;
            }
  
            if(!this.from_to_date(this.convertDate(new Date(this.employer.eftProcessDate)), pmtDate.value)) {
              alert("You may submit payments via EFT beginning "+new Date(this.employer.eftProcessDate));
              pmtDate.value = "";
              return;
            }
            if (i == 0 && allowShort) {
              // var rmk = eval("document.frm.eftCreditNotes.value");
              // var crd = eval("document.frm.eftCreditAmount.value");
              if (crd == null){ crd = '0';};
              var amt = firstPayment;
              if (crd >= firstPayment) {
                alert("Credit amount must be less than amount due.");
                return;
              } else if (Number(crd) < 0) {
                alert("Credit amount must be a positive number.");
                return;
              }
              if (Number(crd) != 0 && rmk == "") {
                if (!confirm("You have not entered employer notes explaining the credit.\nDo you wish to continue anyway?")) {
                  return;
                }
              }
            }
          }
          if (crd != null && Number(crd) != 0 && pmtType.checked == false) {
            alert("Credits can only be applied to EFT payments.");
            return;
          }
        
          
          // if(pmtType != null) {
          this.erfPayment[j][i].ERF_Pmt_Type = 2;
          this.erfPayment[j][i].EFT_Pmt_Date = this.convertToSendDate(pmtDate.value);
          if (i==0 && eftCreditAmount != null && eftCreditAmount.value != null) {
            firstPayment = this.erfPayment[j][i].erfPmtAmtDue;
            this.erfPayment[j][i].eftCreditAmount = eftCreditAmount.value;
          } else {
            this.erfPayment[j][i].eftCreditAmount = 0;
          }
          // }
        }else {
          this.erfPayment[j][i].ERF_Pmt_Type = 1;
          this.erfPayment[j][i].EFT_Pmt_Date = null;
          this.erfPayment[j][i].eftCreditAmount = 0;
        }
      }
  
    }
    if(confirm("A report cannot be modified after it has been 'finalized'. \nDo you want to finalize all reports? "))
    {
      this.finalizeAll();
      return;
    }else{
      return false;
    }
  }
  from_to_date(from_date:any,to_date:any){
    var new_to_date, new_from_date,first_dash,second_dash,mm,dd,yy ;
    if(!!from_date) {
      first_dash = from_date.indexOf("/");
      second_dash = from_date.lastIndexOf("/");
      mm = from_date.substring(0,first_dash);
      dd = from_date.substring(first_dash+1,second_dash);
      yy = from_date.substring(second_dash+1,from_date.length);
      new_from_date = new Date(yy,mm-1,dd);
    }

    if(to_date != "") {
      first_dash = to_date.indexOf("/");
      second_dash = to_date.lastIndexOf("/");
      mm =to_date.substring(0,first_dash);
      dd =to_date.substring(first_dash+1,second_dash);
      yy =to_date.substring(second_dash+1,from_date.length);
      new_to_date = new Date(yy,mm-1,dd);
    }
    if (!!new_from_date && !!new_to_date && new_from_date > new_to_date ) {
      //alert("Note:  Completion Date cannot be before Start Date");
      //to_date.focus();
      // to_date.select();
      return (false);
    }
    return (true);
  }
  convertDate(d:any){
    return (d.getMonth() + 1) + '/' + d.getDate() + '/' + d.getFullYear();
  }
  convertToSendDate(str:any){
    var first_dash,second_dash,mm,dd,yy ;
    first_dash = str.indexOf("/");
    second_dash = str.lastIndexOf("/");
    mm =str.substring(0,first_dash);
    dd =str.substring(first_dash+1,second_dash);
    yy =str.substring(second_dash+1,str.length);
    var date = (yy.toString() + '-' + (Number(mm)-1).toString() + '-'+dd);
    // console.log(new Date(str));
    var finalDate = new Date(str);
    finalDate.setHours(12, 0, 0, 0); //set hours to 12pm since issue with timezone from clients
    return finalDate;
  }
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    this.reportDate = this.date.value._d;
    this.changeDet.detectChanges();//Detects changes and updates value
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.reportDate = this.date.value._d;
    console.log(this.reportDate);
    this.changeDet.detectChanges();//Detects changes and updates value
  }
}
