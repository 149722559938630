import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { NgbModalOptions, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DemographicQueue } from 'src/app/model/demographicQueue/demographic-queue';
import { UserManagement } from 'src/app/model/userManagement/user-management';
import { DemographicsService } from 'src/app/services/demographics/demographics.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-employee-demographics',
  templateUrl: './employee-demographics.component.html',
  styleUrls: ['./employee-demographics.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EmployeeDemographicsComponent implements OnInit {
  private isRoleEmployer: any = 'EmployerUserVO';
  private isRoleEmployerStaff: any = 'EmployerStaffUserVO';
  private isPayRollCompany: any = "PayrollCompanyUserVO";
  role: any = '';
  employerId:any = '';
  @ViewChild('addModal') addModal : any;
  modalOptions: NgbModalOptions;
  closeResult: string = '';
  displayedColumns: string[] = ['status','ssn', 'lastName', 'firstName', 'middleName','hireDate','termDate'];
  similarDisplayedColumns: string[] = ['status','ssn', 'firstName', 'middleName','lastName','hireDate','termDate'];
  displayedLocationInfoColumns: string[] = ['location','status','statusDate','hoursClass'];
  dataSource = new MatTableDataSource<any>([]);
  dataSourceAdd = new MatTableDataSource<any>([]);
  expandedElement: any | null;
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatTable) tableAdd!: MatTable<any>;
  @ViewChild(MatPaginator)
  private paginator!: MatPaginator;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 30, 40, 50];
  // MatPaginator Output
  pageEvent!: PageEvent;
  paginationLength = 0;
  searchValueId: any;
  employeeList: any[] = [];
  employeeSearchList: any[] = [];
  deliveryMethodList: any[] = [];
  statesList: any[] = [];
  personNameSuffixList: any[] =[];
  employmentStatusReason:any[]=[];
  agreementLocations: any[] = [];
  allowedHoursClasses:any[] =[];
  employer:any;
  // Handler for Home/Cell Phone
  tmpHomePhoneMid: any = '';
  tmpCellPhoneMid: any = '';
  tmpHomePhoneEnd: any = '';
  tmpCellPhoneEnd: any = '';

  //used for chunking data
  employmentHistoryGUIDS:any[] = [];

  tmpDemoQ: DemographicQueue = new DemographicQueue(null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null);
  loading$ = this.loader.loading$;
  constructor(public loader: SpinnerService,private demographicsService: DemographicsService,private modalService: NgbModal,public authService: AuthService,
    private route: ActivatedRoute) { 
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      size: 'l',
    };
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.employeeList);
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
    this.getPageParams();
    this.loadData();
  }
  loadData() {
    this.role = this.authService.getSignedinUserRole();
    //Get Employers Employees
    this.getEmployerEmployees();
  }
  getPageParams():void{
    this.employerId = atob(this.route.snapshot.paramMap.get('employerId')!);
  }
  getEmployerEmployees(): void {
      this.demographicsService
      .getEmployerEmployees(this.employerId)
      .subscribe(
        (response: any) => {
          this.employmentStatusReason = response.result.employmentStatusReason;
          this.allowedHoursClasses = response.result.allowedHoursClasses;
          this.deliveryMethodList = response.result.deliveryMethodList;
          this.statesList = response.result.statesList;
          this.personNameSuffixList = response.result.personNameSuffixList;
          this.employer = response.result.employer;
          this.agreementLocations = response.result.employers;
          this.dataSource.data = response.result.demographicQueue;
          this.employeeList = response.result.demographicQueue;
          this.employeeSearchList = response.result.demographicQueue;

          this.employmentHistoryGUIDS = response.result.employmentHistoryGUIDS;
          //Used to grab large amounts of data
          for(let i = 0; i < this.employmentHistoryGUIDS.length;i++){
            this.getEmploymentHistoryDetailsInChunks(this.employmentHistoryGUIDS[i]);
          }

          // console.log(this.agreementLocations);
          this.authService.refreshToken().subscribe((result)=> {}, () => {});
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
  }
  SearchId() {
    this.employeeList = this.employeeSearchList;
    this.dataSource.data = this.employeeSearchList;
    if (this.searchValueId != '') {
      var searchVal = this.searchValueId.replaceAll('-','');
      var tmp : any[] = [];
      for (var element of this.dataSource.data) {
        if (this.objectTypeCheck(element.mid)) {
          if(element.employee.ssn != null && (element.employee.ssn.toLocaleString().match(searchVal.toLowerCase()) || 
          element.employee.firstName.toLocaleString().toLowerCase().match(searchVal.toLowerCase()) || 
          element.employee.lastName.toLocaleString().toLowerCase().match(searchVal.toLowerCase()))){
            tmp.push(element);
          }
        } else {
          if(element.ssn != null && (element.ssn.toLocaleString().match(searchVal.toLowerCase()) || 
          element.firstName.toLocaleString().toLowerCase().match(searchVal.toLowerCase()) || 
          element.lastName.toLocaleString().toLowerCase().match(searchVal.toLowerCase()))){
            tmp.push(element);
          }
        }
      }
      this.dataSource.data = tmp;
    }
  }
  open(content: any) {
    // console.log(this.tmpDemoQ);
    // console.log('open');
    this.modalService.open(content, this.modalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  objectTypeCheck(data:any):boolean{
    if(data != undefined && data.includes("EmploymentHistoryVO")){
      return true;
    }
    return false;
  }

  getStatusHist(element:any){
    // console.log(element);
    var tmp = element.statusHistory;
    tmp = tmp.sort((a:any, b:any) => (new Date(a.statusEffDate) > new Date(b.statusEffDate) ? -1 : 1));
    for(var data of tmp){
      if(data.obsolete == false){
        return data.statusCode.parameterDesc
      }
    }
  }

  editExistingEmployeeForQ(data:any){
    this.tmpDemoQ = new DemographicQueue(null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null);
    if (this.objectTypeCheck(data.mid)) {
      //grab employmentHistory details
      this.getEmploymentHistory(data,null);
    }else{
      this.tmpDemoQ = data;
      this.tmpHomePhoneMid = data.homeLocalNumber?.substring(0,3);
      this.tmpHomePhoneEnd = data.homeLocalNumber?.substring(3);
      this.tmpCellPhoneMid = data.cellLocalNumber?.substring(0,3);
      this.tmpCellPhoneEnd = data.cellLocalNumber?.substring(3);
      this.updateAddTableData();
      // this.open(this.addModal);
    }
  }
  setDemoFromEmployee(data:any){
    this.tmpDemoQ.guid = data.guid;

    this.tmpDemoQ.ssn = data.employee.ssn;
    this.tmpDemoQ.firstName = data.employee.firstName;
    this.tmpDemoQ.middleName = data.employee.middleName;
    this.tmpDemoQ.lastName = data.employee.lastName;
    this.tmpDemoQ.nameSuffix = data.employee.nameSuffix;
    this.tmpDemoQ.emailAddress = data.employee.emailAddress;
    this.tmpDemoQ.gender = data.employee.gender;
    this.setDemoAddress(data.employee.locations[0].addressHistory);
    if(!!data.employee.phoneNumber){
      this.tmpDemoQ.homeAreaCode = data.employee.phoneNumber.areaCode;
      this.tmpDemoQ.homeLocalNumber = data.employee.phoneNumber.localNumber;
      this.tmpDemoQ.homeCountryCode = data.employee.phoneNumber.countryCode;
      this.tmpDemoQ.homeExtension = data.employee.phoneNumber.extension;
      this.tmpHomePhoneMid = this.tmpDemoQ.homeLocalNumber?.substring(0,3);
      this.tmpHomePhoneEnd = this.tmpDemoQ.homeLocalNumber?.substring(3);
    } 
    if(!!data.employee.cellNumber){
      this.tmpDemoQ.cellAreaCode = data.employee.cellNumber.areaCode;
      this.tmpDemoQ.cellLocalNumber = data.employee.cellNumber.localNumber;
      this.tmpDemoQ.cellCountryCode = data.employee.cellNumber.countryCode;
      this.tmpDemoQ.cellExtension = data.employee.cellNumber.extension;
      this.tmpCellPhoneMid = this.tmpDemoQ.cellLocalNumber?.substring(0,3);
      this.tmpCellPhoneEnd = this.tmpDemoQ.cellLocalNumber?.substring(3);
    }

    this.tmpDemoQ.preferredCommMethodCode = data.employee.preferredCommMethod;
    this.tmpDemoQ.dateOfBirthStr = data.employee.birthDate;
    this.tmpDemoQ.hireDate = data.hireDate;
    this.tmpDemoQ.termDate = data.termDate;

    //Other fields not shown on PopUp
    this.tmpDemoQ.employer = data.employer;
    this.tmpDemoQ.employerGUID = data.employer.guid;
    this.tmpDemoQ.person = data.employee;
    this.tmpDemoQ.personGUID = data.employee.guid;
    if(data.statusHistory.length > 0){
      this.tmpDemoQ.employementStatusGUID = data.statusHistory[0].statusCode.guid;
    }
  }
  setDemoAddress(data:any){
    for(var element of data){
      if((element.expDate == null && new Date(element.effDate) <= new Date() && element.obsolete == false) ||
      (element.expDate != null && new Date(element.expDate) > new Date() && new Date(element.effDate) <= new Date() && element.obsolete == false)){
        this.tmpDemoQ.addressLine1 = element.mailingAddress.addressLine1;
        this.tmpDemoQ.addressLine2 = element.mailingAddress.addressLine2;
        this.tmpDemoQ.addressLine3 = element.mailingAddress.addressLine3;
        this.tmpDemoQ.city = element.mailingAddress.city;
        this.tmpDemoQ.country = element.mailingAddress.country;
        this.tmpDemoQ.state = element.mailingAddress.state;
        this.tmpDemoQ.zipPostalCode = element.mailingAddress.zipPostalCode;
      }
    }
  }
  updateAddTableData(){
    var tmpH:any[] = [];
    var tmpQ:any[] = [];
    this.dataSourceAdd.data = [];
    for(var element of this.dataSource.data){
      if (this.objectTypeCheck(element.mid)) {
        if(element.employee.guid === this.tmpDemoQ.personGUID && element.guid != this.tmpDemoQ.guid){
          //grab grabemployment history details to replace element
          // tmpH.push(this.getAsyncData(element));
          this.getAsyncData(element).then((option: any)=>{
            tmpH.push(option);
            this.dataSourceAdd.data = this.dataSourceAdd.data.concat(option.result);
            this.tableAdd.renderRows();
          });
          // this.getEmploymentHistory(element,tmpH);
          // tmpH.push(element);
        }
      } else {
        if(element.ssn === this.tmpDemoQ.ssn && element.guid != this.tmpDemoQ.guid){
          console.log(element);
          tmpQ.push(element);
        }
      }
    }
    this.dataSourceAdd.data = tmpH;
    this.dataSourceAdd.data = this.dataSourceAdd.data.concat(tmpQ);
    // this.tableAdd.renderRows();
    this.open(this.addModal);
    this.authService.refreshToken().subscribe((result)=> {}, () => {});
  }
  addToDemoQ(){
    //Make GUID NULL this data is always new
    this.tmpDemoQ.guid = null;

    this.tmpDemoQ.employerGUID = this.employer.guid;
    this.tmpDemoQ.employer = this.employer;
    this.tmpDemoQ.person = {guid:0};
    this.tmpDemoQ.personGUID = 0;
    this.tmpDemoQ.status = 0;
    //Check Phone # Info
    if(!!this.tmpDemoQ.homeAreaCode){
      this.tmpDemoQ.homeLocalNumber = this.tmpHomePhoneMid + this.tmpHomePhoneEnd;
    }
    if(!!this.tmpDemoQ.cellAreaCode){
      this.tmpDemoQ.cellLocalNumber = this.tmpCellPhoneMid + this.tmpCellPhoneEnd;
    }

    console.log(this.tmpDemoQ);
    this.demographicsService
      .addEmployee(this.tmpDemoQ)
      .subscribe(
        (response: any) => {
        //  console.log(response);
          this.loadData();
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
      this.modalService.dismissAll('close');
      this.clearDemoQ();
  }
  clearDemoQ(){
    this.searchValueId = '';
    this.tmpDemoQ = new DemographicQueue(null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null);
    this.resetPhone();
  }
  clearAddTable(){
    this.resetPhone();
    this.dataSourceAdd.data = [];
  }
  resetPhone(){
    this.tmpHomePhoneMid = null;
    this.tmpHomePhoneEnd = null;
    this.tmpCellPhoneMid = null;
    this.tmpCellPhoneEnd = null;
  }
  validationChecker(){
    var valid = false;
    var str = '';
    //Check filed if they are valid
    if(!this.validSsn(this.tmpDemoQ.ssn)){
      str += "SSN must be a total of 9 numbers only. \n";
    }
    if(!this.validName(this.tmpDemoQ.firstName)){
      str += "Enter a First Name. \n";
    }
    if(!this.validName(this.tmpDemoQ.lastName)){
      str += "Enter a Last Name. \n";
    }
    if(this.tmpDemoQ.nameSuffix === "null"){
      this.tmpDemoQ.nameSuffix = null;
    }
    if(this.tmpDemoQ.state === "null"){
      this.tmpDemoQ.state = null;
    }
    if((!!this.tmpDemoQ.addressLine1 && (!this.tmpDemoQ.city || !this.tmpDemoQ.state || !this.tmpDemoQ.zipPostalCode)) || 
    (!this.tmpDemoQ.addressLine1 && (!!this.tmpDemoQ.city || !!this.tmpDemoQ.state || !!this.tmpDemoQ.zipPostalCode)) ){
      str += 'Complete entry of full address (address 1, city and zip code) or remove full address by focusing on each field and pressing the DELETE key.\n';
    }
    if(!!this.tmpDemoQ.homeAreaCode || !!this.tmpHomePhoneMid || !!this.tmpHomePhoneEnd){
      if(!this.validPhone(this.tmpDemoQ.homeAreaCode,3) || !this.validPhone(this.tmpHomePhoneMid,3) || !this.validPhone(this.tmpHomePhoneEnd,4)){
        str += 'Fill out whole Home Phone number. Make sure it is all numeric. \n';
      } 
    }
    if(!!this.tmpDemoQ.cellAreaCode || !!this.tmpCellPhoneMid || !!this.tmpCellPhoneEnd){
      if(!this.validPhone(this.tmpDemoQ.cellAreaCode,3) || !this.validPhone(this.tmpCellPhoneMid,3) || !this.validPhone(this.tmpCellPhoneEnd,4)){
        str += 'Fill out whole Cell Phone number. Make sure it is all numeric. \n';
      }
    }
    //Location / Employment Info / Hours Class
    if(this.tmpDemoQ.locationGUID == "null" || this.tmpDemoQ.locationGUID == null || this.tmpDemoQ.locationGUID == undefined || this.tmpDemoQ.locationGUID == ''){
      str += 'Location is a required field. \n';
    }
    if(this.tmpDemoQ.employementStatusGUID == "null" || this.tmpDemoQ.employementStatusGUID == null || this.tmpDemoQ.employementStatusGUID == undefined || this.tmpDemoQ.employementStatusGUID == ''){
      str += 'Employment Status is a required field. \n';
    }
    if(this.tmpDemoQ.employementStatusDate == null || this.tmpDemoQ.employementStatusDate == undefined || this.tmpDemoQ.employementStatusDate == ''){
      str += 'Employment Date is a required field. \n';
    } else if(this.tmpDemoQ.employementStatusDate > new Date()){
      str += 'Employment Date can not be after after the current date. \n';
    }
    if(this.tmpDemoQ.hoursClassGUID == "null" || this.tmpDemoQ.hoursClassGUID == null || this.tmpDemoQ.hoursClassGUID == undefined || this.tmpDemoQ.hoursClassGUID == ''){
      str += 'Hours Class is a required field. \n';
    }
    

    if(!str){
      // alert(str);
      valid = true;
    }else{
      alert(str);
      // valid = true;
    }
    if(valid){
      this.addToDemoQ();
    }
  }
  validSsn(data:any){
    if(data == null){return false;}
    if(data.length == 9 && Number(data)){
      return true;
    }
    return false;
  }
  validName(data:any){
    if(data == null){return false;}
    if(data.length > 0){
      return true;
    }
    return false;
  }
  validPhone(data:any,num:number){
    if(data == null){return false;}
    if(data.length == num && Number(data)){
      return true;
    }
    return false;
  }
  isEmployerOrStaff() {
    if (
      this.role == this.isRoleEmployer ||
      this.role == this.isRoleEmployerStaff
    ) {
      return true;
    } else {
      return false;
    }
  }
  isPayRoll(){
    if(this.role == this.isPayRollCompany){
      return true;
    } else {
      return false;
    }
  }
  getEmploymentHistory(data:any,tmpH:any){
    this.demographicsService
    .getEmploymentHistoryDetails(data)
    .subscribe(
      (response: any) => {
        // console.log(response);
        this.setDemoFromEmployee(response.result);
        this.updateAddTableData();
        // this.open(this.addModal);
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      }
    );
  }
  getEmploymentHistoryDetailsInChunks(data:any){
    this.demographicsService
    .getEmploymentHistoryDetailsInChunks(data)
    .subscribe(
      (response: any) => {
        // console.log(response);
        this.dataSource.data = this.dataSource.data.concat(response.result);
        this.employeeList = this.employeeList.concat(response.result);
        this.employeeSearchList = this.employeeSearchList.concat(response.result);
        this.authService.refreshToken().subscribe((result)=> {}, () => {});
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      }
    );
  }
  async getAsyncData(data:any) {
    const asyncResult = await this.demographicsService.getEmploymentHistoryDetails(data).toPromise();
    return asyncResult
  }
  ssnFormater(data:any){
    if(data == null || data == ""){ return data;}
    if(data.length < 3){
      return data;
    } else if(data.length < 5){
      return  data.slice(0,3) + '-' + data.slice(3,5);
    } else {
      return  data.slice(0,3) + '-' + data.slice(3,5) + '-' + data.slice(5);
    }
  }
  updateSsnData(data:any){
    this.tmpDemoQ.ssn = data.replaceAll('-', '');
  }
  snnFormatEditer(ssn:any){
    ssn.value = ssn.value.replaceAll('-', '');
  }
  locationOption(data:any){
    // return data.activeProjects[0].project.projectID;
    return (data.organizationID.toString() + ' | ' + data.organizationName.toString());
  }
}