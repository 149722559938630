import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Employer } from 'src/app/model/userManagement/employer';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployerService {
  private apiServerUrl = environment.apiBaseUrl + '/spring';

  constructor(private http: HttpClient) { }

  public getAllEmployers(): Observable<Employer[]>{
    return this.http.get<any>(`${this.apiServerUrl}/Employer/all/`);
  }
  public getEmployer(): Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/Employer/info/`);
  }
  public getEmployerEftAccountDetails(): Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/Employer/EftAccountDetails/`);
  }
  saveEmployerEftAccountDetails(data: any): Observable<any>{
    return this.http.post<any>(`${this.apiServerUrl}/Employer/save/EftAccountDetails/`,data);
	}
  removeEmployerEftAccountDetails(data: any): Observable<any>{
    return this.http.post<any>(`${this.apiServerUrl}/Employer/save/removeEftAccountDetails/`,data);
	}
  getAllEmployerClassMappings(id:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('guid', id);
    return this.http.get<any>(`${this.apiServerUrl}/Employer/getAllEmployerClassMappings`,{ params: Params });
	}
  deleteSingleEmployerClassMapping(ecm: any): Observable<any>{
    return this.http.post(`${this.apiServerUrl}/Employer/deleteSingleEmployerClassMapping`,ecm);
	}
  getEmployerStaffInfo(): Observable<any>{
    return this.http.get<any>(`${this.apiServerUrl}/Employer/Staff/Info`);
	}
  updateEmployerStaff(guid:any,firstname:any,lastname:any,email:any,inactive:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('guid', guid);
    Params = Params.append('firstname', firstname);
    Params = Params.append('lastname', lastname);
    Params = Params.append('email', email);
    Params = Params.append('inactive', inactive);
    return this.http.get<any>(`${this.apiServerUrl}/Employer/Staff/updateEmployerStaff`,{ params: Params });
	}
  resetEmployerStaffPassword(guid:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('guid', guid);
    return this.http.get<any>(`${this.apiServerUrl}/Employer/Staff/resetEmployerStaffPassword`,{ params: Params });
	}
  createEmployerStaff(username:any,firstname:any,lastname:any,email:any,inactive:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('username', username);
    Params = Params.append('firstname', firstname);
    Params = Params.append('lastname', lastname);
    Params = Params.append('email', email);
    Params = Params.append('inactive', inactive);
    return this.http.get<any>(`${this.apiServerUrl}/Employer/Staff/createEmployerStaff`,{ params: Params });
	}
  checkIfUserNameExists(username:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('username', username);
    return this.http.get<any>(`${this.apiServerUrl}/Employer/Staff/checkIfUserNameExists`,{ params: Params });
	}
}
