import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserManagementService } from 'src/app/services/userManagement/user-management.service';
import { DatePipe } from '@angular/common';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { Employer } from 'src/app/model/userManagement/employer';
import {
  NgbModal,
  NgbModalOptions,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { EmployerReportService } from 'src/app/services/employerReport/employer-report.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { Observable, take } from 'rxjs';
import { SystemParameters } from 'src/app/model/systemParameters/system-parameters';
import { SystemParametersService } from 'src/app/services/systemParameter/system-parameters.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SystemParameterConstants } from 'src/app/model/constants/system-parameter-constants';

@Component({
  selector: 'app-list-reports',
  templateUrl: './list-reports.component.html',
  styleUrls: ['./list-reports.component.scss'],
  providers: [DatePipe],
})
export class ListReportsComponent implements OnInit {
  employerId:any = '';
  private isRoleEmployer: any = 'EmployerUserVO';
  private isRoleEmployerStaff: any = 'EmployerStaffUserVO';
  private isRoleLiaison: any = "WEBERFLIAISON";
  private isRoleAuditor: any = "WEBERFAUDITOR";
  private isPayRollCompany: any = "PayrollCompanyUserVO";
  role: any = '';
  employer!: Employer;
  allReports: any[] = [];
  tmpListRpts: any[]=[];
  unfinishedReports: any[] = [];
  finalizedReports: any[] = [];
  modalOptions: NgbModalOptions;
  closeResult: string = '';
  deleteAgreement: any = '';
  pdfData: any;
  fileURL:any;
  @ViewChild('pdfModal') pdfModal : any;
  employerReport:any;
  pdfButton = false;
  showingMassCoupon = false;
  isProjectBased = false;

  systemParameters: SystemParameters[] = [];
  params:any = [SystemParameterConstants.PAYMENT_COUPON_B4_FINAL,
    SystemParameterConstants.UNFINALIZE_ADMIN_ENABLE];

  loading$ = this.loader.loading$;
  constructor(
    public loader: SpinnerService,
    public authService: AuthService,
    private datePipe: DatePipe,
    private userManagementService: UserManagementService,
    private router: Router,
    private modalService: NgbModal,
    private employerReportService: EmployerReportService,
    private systemParameterService: SystemParametersService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
    };
  }

  ngOnInit(): void {
    this.getPageParams();
    this.loadData();
  }

  loadData() {
    // Get User Role
    this.role = this.authService.getSignedinUserRole();
    //Get System Parameters
    this.systemParameterService
    .getSystemParameterDetails(this.params)
    .subscribe(
      (responseP: any) => {
        this.systemParameters = responseP.result;
        // this.authService.refreshToken().subscribe((result)=> {}, () => {});
        //Get Employer Details & Get Announcements
        this.getUserInfo();
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      }
    );
  }

  getUserInfo(): void {
    this.userManagementService.getUserListReports(this.employerId).subscribe(
      (response: any) => {
        // this.allReports = response.result.listReports;
        this.isProjectBased = response.result.isProjectBased;
        this.tmpListRpts = [];
        for(let i = 0; i < response.result.listReportGUIDS.length;i++){
          this.getReportsInChunks(response.result.listReportGUIDS[i]);
        }
        this.employer = response.result.employer;
        // this.sortOutLists();
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      }
    );
  }
  getReportsInChunks(data:any){
    this.userManagementService
    .getReportsInChunks(this.employerId,data)
    .subscribe(
      (response: any) => {
        this.tmpListRpts = this.tmpListRpts.concat(response.result);
        this.allReports = this.tmpListRpts;
        // this.sortOutLists(this.tmpListRpts);
        this.sortOutLists();
        this.authService.refreshToken().subscribe((result)=> {}, () => {});
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      }
    );
  }

  sortOutLists() {
    this.allReports.sort((a,b) => (a.workPeriodBegin > b.workPeriodBegin ? -1 : 1));
    this.unfinishedReports = [];
    this.finalizedReports = [];

    for (let i = 0; i < this.allReports.length; i++) {
      if (
        this.allReports[i].referenceNo == null &&
        this.allReports[i].agreement != null
      ) {
        this.unfinishedReports.push(this.allReports[i]);
        // console.log(this.allReports[i].guid);
      } else if (
        this.allReports[i].referenceNo != null &&
        this.allReports[i].agreement != null
      ) {
        this.finalizedReports.push(this.allReports[i]);
      }
    }
    this.createTreeData(this.finalizedReports);
  }
  createTreeData(rept: any) {
    var map = new Map();
    var tmpList: AgreementNode[] = [];
    for (let i = 0; i < rept.length; i++) {
      var year = this.datePipe
        .transform(rept[i].workPeriodBegin, 'yyyy')
        ?.toString();
      var month = this.datePipe
        .transform(rept[i].workPeriodBegin, 'MMMM')
        ?.toString();
      var tmp;
      var index = tmpList.findIndex((x) => x.name === year?.toString()!);
      if (!map.has(month?.toString()! + ', ' + year?.toString()!)) {
        // If map doesnt have month,year add it to map and Tree Data
        map.set(
          month?.toString()! + ', ' + year?.toString()!,
          year?.toString()!
        );
        //If map has year add month to list
        if (index != -1) {
          var tmpIndex = tmpList[index].children?.findIndex(
            (x) => x.name === month?.toString()! + ', ' + year?.toString()!
          );
          if (tmpIndex == -1) {
            tmp = {
              name: month?.toString()! + ', ' + year?.toString()!,
              children: [{ name: rept[i] }],
            };
            tmpList[index].children?.push(tmp);
          }
        } else {
          tmp = {
            name: year?.toString()!,
            children: [
              {
                name: month?.toString()! + ', ' + year?.toString()!,
                children: [{ name: rept[i] }],
              },
            ],
          };
          tmpList.push(tmp);
        }
      } else {
        // Add to existing month when month exists for same year
        var tmpIndex = tmpList[index].children?.findIndex(
          (x) => x.name === month?.toString()! + ', ' + year?.toString()!
        );
        tmp = { name: rept[i] };
        tmpList[index].children![tmpIndex!].children.push(tmp);
      }
    }
    this.dataSource.data = tmpList;
  }

  setAgreementLineEnd(data: any) {
    var tmp: string = data.erCba.rateAsOfType.parameterCode;
    var str: string = tmp.match('FROZEN')
      ? 'Frozen Rates (' +
        this.datePipe.transform(data.erCba.rateAsOfDate, 'M/d/YYYY') +
        ')'
      : 'Current Rates';

    str = this.setProjectTag(data) + str;
    return str;
  }
  deleteSetItem(data: any) {
    console.log(data);
    this.deleteAgreement = data;
  }
  deleteAction(): void {
    if (this.deleteAgreement.guid != null) {
      this.employerReportService
        .deleteEmployerReport(this.deleteAgreement.guid)
        .subscribe(
          (response: any) => {
            if (response.result) {
              this.unfinishedReports.forEach((element, index) => {
                if (element.guid == this.deleteAgreement.guid)
                  // delete this.unfinishedReports[index];
                  this.unfinishedReports.splice(index, 1);
                  
              });
              this.authService.refreshToken().subscribe((result)=> {}, () => {});
            }
          },
          (error: HttpErrorResponse) => {
            alert(error.error.reason);
          }
        );
    }
    // this.loadData();
  }
  //Only for finalized reports for admin
  unfinalizeReport(){
    this.employerReportService
        .unfinalizeEmployerReport(this.employerReport.guid)
        .subscribe(
          (response: any) => {
            if (response.result) {
              this.dataSource.data = [];
              this.allReports = [];
              this.unfinishedReports = [];
              this.finalizedReports = [];
              this.loadData();
            }
          },
          (error: HttpErrorResponse) => {
            alert(error.error.reason);
          }
        );
  }
  getPageParams():void{
    this.employerId = atob(this.route.snapshot.paramMap.get('employerId')!);
  }
  goToLink(data: any) {
    console.log(data);
    if(this.employerId === '' || this.employerId == null){
      this.router.navigate([
        '/remittanceReport',
        { agreementId: btoa(data.guid), reportId: btoa(data.erfType.guid) },
      ]);
    }else{
      this.router.navigate([
        '/remittanceReport',
        { agreementId: btoa(data.guid), reportId: btoa(data.erfType.guid),employerId: btoa(data.employer.guid) },
        // {agreementId:data.guid,reportId:data.erfType.guid,sort1:data.sort1,sort2:data.sort2,sort3:data.sort3,edi:data.referenceNo}
      ]);
    }
  }
  getPdf(data: any) {
    // console.log(data);
    this.pdfButton = false;
    this.showingMassCoupon = false;
    this.employerReport = data;
    this.employerReportService.getListReportPdfs(data).subscribe(
      (response: any) => {
        this.pdfData = response;
        this.employerReportService.pdfReport(response)
        .pipe(take(1))
        .subscribe((response1:any) => {
          let file = new Blob([response1], { type: 'application/pdf' });           
          this.fileURL = URL.createObjectURL(file);
          this.openPdf(this.pdfModal);
          this.authService.refreshToken().subscribe((result)=> {}, () => {});
        });
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      }
    );
  }
  pdfURL(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.fileURL);
  }
  processPayment(){
    this.employerReportService.pdfCoupon(this.pdfData)
        .pipe(take(1))
        .subscribe((response:any) => {
          this.pdfButton = !this.pdfButton;
          let file = new Blob([response], { type: 'application/pdf' });           
          this.fileURL = URL.createObjectURL(file);
          // saveAs(file,'WebERF-'+new Date()+'.pdf');
          this.openPdf(this.pdfModal);
          this.authService.refreshToken().subscribe((result)=> {}, () => {});
        });
  }
  processReport(){
    this.employerReportService.pdfReport(this.pdfData)
    .pipe(take(1))
    .subscribe((response1:any) => {
      this.pdfButton = !this.pdfButton;
      let file = new Blob([response1], { type: 'application/pdf' });           
      this.fileURL = URL.createObjectURL(file);
      this.openPdf(this.pdfModal);
      this.authService.refreshToken().subscribe((result)=> {}, () => {});
    });
  }
  isEmployerOrStaff() {
    if (
      this.role == this.isRoleEmployer ||
      this.role == this.isRoleEmployerStaff
    ) {
      return true;
    } else {
      return false;
    }
  }
  isLiaison(){
    if(this.role == this.isRoleLiaison){
      return true;
    } else {
      return false;
    }
  }
  isAuditor() {
    if (this.role == this.isRoleAuditor) {
      return true;
    } else {
      return false;
    }
  }
  isPayRoll(){
    if(this.role == this.isPayRollCompany){
      return true;
    } else {
      return false;
    }
  }
  open(content: any) {
    console.log('open');
    this.modalService.open(content, this.modalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  openPdf(content: any) {
    console.log('open');
    this.modalService.open(content, {backdrop: 'static',backdropClass: 'customBackdrop',size:'xl'}).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  dateToString(str:string){
    if(str == null){return '';}
    return Number(str.substring(5,7)) + '/' + Number(str.substring(8,10)) + '/' + str.substring(0,4);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  isAffirmative(s: string) {
    if (
      s != null &&
      (s.toUpperCase().match('Y') ||
        s.toUpperCase().match('YES') ||
        s.match('1'))
    ) {
      return true;
    } else {
      return false;
    }
  }
  setProjectTag(data:any){
    if(this.isProjectBased){
      if(data.erCba.projects != null && data.erCba.projects != undefined && data.erCba.projects.length != 0){
        return '['+data.erCba.projects[0].project.projectName +'] ';
      }
    }
    return ""
  }
  showMassCoupon(data: any){
    this.showingMassCoupon = true;
    this.employerReport = data;
    this.employerReportService.pdfMassCoupon(data.massImportInfo.guid)
        .pipe(take(1))
        .subscribe((response1:any) => {
          let file = new Blob([response1], { type: 'application/pdf' });           
          this.fileURL = URL.createObjectURL(file);
          this.openPdf(this.pdfModal);
        });
  }
  // Everything Below is for TreeView
  private _transformer = (node: AgreementNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
}
interface AgreementNode {
  name: string;
  children?: any[];
}
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}
