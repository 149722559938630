import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NgbModalOptions, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Employer } from 'src/app/model/userManagement/employer';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EmployerService } from 'src/app/services/employer/employer.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-manage-staff',
  templateUrl: './manage-staff.component.html',
  styleUrls: ['./manage-staff.component.scss']
})
export class ManageStaffComponent implements OnInit {

  role: any = '';
  employer!: Employer;
  employerId:any = '';
  private isRoleEmployer: any = 'EmployerUserVO';
  private isRoleEmployerStaff: any = 'EmployerStaffUserVO';
  selectedUser:any;
  selectedUserRandomPass:any;
  isReset = true;

  @ViewChild('resetPassModal') resetPassModal : any;
  modalOptions: NgbModalOptions;
  closeResult: string = '';

  employeeMapping = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['username', 'firstname', 'lastname','email','inactive','lastlogin','action'];
  @ViewChild(MatPaginator)
  private paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 30, 40, 50];
  pageEvent!: PageEvent;

  loading$ = this.loader.loading$;
  constructor(
    public loader: SpinnerService,private route: ActivatedRoute,private modalService: NgbModal,
    public authService: AuthService,private employerService: EmployerService) { 
      this.modalOptions = {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
        size: 'lg',
      };
    }

    ngAfterViewInit() {
      this.employeeMapping.paginator = this.paginator;
      this.employeeMapping.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'username': return item.username;
          case 'firstname' : return item.firstName;
          case 'lastname' : return item.lastName;
          case 'email' : return item.emailAddress;
          default: return item[property];
        }
      };
      this.employeeMapping.sort = this.sort;
    }
    ngOnInit(): void {
      this.getPageParams();
      this.loadData();
    }
    getPageParams():void{
      this.employerId = atob(this.route.snapshot.paramMap.get('employerId')!);
    }
    loadData() {
      // Get User Role
      this.role = this.authService.getSignedinUserRole();
      this.getEmployerStaffInfo();
    }
    getEmployerStaffInfo(){
      this.employerService
      .getEmployerStaffInfo()
      .subscribe(
        (response: any) => {
          this.employer = response.result.employer;
          this.employeeMapping.data = response.result.staffList;
          this.authService.refreshToken().subscribe((result)=> {}, () => {});
        },
        (error: HttpErrorResponse) => {
          alert(error.message);
        }
      );
    }

  isEmployerOrStaff() {
    if (
      this.role == this.isRoleEmployer ||
      this.role == this.isRoleEmployerStaff
    ) {
      return true;
    } else {
      return false;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.employeeMapping.filter = filterValue.trim().toLowerCase();

    if (this.employeeMapping.paginator) {
      this.employeeMapping.paginator.firstPage();
    }
  }
  open(content: any) {
    // console.log(this.tmpDemoQ);
    // console.log('open');
    this.modalService.open(content, this.modalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  updateUser(){
    this.employerService
    .updateEmployerStaff(this.selectedUser.guid,this.selectedUser.firstName,this.selectedUser.lastName,this.selectedUser.emailAddress,this.selectedUser.inactive)
    .subscribe(
      (response: any) => {
        this.modalService.dismissAll();
        this.getEmployerStaffInfo();
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      }
    );
  }
  createUser(){
    // check if username is available
    this.employerService
      .checkIfUserNameExists(this.selectedUser.username)
      .subscribe(
        (response: any) => {
          // add user
          this.employerService
            .createEmployerStaff(this.selectedUser.username,this.selectedUser.firstName,this.selectedUser.lastName,this.selectedUser.emailAddress,this.selectedUser.inactive)
            .subscribe(
              (response: any) => {
                this.selectedUserRandomPass = response.result;
                this.isReset = false;
                this.modalService.dismissAll();
                this.open(this.resetPassModal);
                this.getEmployerStaffInfo();
              },
              (error: HttpErrorResponse) => {
                alert(error.error.reason);
              }
            );
          ////
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
  }
  resetUserPassword(){
    if(confirm("Unsaved changes will be lost. \nClick OK to obtain new password or Cancel to continue editing.")){
      this.employerService
        .resetEmployerStaffPassword(this.selectedUser.guid)
        .subscribe(
          (response: any) => {
            this.selectedUserRandomPass = response.result;
            this.isReset = true;
            this.modalService.dismissAll();
            this.open(this.resetPassModal);
            this.getEmployerStaffInfo();
          },
          (error: HttpErrorResponse) => {
            alert(error.message);
          }
        );
    }
  }

}
