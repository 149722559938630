export class SystemParameterConstants {
    public static WEBERF_OFFLINE: string= "WEBERF_OFFLINE";
	public static WEBERF_OFFLINE_MESSAGE: string= "WEBERF_OFFLINE_MESSAGE";

    public static ADMINREPORTS: string= "ADMINREPORTS";
	public static LOGON_TITLE: string= "LOGON_TITLE";
	public static WELCOME_TITLE: string= "WELCOME_TITLE";
	public static TRUST_NAME: string= "TRUST_NAME";
	public static TRUST_ADDRESS1: string= "TRUST_ADDRESS1";
	public static TRUST_ADDRESS2: string= "TRUST_ADDRESS2";
	public static TRUST_PHONE1: string= "TRUST_PHONE1";
	public static TRUST_PHONE2: string= "TRUST_PHONE2";
	public static TRUST_FAX: string= "TRUST_FAX";
	public static TRUST_DEPT: string= "TRUST_DEPT";
	public static PLAN_ADMINISTRATOR: string= "PLAN_ADMINISTRATOR";
	public static TRUST_EMAIL_ADDRESS: string= "TRUST_EMAIL_ADDRESS";
	public static TRUST_CONTACT: string= "TRUST_CONTACT";
	public static TRUST_PHONE_TOLLFREE: string= "TRUST_PHONE_TOLLFREE";
	public static CUSTOM_CONTACT_INCLUDE: string= "CUSTOM_CONTACT_INCLUDE";

	public static OWNER_OPERATOR: string= "OWNER_OPERATOR";
	public static WEBERF_ROOT_FOLDER: string= "WEBERF_ROOT_FOLDER";
	public static MAIL_SMTP_HOST: string= "MAIL_SMTP_HOST";
	public static INSTALLATION_NAME: string= "INSTALLATION_NAME";

	public static LOGOFF: string= "LOGOFF";
	public static COMPANY_ID: string= "COMPANY_ID";
	public static ALLOW_JCTYPE_ALL: string= "ALLOW_JCTYPE_ALL";
	public static HRS_REG_ALLOW_ZERO: string= "HRS_REG_ALLOW_ZERO";  // Only when there's some other hours
	public static HRS_MAX: string = "HRS_MAX";
	public static HRS_MIN: string= "HRS_MIN";
	public static WAGES_MAX: string = "WAGES_MAX";
	public static WAGES_MIN: string= "WAGES_MIN";
	public static INCL_DEMOGRAPHICS_ON_ERF: string= "INCL_DEMOGRAPHICS_ON_ERF";
	public static DISPLAY_VARIANT_SIGTYPE: string= "DISPLAY_VARIANT_SIGTYPE";
	public static CHANGE_PW_AFTER_LOGIN: string = "CHANGE_PW_AFTER_LOGIN";
	public static WEBERF_RESTRICTED_FOLDER: string  = "WEBERF_RESTRICTED_FOLDER";
	public static WEBERF_REPORT_XFORM_FILE: string = "WEBERF_REPORT_XFORM_FILE";
	public static WEBERF_XFORM_OUTPUT_EXT: string  = "WEBERF_XFORM_OUTPUT_EXT";
	public static WEBERF_XFORM_CREATE_EMPTY: string = "WEBERF_XFORM_CREATE_EMPTY";
	public static WEBERF_XFORM_EMPTY_HEADER: string = "WEBERF_XFORM_EMPTY_HEADER";
	public static FUND_XML_FROM_INTEGRATED: string  = "FUND_XML_FROM_INTEGRATED";
	public static POA_XFORM_FILE: string = "POA_XFORM_FILE";
	public static POA_XFORM_EXT: string = "POA_XFORM_EXT";

	//these are new system email parameters
	public static SYSTEM_EMAIL_FROM: string= "SYSTEM_EMAIL_FROM";
	public static SYSTEM_EMAIL_TO: string= "SYSTEM_EMAIL_TO";

	public static REPORT_MONTH: string = "REPORT_MONTH";
	public static REPORT_SHOW_DAY: string= "REPORT_SHOW_DAY";
	public static REPORT_DEFAULT_DAY: string = "REPORT_DEFAULT_DAY";

	public static DO_NIGHTLY_PROCESSING: string = "DO_NIGHTLY_PROCESSING";
	public static DO_EMAIL_ATTACHMENT: string = "DO_EMAIL_ATTACHMENT";
	public static MAX_EXPORT_REPORTS: string= "MAX_EXPORT_REPORTS";
	public static AUTOMATE_RATE_IMPORT: string= "AUTOMATE_RATE_IMPORT";
	public static RATE_IMPORT_START_TIME: string= "RATE_IMPORT_START_TIME";	// Default is in WebERFStartup
	public static FUND_EXPORT_START_TIME: string= "FUND_EXPORT_START_TIME";	// Default is in WebERFStartup
	public static DB_SYNCH_INTERVAL: string = "DB_SYNCH_INTERVAL";

	public static EFT_ACTIVATED: string= "EFT_ACTIVATED";
	public static TEST_EFT_NO_UPDATE: string = "TEST_EFT_NO_UPDATE";
	public static EFT_EXPORT_START_TIME: string= "EFT_EXPORT_START_TIME";
	public static EFT_PREPEND_NUMBER: string= "EFT_PREPEND_NUMBER";
	public static EFT_FILE_PREPEND_NUMBER: string= "EFT_FILE_PREPEND_NUMBER";
	public static EFT_PRENOTE_DELAY: string= "EFT_PRENOTE_DELAY";
	public static EFT_ONE_BATCH_PER_FILE: string= "EFT_ONE_BATCH_PER_FILE";
	public static EFT_OMIT_CREDIT_RECORDS: string= "EFT_OMIT_CREDIT_RECORDS";
	public static EFT_PAYMENT_HTML: string= "EFT_PAYMENT_HTML";
	public static EFT_ALLOW_SHORT_PAYMENT: string= "EFT_ALLOW_SHORT_PAYMENT";
	public static SEND_EMPTY_EFT_EMAIL: string = "SEND_EMPTY_EFT_EMAIL";

	public static INTEGRATE_PLANIT: string= "INTEGRATE_PLANIT";

	public static DB_VERSION: string= "DATABASE_VERSION";
	public static DB_MINOR_VERSION: string = "DATABASE_MINOR_VERSION";
	public static APP_VERSION: string = "WEBERF_VERSION";

	public static HEADER_HTML: string = "HEADER_HTML";
	public static CUSTOM_PRELIST: string = "CUSTOM_PRELIST";
	public static ER_PROJECT_MAINT: string = "ER_PROJECT_MAINT";

	public static EXPORT_REPORT_PDF: string = "EXPORT_REPORT_PDF";
	public static PDF_AND_MARKER_PATH: string= "PDF_AND_MARKER_PATH";

	public static REPORT_SYNOPSIS: string = "REPORT_SYNOPSIS";
	public static REPORT_SYNOPSIS_ROWS: string = "REPORT_SYNOPSIS_ROWS";

	public static REQUIRE_UNION_LOCAL: string= "REQUIRE_UNION_LOCAL";
	public static USE_PRELIST: string= "USE_PRELIST";
	public static EXPORT_DELETED_EMPLOYEES: string= "EXPORT_DELETED_EMPLOYEES";
	public static EMPLOYER_CAN_VIEW_RATES: string="EMPLOYER_CAN_VIEW_RATES";
	public static EMPLOYER_CAN_MANAGE_STAFF: string="EMPLOYER_CAN_MANAGE_STAFF";

	public static PRELIST_OVERRIDE: string= "ALLOW_PRELIST_OVERRIDE";
	public static PRIOR_REPORT: string  = "ALLOW_PRIOR_REPORT";

    public static YES: string = "YES";

	public static ENHANCED_REPORT_OPTION: string= "ENHANCED_REPORT_OPTION";
	public static JMS_DESTINATION: string = "JMS_DESTINATION";
	public static NOTE_TYPES: string= "NOTE_TYPES";
	public static POA_PAY_TO: string = "POA_PAY_TO";
	public static POA_INSTRUCTION: string = "POA_INSTRUCTION";
	public static POA_MAIL_TO: string = "POA_MAIL_TO";
	public static POA_EXPORT_START_TIME: string  = "POA_EXPORT_START_TIME";
	public static UNIONBANK_ECHECK_URL: string = "UNIONBANK_ECHECK_URL";
	public static UNIONBANK_RETURN_URL: string= "UNIONBANK_RETURN_URL";
	public static ER_MAINPAGE_HTML: string = "ER_MAINPAGE_HTML";

	public static EFT_CUTOFF_MONTHS: string= "EFT_CUTOFF_MONTHS";
	public static EFT_CUTOFF_DAY: string= "EFT_CUTOFF_DAY";

	public static MAX_REPORT_YEARS_IN_PAST: string= "MAX_REPORT_YEARS_IN_PAST";

	public static MUST_ACCEPT_LEGALESE: string = "MUST_ACCEPT_LEGALESE";
	public static MUST_REPORT_OOP: string= "MUST_REPORT_OOP";
	
	public static EXPORT_CASH_BATCH: string= "EXPORT_CASH_BATCH";
	public static PAYMENT_COUPON_B4_FINAL: string   = "PAYMENT_COUPON_B4_FINAL";
	public static UNFINALIZE_ADMIN_ENABLE: string = "UNFINALIZE_ADMIN_ENABLE";
	public static SSN_NAME_VALIDATION: string = "SSN_NAME_VALIDATION";

	public static DEMOGRAPHIC_Q_ENABLE: string = "DEMOGRAPHIC_Q_ENABLE";
	public static DEMO_Q_HIRE_DATE_ENABLE: string = "DEMO_Q_HIRE_DATE_ENABLE";
	public static MANAGE_STAFF_ENABLE: string = "MANAGE_STAFF_ENABLE";
	public static REPORT_VERIFY_WORK_PERIOD: string = "REPORT_VERIFY_WORK_PERIOD";

	public static SHOW_CENSUS_IMPORT: string = "SHOW_CENSUS_IMPORT";
	public static SHOW_WEBMSTR_TERMS_OF_USE: string = "SHOW_WEBMSTR_TERMS_OF_USE";
}
