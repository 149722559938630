export class DemographicQueue {
    public addressLine1: string | null;
    public addressLine2: string | null;
    public addressLine3: string | null;
    public adminServiceEnvGUID: number | null;
    public cellAreaCode: string | null | number;
    public cellCountryCode: string | null;
    public cellExtension: string | null;
    public cellForeignFlag: boolean | null;
    public cellLocalNumber: string | null;
    public city: string | null;
    public country: string | null;
    public dateOfBirthStr: string | null;
    public ediReferenceNo: string | null;
    public emailAddress: string | null;
    public employementStatusGUID: string | null;
    public employementStatusDate: string | Date | null;
    public employer: any | null;
    public employerGUID: number | null;
    public firstName: string | null;
    public gender: string | null;
    public guid: number | null;
    public hireDate: string | Date | null;
    public homeAreaCode: string | null | number;
    public homeCountryCode: string | null;
    public homeExtension: string | null;
    public homeForeignFlag: boolean | null;
    public homeLocalNumber: string | null;
    public lastName: string | null;
    public mid: string | null;
    public middleName: string | null;
    public nameSuffix: string | null;
    public obsolete: boolean | null;
    public person: any | null;
    public personGUID: number | null;
    public preferredCommMethodCode: string | null;
    public ssn: string | any | null;
    public state: string | null;
    public status: number | null;
    public termDate: string | null;
    public updateRequired: boolean | null;
    public zipPostalCode: string | null;
    public locationGUID: string | null;
    public hoursClassGUID: number | string | null;

	constructor($addressLine1: string | null, $addressLine2: string | null, $addressLine3: string | null, $adminServiceEnvGUID: number | null, $cellAreaCode: string | null | number, $cellCountryCode: string | null, $cellExtension: string | null, $cellForeignFlag: boolean | null, $cellLocalNumber: string | null, $city: string | null, $country: string | null, $dateOfBirthStr: string | null, $ediReferenceNo: string | null, $emailAddress: string | null, $employementStatusGUID: string | null, $employer: any | null, $employerGUID: number | null, $firstName: string | null, $gender: string | null, $guid: number | null, $hireDate: string | Date | null, $homeAreaCode: string | null | number, $homeCountryCode: string | null, $homeExtension: string | null, $homeForeignFlag: boolean | null, $homeLocalNumber: string | null, $lastName: string | null, $mid: string | null, $middleName: string | null, $nameSuffix: string | null, $obsolete: boolean | null, $person: any | null, $personGUID: number | null, $preferredCommMethodCode: string | null, $ssn: string | any | null, $state: string | null, $status: number | null, $termDate: string | null, $updateRequired: boolean | null, $zipPostalCode: string | null,
        $employementStatusDate: string | Date | null, $locationGUID: string | null, $hoursClassGUID: number | string | null) {
		this.addressLine1 = $addressLine1;
		this.addressLine2 = $addressLine2;
		this.addressLine3 = $addressLine3;
		this.adminServiceEnvGUID = $adminServiceEnvGUID;
		this.cellAreaCode = $cellAreaCode;
		this.cellCountryCode = $cellCountryCode;
		this.cellExtension = $cellExtension;
		this.cellForeignFlag = $cellForeignFlag;
		this.cellLocalNumber = $cellLocalNumber;
		this.city = $city;
		this.country = $country;
		this.dateOfBirthStr = $dateOfBirthStr;
		this.ediReferenceNo = $ediReferenceNo;
		this.emailAddress = $emailAddress;
		this.employementStatusGUID = $employementStatusGUID;
        this.employementStatusDate = $employementStatusDate;
		this.employer = $employer;
		this.employerGUID = $employerGUID;
		this.firstName = $firstName;
		this.gender = $gender;
		this.guid = $guid;
		this.hireDate = $hireDate;
		this.homeAreaCode = $homeAreaCode;
		this.homeCountryCode = $homeCountryCode;
		this.homeExtension = $homeExtension;
		this.homeForeignFlag = $homeForeignFlag;
		this.homeLocalNumber = $homeLocalNumber;
		this.lastName = $lastName;
		this.mid = $mid;
		this.middleName = $middleName;
		this.nameSuffix = $nameSuffix;
		this.obsolete = $obsolete;
		this.person = $person;
		this.personGUID = $personGUID;
		this.preferredCommMethodCode = $preferredCommMethodCode;
		this.ssn = $ssn;
		this.state = $state;
		this.status = $status;
		this.termDate = $termDate;
		this.updateRequired = $updateRequired;
		this.zipPostalCode = $zipPostalCode;
        this.locationGUID = $locationGUID;
        this.hoursClassGUID = $hoursClassGUID;
	}


    /**
     * Getter $addressLine1
     * @return {string }
     */
	public get $addressLine1(): string | null {
		return this.addressLine1;
	}

    /**
     * Getter $addressLine2
     * @return {string }
     */
	public get $addressLine2(): string | null  {
		return this.addressLine2;
	}

    /**
     * Getter $addressLine3
     * @return {string }
     */
	public get $addressLine3(): string | null  {
		return this.addressLine3;
	}

    /**
     * Getter $adminServiceEnvGUID
     * @return {number }
     */
	public get $adminServiceEnvGUID(): number | null  {
		return this.adminServiceEnvGUID;
	}

    /**
     * Getter $cellAreaCode
     * @return {string }
     */
	public get $cellAreaCode(): string | null | number  {
		return this.cellAreaCode;
	}

    /**
     * Getter $cellCountryCode
     * @return {string }
     */
	public get $cellCountryCode(): string | null  {
		return this.cellCountryCode;
	}

    /**
     * Getter $cellExtension
     * @return {string }
     */
	public get $cellExtension(): string | null  {
		return this.cellExtension;
	}

    /**
     * Getter $cellForeignFlag
     * @return {boolean }
     */
	public get $cellForeignFlag(): boolean | null  {
		return this.cellForeignFlag;
	}

    /**
     * Getter $cellLocalNumber
     * @return {string }
     */
	public get $cellLocalNumber(): string | null  {
		return this.cellLocalNumber;
	}

    /**
     * Getter $city
     * @return {string }
     */
	public get $city(): string | null  {
		return this.city;
	}

    /**
     * Getter $country
     * @return {string }
     */
	public get $country(): string | null  {
		return this.country;
	}

    /**
     * Getter $dateOfBirthStr
     * @return {string }
     */
	public get $dateOfBirthStr(): string | null  {
		return this.dateOfBirthStr;
	}

    /**
     * Getter $ediReferenceNo
     * @return {string }
     */
	public get $ediReferenceNo(): string | null  {
		return this.ediReferenceNo;
	}

    /**
     * Getter $emailAddress
     * @return {string }
     */
	public get $emailAddress(): string | null  {
		return this.emailAddress;
	}

    /**
     * Getter $employementStatusGUID
     * @return {string }
     */
	public get $employementStatusGUID(): string | null  {
		return this.employementStatusGUID;
	}
    /**
     * Getter $hoursClassGUID
     * @return {number }
     */
	public get $hoursClassGUID(): number | string | null  {
		return this.hoursClassGUID;
	}
    /**
     * Getter $locationGUID
     * @return {string }
     */
	public get $locationGUID(): string | null  {
		return this.locationGUID;
	}

    /**
     * Getter $employementStatusDate
     * @return {string }
     */
	public get $employementStatusDate(): string | Date | null  {
		return this.employementStatusDate;
	}

    /**
     * Getter $employer
     * @return {any }
     */
	public get $employer(): any | null  {
		return this.employer;
	}

    /**
     * Getter $employerGUID
     * @return {number }
     */
	public get $employerGUID(): number | null  {
		return this.employerGUID;
	}

    /**
     * Getter $firstName
     * @return {string }
     */
	public get $firstName(): string | null  {
		return this.firstName;
	}

    /**
     * Getter $gender
     * @return {string }
     */
	public get $gender(): string | null  {
		return this.gender;
	}

    /**
     * Getter $guid
     * @return {number }
     */
	public get $guid(): number | null  {
		return this.guid;
	}

    /**
     * Getter $hireDate
     * @return {string }
     */
	public get $hireDate(): string | Date | null  {
		return this.hireDate;
	}

    /**
     * Getter $homeAreaCode
     * @return {string }
     */
	public get $homeAreaCode(): string | null | number  {
		return this.homeAreaCode;
	}

    /**
     * Getter $homeCountryCode
     * @return {string }
     */
	public get $homeCountryCode(): string | null  {
		return this.homeCountryCode;
	}

    /**
     * Getter $homeExtension
     * @return {string }
     */
	public get $homeExtension(): string | null  {
		return this.homeExtension;
	}

    /**
     * Getter $homeForeignFlag
     * @return {boolean }
     */
	public get $homeForeignFlag(): boolean | null  {
		return this.homeForeignFlag;
	}

    /**
     * Getter $homeLocalNumber
     * @return {string }
     */
	public get $homeLocalNumber(): string | null  {
		return this.homeLocalNumber;
	}

    /**
     * Getter $lastName
     * @return {string }
     */
	public get $lastName(): string | null  {
		return this.lastName;
	}

    /**
     * Getter $mid
     * @return {string }
     */
	public get $mid(): string | null  {
		return this.mid;
	}

    /**
     * Getter $middleName
     * @return {string }
     */
	public get $middleName(): string | null  {
		return this.middleName;
	}

    /**
     * Getter $nameSuffix
     * @return {string }
     */
	public get $nameSuffix(): string | null  {
		return this.nameSuffix;
	}

    /**
     * Getter $obsolete
     * @return {boolean }
     */
	public get $obsolete(): boolean | null  {
		return this.obsolete;
	}

    /**
     * Getter $person
     * @return {any }
     */
	public get $person(): any | null  {
		return this.person;
	}

    /**
     * Getter $personGUID
     * @return {number }
     */
	public get $personGUID(): number | null  {
		return this.personGUID;
	}

    /**
     * Getter $preferredCommMethodCode
     * @return {string }
     */
	public get $preferredCommMethodCode(): string | null  {
		return this.preferredCommMethodCode;
	}

    /**
     * Getter $ssn
     * @return {string }
     */
	public get $ssn(): string | any | null  {
		return this.ssn;
	}

    /**
     * Getter $state
     * @return {string }
     */
	public get $state(): string | null  {
		return this.state;
	}

    /**
     * Getter $status
     * @return {number }
     */
	public get $status(): number | null  {
		return this.status;
	}

    /**
     * Getter $termDate
     * @return {string }
     */
	public get $termDate(): string | null  {
		return this.termDate;
	}

    /**
     * Getter $updateRequired
     * @return {boolean }
     */
	public get $updateRequired(): boolean | null  {
		return this.updateRequired;
	}

    /**
     * Getter $zipPostalCode
     * @return {string }
     */
	public get $zipPostalCode(): string | null  {
		return this.zipPostalCode;
	}

    /**
     * Setter $addressLine1
     * @param {string } value
     */
	public set $addressLine1(value: string | null) {
		this.addressLine1 = value;
	}

    /**
     * Setter $addressLine2
     * @param {string } value
     */
	public set $addressLine2(value: string | null ) {
		this.addressLine2 = value;
	}

    /**
     * Setter $addressLine3
     * @param {string } value
     */
	public set $addressLine3(value: string | null ) {
		this.addressLine3 = value;
	}

    /**
     * Setter $adminServiceEnvGUID
     * @param {number } value
     */
	public set $adminServiceEnvGUID(value: number | null ) {
		this.adminServiceEnvGUID = value;
	}

    /**
     * Setter $cellAreaCode
     * @param {string } value
     */
	public set $cellAreaCode(value: string | null | number ) {
		this.cellAreaCode = value;
	}

    /**
     * Setter $cellCountryCode
     * @param {string } value
     */
	public set $cellCountryCode(value: string | null ) {
		this.cellCountryCode = value;
	}

    /**
     * Setter $cellExtension
     * @param {string } value
     */
	public set $cellExtension(value: string | null ) {
		this.cellExtension = value;
	}

    /**
     * Setter $cellForeignFlag
     * @param {boolean } value
     */
	public set $cellForeignFlag(value: boolean | null ) {
		this.cellForeignFlag = value;
	}

    /**
     * Setter $cellLocalNumber
     * @param {string } value
     */
	public set $cellLocalNumber(value: string | null ) {
		this.cellLocalNumber = value;
	}

    /**
     * Setter $city
     * @param {string } value
     */
	public set $city(value: string | null ) {
		this.city = value;
	}

    /**
     * Setter $country
     * @param {string } value
     */
	public set $country(value: string | null ) {
		this.country = value;
	}

    /**
     * Setter $dateOfBirthStr
     * @param {string } value
     */
	public set $dateOfBirthStr(value: string | null ) {
		this.dateOfBirthStr = value;
	}

    /**
     * Setter $ediReferenceNo
     * @param {string } value
     */
	public set $ediReferenceNo(value: string | null ) {
		this.ediReferenceNo = value;
	}

    /**
     * Setter $emailAddress
     * @param {string } value
     */
	public set $emailAddress(value: string | null ) {
		this.emailAddress = value;
	}

    /**
     * Setter $employementStatusGUID
     * @param {string } value
     */
	public set $employementStatusGUID(value: string | null ) {
		this.employementStatusGUID = value;
	}
    /**
     * Setter $locationGUID
     * @param {string } value
     */
	public set $locationGUID(value: string | null ) {
		this.locationGUID = value;
	}
    /**
     * Setter $hoursClassGUID
     * @param {number } value
     */
	public set $hoursClassGUID(value: number | string | null ) {
		this.hoursClassGUID = value;
	}

    /**
     * Setter $employementStatusDate
     * @param {string } value
     */
	public set $employementStatusDate(value: string | Date | null ) {
		this.employementStatusDate = value;
	}


    /**
     * Setter $employer
     * @param {any } value
     */
	public set $employer(value: any | null ) {
		this.employer = value;
	}

    /**
     * Setter $employerGUID
     * @param {number } value
     */
	public set $employerGUID(value: number | null ) {
		this.employerGUID = value;
	}

    /**
     * Setter $firstName
     * @param {string } value
     */
	public set $firstName(value: string | null ) {
		this.firstName = value;
	}

    /**
     * Setter $gender
     * @param {string } value
     */
	public set $gender(value: string | null ) {
		this.gender = value;
	}

    /**
     * Setter $guid
     * @param {number } value
     */
	public set $guid(value: number | null ) {
		this.guid = value;
	}

    /**
     * Setter $hireDate
     * @param {string } value
     */
	public set $hireDate(value: string | Date | null ) {
		this.hireDate = value;
	}

    /**
     * Setter $homeAreaCode
     * @param {string } value
     */
	public set $homeAreaCode(value: string  | null | number) {
		this.homeAreaCode = value;
	}

    /**
     * Setter $homeCountryCode
     * @param {string } value
     */
	public set $homeCountryCode(value: string | null ) {
		this.homeCountryCode = value;
	}

    /**
     * Setter $homeExtension
     * @param {string } value
     */
	public set $homeExtension(value: string | null ) {
		this.homeExtension = value;
	}

    /**
     * Setter $homeForeignFlag
     * @param {boolean } value
     */
	public set $homeForeignFlag(value: boolean | null ) {
		this.homeForeignFlag = value;
	}

    /**
     * Setter $homeLocalNumber
     * @param {string } value
     */
	public set $homeLocalNumber(value: string | null ) {
		this.homeLocalNumber = value;
	}

    /**
     * Setter $lastName
     * @param {string } value
     */
	public set $lastName(value: string | null ) {
		this.lastName = value;
	}

    /**
     * Setter $mid
     * @param {string } value
     */
	public set $mid(value: string | null ) {
		this.mid = value;
	}

    /**
     * Setter $middleName
     * @param {string } value
     */
	public set $middleName(value: string | null ) {
		this.middleName = value;
	}

    /**
     * Setter $nameSuffix
     * @param {string } value
     */
	public set $nameSuffix(value: string | null ) {
		this.nameSuffix = value;
	}

    /**
     * Setter $obsolete
     * @param {boolean } value
     */
	public set $obsolete(value: boolean | null ) {
		this.obsolete = value;
	}

    /**
     * Setter $person
     * @param {any } value
     */
	public set $person(value: any | null ) {
		this.person = value;
	}

    /**
     * Setter $personGUID
     * @param {number } value
     */
	public set $personGUID(value: number | null ) {
		this.personGUID = value;
	}

    /**
     * Setter $preferredCommMethodCode
     * @param {string } value
     */
	public set $preferredCommMethodCode(value: string | null ) {
		this.preferredCommMethodCode = value;
	}

    /**
     * Setter $ssn
     * @param {string } value
     */
	public set $ssn(value: string | any | null ) {
		this.ssn = value;
	}

    /**
     * Setter $state
     * @param {string } value
     */
	public set $state(value: string | null ) {
		this.state = value;
	}

    /**
     * Setter $status
     * @param {number } value
     */
	public set $status(value: number | null ) {
		this.status = value;
	}

    /**
     * Setter $termDate
     * @param {string } value
     */
	public set $termDate(value: string | null ) {
		this.termDate = value;
	}

    /**
     * Setter $updateRequired
     * @param {boolean } value
     */
	public set $updateRequired(value: boolean | null ) {
		this.updateRequired = value;
	}

    /**
     * Setter $zipPostalCode
     * @param {string } value
     */
	public set $zipPostalCode(value: string | null ) {
		this.zipPostalCode = value;
	}


}
