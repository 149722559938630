import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private apiServerUrl = environment.apiBaseUrl + '/spring/Project';

  constructor(private http: HttpClient) { }


  getEmployerProjects(id:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('empGuid', id);
    return this.http.get<any>(`${this.apiServerUrl}/viewProjects`,{ params: Params });
	}
  retrieveProjects(projId:string, projName:string, awardingAgencyID:string, awardingAgencyName:string, awardingAgencyContract:string, generalContractor:string, address:string, city:string, zip:string): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('projId', projId);
    Params = Params.append('projName', projName);
    Params = Params.append('awardingAgencyID', awardingAgencyID);
    Params = Params.append('awardingAgencyName', awardingAgencyName);
    Params = Params.append('awardingAgencyContract', awardingAgencyContract);
    Params = Params.append('generalContractor', generalContractor);
    Params = Params.append('address', address);
    Params = Params.append('city', city);
    Params = Params.append('zip', zip);
    return this.http.get<any>(`${this.apiServerUrl}/retrieveProjects`,{ params: Params });
	}
  updateProjectReference(employerProjectGUID:any,webERFProjectGUID:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('employerProjectGUID', employerProjectGUID);
    Params = Params.append('webERFProjectGUID', webERFProjectGUID);
    return this.http.post<any>(`${this.apiServerUrl}/updateProjectReference`,'',{ params: Params });
	}
  updateWebERFEmployerProject(webERFProjectGUID:any,addrLine1:any,addrLine2:any, city:any,country:any,state:any,zip:any,
    awardingAgencyContract:any,awardingAgencyID:any,generalContractor:any,projectName:any,eff:any,exp:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('webERFProjectGUID', webERFProjectGUID);
    Params = Params.append('addrLine1', addrLine1);
    Params = Params.append('addrLine2', addrLine2);
    Params = Params.append('city', city);
    Params = Params.append('country', country);
    Params = Params.append('state', state);
    Params = Params.append('zip', zip);
    Params = Params.append('awardingAgencyContract', awardingAgencyContract);
    Params = Params.append('awardingAgencyID', awardingAgencyID);
    Params = Params.append('generalContractor', generalContractor);
    Params = Params.append('projectName', projectName);
    Params = Params.append('eff', eff);
    Params = Params.append('exp', exp);

    return this.http.post<any>(`${this.apiServerUrl}/updateWebERFEmployerProject`,'',{ params: Params });
	}
  createHalfNewProject(webERFProjectGUID:any,createProjectId:any,createContact:any,createEff:any,createExp:any,createComment:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('webERFProjectGUID', webERFProjectGUID);
    Params = Params.append('createProjectId', createProjectId);
    Params = Params.append('createContact', createContact);
    Params = Params.append('createEff', createEff);
    Params = Params.append('createExp', createExp);
    Params = Params.append('createComment', createComment);
    return this.http.post<any>(`${this.apiServerUrl}/createHalfNewProject`,'',{ params: Params });
	}
  createNewProject(createProjectId:any,createAA:any,createAAC:any,createGC:any,createContact:any,createName:any,createAddr1:any,createAddr2:any, 
    createCity:any,createState:any,createCountry:any,createZip:any,createEff:any,createExp:any,createComment:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('createProjectId', createProjectId);
    Params = Params.append('createAA', createAA);
    Params = Params.append('createAAC', createAAC);
    Params = Params.append('createGC', createGC);
    Params = Params.append('createContact', createContact);
    Params = Params.append('createName', createName);
    Params = Params.append('createAddr1', createAddr1);
    Params = Params.append('createAddr2', createAddr2);
    Params = Params.append('createCity', createCity);
    Params = Params.append('createState', createState);
    Params = Params.append('createCountry', createCountry);
    Params = Params.append('createZip', createZip);
    Params = Params.append('createEff', createEff);
    Params = Params.append('createExp', createExp);
    Params = Params.append('createComment', createComment);
    return this.http.post<any>(`${this.apiServerUrl}/createNewProject`,'',{ params: Params });
	}
}
