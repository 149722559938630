<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>

<body>
    <div>
        <h3 *ngIf="systemParameters[0] != null && systemParameters[0] != undefined">{{systemParameters[0].parameterValue1}}</h3>
	    <span class="pageHeader">Contact Us</span>

        <p>Please contact 
            <ng-container *ngIf="systemParameters[4] != null && systemParameters[4] != undefined && systemParameters[4].parameterValue1 != '' && systemParameters[4].parameterValue1 != null">
                the {{systemParameters[4].parameterValue1}} at
            </ng-container>
            <ng-container *ngIf="(systemParameters[4] == null || systemParameters[4] == undefined || systemParameters[4].parameterValue1 == '' && systemParameters[4].parameterValue1 != null) && systemParameters[3] != null && systemParameters[3] != undefined && systemParameters[3].parameterValue1 != '' && systemParameters[3].parameterValue1 != null">
                the {{systemParameters[3].parameterValue1}} at
            </ng-container>
            the Administrative Office with any questions about the 
            Web Employer Reporting Form (WebERF).
        </p>
    </div>
    
    <div>
        <strong>Mailing Address:</strong><br />
        {{systemParameters[0].parameterValue1}} <br/>
        {{systemParameters[1].parameterValue1}} <br/>
        {{systemParameters[2].parameterValue1}} <br/>
        <strong>Phone:</strong>&nbsp; 
        {{systemParameters[7].parameterValue1}} <br/>
        <ng-container *ngIf="systemParameters[8] != null && systemParameters[8] != undefined && systemParameters[8].parameterValue1 != '' && systemParameters[8].parameterValue1 != null">
            <strong>Phone 2:</strong>&nbsp; 
            {{systemParameters[8].parameterValue1}} <br/>
        </ng-container>
        <ng-container *ngIf="systemParameters[9] != null && systemParameters[9] != undefined && systemParameters[9].parameterValue1 != '' && systemParameters[9].parameterValue1 != null">
            <strong>Toll free:</strong>&nbsp; 
            {{systemParameters[9].parameterValue1}} <br/>
        </ng-container>
        <strong>Fax:</strong>&nbsp; 
        {{systemParameters[6].parameterValue1}} <br/>
        <strong>Email:</strong>&nbsp; 
        {{systemParameters[5].parameterValue1}} <br/>
        <strong>Attn:</strong>&nbsp; 
        {{systemParameters[3].parameterValue1}} <br/>
        <strong>Plan Administrator:</strong>&nbsp; 
        {{systemParameters[11].parameterValue1}} <br/>
    </div>
    <p>{{systemParameters[10].parameterValue1}}</p>
</body>