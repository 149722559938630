import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProcessesService {

  private baseUrl = environment.apiBaseUrl + '/spring';

  constructor(private http: HttpClient) { }

  generate(str:any): Observable<any>{
    return this.http.post(`${this.baseUrl}/WebmasterProcess/generate`,str);
  }
  databaseSync(str:any):  Observable<any>{
    return this.http.post(`${this.baseUrl}/WebmasterProcess/databaseSync`,str);
  }

  getHolidays():  Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/WebmasterProcess/getHolidays`);
  }
  addHoliday(data:any):  Observable<any>{
    return this.http.post(`${this.baseUrl}/WebmasterProcess/addHoliday`,data);
  }
  deleteHoliday(data:any):  Observable<any>{
    return this.http.post(`${this.baseUrl}/WebmasterProcess/deleteHoliday`,data);
  }

  getAllTermsOfUse(): Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/WebmasterProcess/termsOfUse`);
  }
  saveTermsOfUse(data:any): Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/WebmasterProcess/saveTermsOfUse`,data);
	}
  getActiveTermsOfUse(): Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/WebmasterProcess/activeTermsOfUse`);
  }
}
