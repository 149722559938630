import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DemographicQueue } from 'src/app/model/demographicQueue/demographic-queue';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DemographicsService {

  private baseUrl = environment.apiBaseUrl + '/spring';

  constructor(private http: HttpClient) { }

  getEmployerEmployees(id:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('guid', id);
    return this.http.get<any>(`${this.baseUrl}/Demo/EmployerEmployees`,{ params: Params });
	}
  addEmployee(demo: DemographicQueue): Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/Demo/add`,demo);
	}
  getEmploymentHistoryDetails(data:any){
    return this.http.post<any>(`${this.baseUrl}/Demo/getEmploymentHistoryDetails`,data);
  }
  getEmploymentHistoryDetailsInChunks(data:any){
    return this.http.post<any>(`${this.baseUrl}/Demo/getEmploymentHistoryDetailsInChunks`,data);
  }
}
