<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>
<body *ngIf="eftAccountDetailsForm.employer">
    <h3>
        <ng-container *ngIf="isEmployerOrStaff()">
            {{eftAccountDetailsForm.employer.organizationName}}
        </ng-container>
    </h3>
    
    <span class="pageHeader">Setup/Edit Employer EFT Account Details</span>

    <p>Enter the bank routing and account number from which funds will be obtained and then click the Save button. &nbsp;Use extra care to enter the numbers correctly. &nbsp;Fields marked with an asterisk (&#8220;<span class="required">*</span>&#8221;) are required.</p>
    <p>To de&#45;activate a bank routing and account number, click the 'Reset Saved Data' button.</p>
    <p>To view a sample check with the location of the bank routing number and account numbers identified, 
        <!-- <a href="javascript:PopupPic('custom/images/checksample.gif')" title="Click to view a sample check with location of bank routing and account numbers">click here</a> -->
        <button mat-button class="btnLinkImg" (click)="openPdf(imgModal)" title="Click to view a sample check with location of bank routing and account numbers">click here</button>
        .</p>

    <div class="tbldiv">
        <table class="tbl">
            <tr>
                <td style="padding-top: 10px;" class="right nowrap"><label for="oldPW">Bank Routing Number<span class="required">*</span>:</label></td>
                <td class="nowrap">
                    <input type="text" size="14" maxlength="9" title="Bank routing number associated with the bank account" [disabled]="!isEdit" [(ngModel)]="eftAccountDetailsForm.routingNumber" />&nbsp;&nbsp;
                </td>
            </tr>
            <tr>
                <td class="right nowrap"><label for="newPW">Bank Account Number<span class="required">*</span>:</label></td>
                <td class="nowrap">
                    <input type="text" size="14" maxlength="18" title="Bank account number" [disabled]="!isEdit" [(ngModel)]="eftAccountDetailsForm.accountNumber"/>&nbsp;&nbsp;
                </td>
            </tr>
            <tr>
                <td class="right nowrap"><label for="confirmPW">Bank Name:</label></td>
                <td class="nowrap">
                    <!-- <input type="password" property="confirmPassword" size="14" maxlength="12"title="Enter new Password again to confirm" tabindex="6" [(ngModel)]="bankName"/>&nbsp;&nbsp; -->
                    <label>{{eftAccountDetailsForm.bankName}}</label>
                </td>
            </tr>

            <tr>
                <td colspan="4" class="center" style="text-align: center;padding-bottom: 10px;">
                    <button color="accent" mat-raised-button (click)="edit()" style="margin-right: 10px;" [disabled]="isEdit">Edit</button>
                    <button color="primary" mat-raised-button (click)="validation()" [disabled]="!isEdit">Save</button>

                    <br/><br/>
                    <button color="warn" mat-raised-button (click)="remove()">Reset Saved Data</button>
                </td>
            </tr>
        </table>
    </div>


</body>

<!-- Image Modal -->
<ng-template #imgModal let-modal class="modal-dialog modal-fullscreen">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Sample Check</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="/assets/custom/images/checksample.gif" alt="image" class="imgClient" title="">
    </div>
</ng-template>
<!-- Alert Modal -->
<ng-template #alertModal let-modal>
    <div class="modal-header" style="justify-content: center;">
        <h4 class="modal-title" id="modal-basic-title">Notification</h4>
    </div>
    <div class="modal-body" style="align-self: center;">
        A one-time pre-notification process will be run to validate this bank routing and account number to ensure that an EFT will take place without errors. The Administrative Office will only notify you if the pre-notification is unsuccessful. You may begin submitting payments via EFT on {{eftAccountDetailsForm.erBankAccountValidFromDate}}.
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click');save()">Ok</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click');">Cancel</button>
    </div>
</ng-template>