import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private baseUrl = environment.apiBaseUrl + '/spring';

  constructor(private http: HttpClient) { }

  upload(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post(`${this.baseUrl}/Images/upload/file`,formData,{reportProgress: true,responseType: 'json'});
  }

  retrieve(type: any): Observable<any>{
    return this.http.post(`${this.baseUrl}/Images/retrieve/file`,type,{ responseType: 'blob'});
  }
  show(img: any): Observable<any>{
    return this.http.post(`${this.baseUrl}/Images/show/file`,img,{ responseType: 'blob'});
  }
}
