import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Employer } from 'src/app/model/userManagement/employer';
import { EmployerService } from 'src/app/services/employer/employer.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-search-employers',
  templateUrl: './search-employers.component.html',
  styleUrls: ['./search-employers.component.scss']
})
export class SearchEmployersComponent implements OnInit {
  destination:string = '';
  searchValueId: any;
  searchValueName: any;
  selectedValue: any= '';
  listItem:any;
  employerList: Employer[] = [];
  employerSearchList: Employer[] = [];
  displayedColumns: string[] = ['er_ID', 'er_NAME'];

  selectedEmployer: any = null;
  employerAddress: any;
  employerPhone: any;

  modalOptions: NgbModalOptions;
  closeResult: string = '';
  @ViewChild('editEmployerModal') editEmployerModal : any;
  dataSource = new MatTableDataSource<Employer>([]);
  @ViewChild(MatPaginator)
  private paginator!: MatPaginator;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 30, 40, 50];
  // MatPaginator Output
  pageEvent!: PageEvent;

  loading$ = this.loader.loading$;

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.employerList);
    this.dataSource.paginator = this.paginator;
  }
  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  constructor(public loader: SpinnerService,private employerService : EmployerService,private modalService: NgbModal,
    private route: ActivatedRoute,private router: Router) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      size:'xl',
    };
    //This will allow the parameters to reload
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit(): void {
    this.getPageParams();
    this.getAllEmployers();
  }

  public getAllEmployers(): void {
    this.employerService.getAllEmployers().subscribe(
      (response: Employer[]) => {
        this.employerList = response;
        this.employerSearchList = response;
        this.dataSource.data = response;
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      }
    );
  }
  SearchId() {
    this.employerList = this.employerSearchList;
    this.dataSource.data = this.employerSearchList;
    if(this.searchValueName != ''){
      this.searchValueName = '';
    }
    if (this.searchValueId != '') {
      this.dataSource.data = this.dataSource.data.filter((res) => {
        return res.organizationID!.toLowerCase().match(this.searchValueId.toLowerCase());
      });
    }
  }
  SearchName() {
    this.employerList = this.employerSearchList;
    this.dataSource.data = this.employerSearchList;
    if(this.searchValueId != ''){
      this.searchValueId = '';
    }
    if (this.searchValueName != '') {
      this.dataSource.data = this.dataSource.data.filter((res) => {
        return res.organizationName!.toLowerCase().match(this.searchValueName.toLowerCase());
      });
    }
  }

  goToEmployerAgreement(event: any){
    //reset data
    this.selectedEmployer = null;
    this.employerAddress = null;
    this.employerPhone = null;
    console.log(event);
    // console.log(this.destination);
    if(this.destination.match("editRegisteredEmployer")){
      this.selectedEmployer = event;
      this.setEmployerAddress(this.selectedEmployer);
      //popup to display details to be edited
      this.open(this.editEmployerModal);
    } else {
      this.router.navigate([
        '/'+this.destination,
        { employerId: btoa(event.guid) },
      ]);
    }
  }
  getPageParams():void{
    this.destination = atob(this.route.snapshot.paramMap.get('destination')!);
  }
  open(content: any) {
    console.log('open');
    this.modalService.open(content, this.modalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  setEmployerAddress(data:any):void{
    for(let i = 0; i < data.locations.length;i++){
      if (data.locations[i].obsolete){
        continue;
      }
      if(data.locations[i].locationType == 'PRIMARY'){
        for(let j = 0; j < data.locations[i].addressHistory.length; j++){
          var effDate = data.locations[i].addressHistory[j].effDate;
          var expDate = data.locations[i].addressHistory[j].expDate;
          if(data.locations[i].addressHistory[j].obsolete){
            continue;
          }
          if(expDate == null){
            if(new Date(effDate!) <= new Date()){
              this.employerAddress = data.locations[i].addressHistory[j].mailingAddress;
              this.employerPhone = data.locations[i];
            }
          } else {
            if(new Date(effDate!) < new Date() && new Date(expDate) > new Date()){
              this.employerAddress = data.locations[i].addressHistory[j].mailingAddress;
              this.employerPhone = data.locations[i];
            }
          }
        }
      }
    }
  }
  prenoteStatus(){
    if(this.selectedEmployer.employerInfo.bankStatus == 1 || this.selectedEmployer.employerInfo.bankStatus == '1'){
      return 1;
    }else if(this.selectedEmployer.employerInfo.bankStatus == 2 || this.selectedEmployer.employerInfo.bankStatus == '2'){
      return 2;
    }else if(this.selectedEmployer.employerInfo.bankStatus == 3 || this.selectedEmployer.employerInfo.bankStatus == '3'){
      return 3;
    }else if(this.selectedEmployer.employerInfo.bankStatus == 4 || this.selectedEmployer.employerInfo.bankStatus == '4'){
      return 4;
    }else {
      return 5;
    }
  }
}
