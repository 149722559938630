import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Employer } from 'src/app/model/userManagement/employer';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ImportService } from 'src/app/services/import/import.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-census-import',
  templateUrl: './census-import.component.html',
  styleUrls: ['./census-import.component.scss']
})
export class CensusImportComponent implements OnInit {

  modalOptions: NgbModalOptions;
  closeResult: string = '';
  @ViewChild('importModal') importModal : any;
  @ViewChild('importSelectFile') importSelectFile : any;
  @ViewChild('employerClassMappingModal') employerClassMappingModal : any;
  @ViewChild('submittedListFile') submittedListFile : any;

  @ViewChild(MatPaginator, {static: false}) paginator!: MatPaginator;
  pageEvent!: PageEvent;

  employerId:any = '';
  private isRoleEmployer: any = 'EmployerUserVO';
  private isRoleEmployerStaff: any = 'EmployerStaffUserVO';
  private isPayRollCompany: any = "PayrollCompanyUserVO";
  role: any = '';
  employer!: Employer;
  employerList!: any;
  displayedColumns: string[] = ['ID', 'Location'];
  submittedListDisplayedColumns: string[] = ['ID','Submitted Date','File Name', 'Total Rows', 'Total Added To Queue'];
  selectedLocation:any;
  //For Import TODO: condense into a model
  selectedFiles?: FileList;
  currentFile?: File;
  headerRow: any = 1;

  saveDisabled = true;

  previewData:any;
  censusFields:any[]=[];
  censusColumnMapping:any[]=[];
  fileHeaders:any[]=[];
  fileRowPreview:any=[];

  hoursNotMapped:any[] = [];
  employmentReasonNotMapped:any[] = [];
  hoursToBeMapped:any[] = [];
  employmentReasonToBeMapped:any[] = [];
  submittedList = new MatTableDataSource<any>([]);

  loading$ = this.loader.loading$;
  constructor(public loader: SpinnerService, public authService: AuthService,private modalService: NgbModal,
    private importService: ImportService,private route: ActivatedRoute, private router: Router,private cdr: ChangeDetectorRef) { 
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      size: 'xl',
    };
  }
  ngAfterViewInit() {
    this.submittedList = new MatTableDataSource<any>([]);
    this.submittedList.paginator = this.paginator;
  }

  loadData() {
    // Get User Role
    this.role = this.authService.getSignedinUserRole();
    //Get Employer Details 
    this.loadEmployerInfo();
  }

  ngOnInit(): void {
    this.getPageParams();
    this.loadData();
  }
  getPageParams():void{
    this.employerId = atob(this.route.snapshot.paramMap.get('employerId')!);
  }

  loadEmployerInfo(){
    this.importService.getEmployerCensusData(this.employerId).subscribe(
      (response: any) => {
        console.log(response);
        this.employer = response.result.employer;
        this.employerList = response.result.employers;
        this.authService.refreshToken().subscribe((result)=> {}, () => {});
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      }
    );
  }

  open(content: any) {
    console.log('open');
    this.modalService.open(content, this.modalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  isEmployerOrStaff() {
    if (
      this.role == this.isRoleEmployer ||
      this.role == this.isRoleEmployerStaff
    ) {
      return true;
    } else {
      return false;
    }
  }
  isPayRoll(){
    if(this.role == this.isPayRollCompany){
      return true;
    } else {
      return false;
    }
  }
  startImportProcess(row:any){
    console.log(row);
    this.selectedLocation = row;
    this.open(this.importSelectFile);
  }
  checkImportFile(){
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      if(file){
        this.currentFile = file;
        //send file to get headers and sort order if exists
        this.importService
          .getCensusImportData(this.currentFile,this.selectedLocation.organizationID,this.selectedLocation,this.headerRow)
          .subscribe(
            (response: any) => {
              console.log(response);
              this.previewData = response.result;
              this.censusFields = response.result.censusFields;
              this.fileHeaders = response.result.fileHeaders;
              this.fileRowPreview = response.result.fileRowPreview;
              this.censusColumnMapping = response.result.censusColumnMapping;
              this.authService.refreshToken().subscribe((result)=> {}, () => {});
            },
            (error: HttpErrorResponse) => {
              alert(error.error.reason);
            }
          );
        ///////
        this.modalService.dismissAll();
        this.open(this.importModal);
      }else{
        alert('Select a file to continue.');
      }
    }else{
      alert('Select a file to continue.');
    }
  }
  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  runImport(){
    //Check for required field
    for(let i = 0; i < this.censusFields.length;i++){
      if(this.censusFields[i].required && (this.censusColumnMapping[i].columnOrder == null || this.censusColumnMapping[i].columnOrder == undefined || this.censusColumnMapping[i].columnOrder == '')){
        alert(this.censusFields[i].fieldName + ' is a required, add mapping to continue.');
        return;
      }
    }
    console.log("censusColumnMapping");
    console.log(this.censusColumnMapping);
    this.importService
    .checkCensusClassMapping(this.currentFile!,this.selectedLocation,this.headerRow)
    .subscribe(
      (response: any) => {
        console.log(response);
        this.hoursNotMapped = response.result.hoursNotMapped;
        this.hoursToBeMapped = response.result.hoursToBeMapped;
        this.employmentReasonNotMapped = response.result.employmentReasonNotMapped;
        this.employmentReasonToBeMapped = response.result.employmentReasonToBeMapped;

        if(this.hoursNotMapped.length > 0 || this.employmentReasonNotMapped.length > 0){
          this.modalService.dismissAll();
          this.open(this.employerClassMappingModal);
        }else{//All Good continue with import
          // Run the following mapping
          this.importService
            .runCensusImportData(this.currentFile!,this.selectedLocation.organizationID,this.selectedLocation,this.headerRow)
            .subscribe(
              (response: any) => {
                console.log(response);
                alert(response.result.message);
                this.modalService.dismissAll();
                this.authService.refreshToken().subscribe((result)=> {}, () => {});
              },
              (error: HttpErrorResponse) => {
                alert(error.error.reason);
              }
            );
        }
      },
      (error: HttpErrorResponse) => {
        // console.log(error.error.message);
        // alert(error.error.message);
        alert(error.error.reason);
      }
    );
  }
  editMapping(){
    this.saveDisabled = false;
  }
  saveMapping(){
    this.importService
      .saveCensusMapping(this.censusColumnMapping)
      .subscribe(
        (response: any) => {
          console.log(response);
          this.censusColumnMapping = response.result.censusColumnMapping;
          this.saveDisabled = !this.saveDisabled;
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
  }
  formatExcelDate(num:any){
    if(Number(num)){
      var date = new Date(Math.round((num - 25569) * 86400 * 1000));
      date.setDate(date.getDate() + 1);
      return date;
    }else{
      return num;
    }
  }
  hoursClassMapping(event:any,item:any){
    const value = event.target.value;
    const parameterGroupGUID = this.hoursToBeMapped.filter((obj) => {
      return obj.parameterCode === value;
    });
    item.idFinder = (parameterGroupGUID[0] == undefined) ? null : parameterGroupGUID[0].guid;
    item.parameterGroupGUID = (parameterGroupGUID[0] == undefined) ? null : parameterGroupGUID[0].parameterGroupGUID;
  }
  employmentReasonClassMapping(event:any,item:any){
    const value = event.target.value;
    const parameterGroupGUID = this.employmentReasonToBeMapped.filter((obj) => {
      return obj.parameterCode === value;
    });
    item.idFinder = (parameterGroupGUID[0] == undefined) ? null : parameterGroupGUID[0].guid;
    item.parameterGroupGUID = (parameterGroupGUID[0] == undefined) ? null : parameterGroupGUID[0].parameterGroupGUID;
  }
  saveClassMapping(){
    for(let i = 0; i < this.hoursNotMapped.length; i++){
      if(this.hoursNotMapped[i].idFinder == null){
        alert("All mappings must have an assigned value before import can continue.");
        return;
      }
    }
    for(let i = 0; i < this.employmentReasonNotMapped.length; i++){
      if(this.employmentReasonNotMapped[i].idFinder == null){
        alert("All mappings must have an assigned value before import can continue.");
        return;
      }
    }
    // CONTINUE TO SAVE MAPPING THEN DO IMPORT
    this.importService
    .saveClassMapping(this.hoursNotMapped,this.employmentReasonNotMapped,[])
    .subscribe(
      (response: any) => {
        console.log(response);
        alert(response.result.message);
        this.authService.refreshToken().subscribe((result)=> {}, () => {});
        this.modalService.dismissAll();
        this.open(this.importModal);
      },
      (error: HttpErrorResponse) => {
        // console.log(error.error.message);
        // alert(error.error.message);
        alert(error.error.reason);
      }
    );
  }
  retrieveSubmittedList(){
    this.importService
      .retrieveEmployerCensusMapping(this.employer.guid, this.authService.getPGUID() != null && this.authService.getPGUID() != 'null' ? this.authService.getPGUID() : 0)
      .subscribe(
        (response: any) => {
          this.submittedList = new MatTableDataSource<any>(response.result);
          this.authService.refreshToken().subscribe((result)=> {}, () => {});
          this.open(this.submittedListFile);
          setTimeout(() => this.submittedList.paginator = this.paginator);
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
  }

}
