import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Announcement } from 'src/app/model/announcement/announcement';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  private baseUrl = environment.apiBaseUrl + '/spring';

  constructor(private http: HttpClient) { }

  getAnnouncements(): Observable<Announcement[]>{
		return this.http.get<any>(this.baseUrl + '/Announcement/employer/all',{responseType: 'json'}).pipe(map((resp) => {
			return resp;
		}));
	}

  //For Webmaster
  getAllAnnouncements(): Observable<any>{
    return this.http.get<any>(`${this.baseUrl}/Announcement/all`);
  }
  saveGlobalData(data:any): Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/Announcement/saveGlobalData`,data);
	}
  saveEmployerData(data:any): Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/Announcement/saveEmployerData`,data);
	}
  saveAgreementData(data:any): Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/Announcement/saveAgreementData`,data);
	}
  deleteGlobalData(data:any): Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/Announcement/deleteGlobalData`,data);
	}
  deleteEmployerData(data:any): Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/Announcement/deleteEmployerData`,data);
	}
  deleteAgreementData(data:any): Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/Announcement/deleteAgreementData`,data);
	}
}
