import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver'; 

@Injectable({
  providedIn: 'root'
})
export class EmployerReportService {

  private baseUrl = environment.apiBaseUrl + '/spring';
  constructor(private http: HttpClient) { }


  public deleteEmployerReport(id: number): Observable<any>{
    return this.http.delete<any>(`${this.baseUrl}/EmployerReport/delete/${id}`);
  }
  public unfinalizeEmployerReport(id: number): Observable<any>{
    return this.http.delete<any>(`${this.baseUrl}/EmployerReport/unfinalize/${id}`);
  }
  saveNewReport(er: any,guid:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('guid', guid);
    return this.http.post<any>(`${this.baseUrl}/EmployerReport/saveNewAgreement`,er,{ params: Params });
	}
  checkReportByPayPeriod(er: any,guid:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('guid', guid);
    return this.http.post<any>(`${this.baseUrl}/EmployerReport/checkReportByPayPeriod`,er,{ params: Params });
	}
  checkAllReportByPayPeriod(er: any,guid:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('guid', guid);
    return this.http.post<any>(`${this.baseUrl}/EmployerReport/checkAllReportByPayPeriod`,er,{ params: Params });
	}
  saveReport(er: any): Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/EmployerReport/save`,er);
	}
  calculateReport(er: any): Observable<any>{
    return this.http.post(`${this.baseUrl}/EmployerReport/Calculate`,er);
	}
  pdfReport(pdf: any): Observable<any>{
    return this.http.post(`${this.baseUrl}/EmployerReport/pdfCalculate`,pdf,{ responseType: 'blob'});
  }
  pdfCoupon(pdf: any): Observable<any>{
    return this.http.post(`${this.baseUrl}/EmployerReport/pdfCoupon`,pdf,{ responseType: 'blob'});
  }
  pdfMassCoupon(pdf: any): Observable<any>{
    return this.http.post(`${this.baseUrl}/EmployerReport/pdfMassCoupon`,pdf,{ responseType: 'blob'});
  }
  vebaStatementPdf(id:number): Observable<any>{
    return this.http.post(`${this.baseUrl}/EmployerReport/vebaStatementPdf`,id,{ responseType: 'blob'});
  }
  vebaStatementExcel(id:number): Observable<any>{
    return this.http.post(`${this.baseUrl}/EmployerReport/vebaStatementExcel`,id,{ responseType: 'blob'});
  }

  finalizeReport(er: any,pmt:any): Observable<any>{
    // return this.http.post(`${this.baseUrl}/EmployerReport/Finalize`,er,{ responseType: 'blob'});
    return this.http.post(`${this.baseUrl}/EmployerReport/Finalize`,{employerReport:er,erfPayment:pmt});
  }
  getListReportPdfs(er: any): Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/EmployerReport/listReportPdfs`,er);
	}
  getSingleMassImportPdf(er: any): Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/EmployerReport/getSingleMassImportPdf`,er);
	}

  deleteEmployeeFromReport(id: number): Observable<any>{
    return this.http.post<any>(`${this.baseUrl}/EmployerReport/deleteEmployeeFromReport`,id);
  }
  massCalculate(guids: any): Observable<any>{
    return this.http.post(`${this.baseUrl}/EmployerReport/massCalculate`,guids);
	}
  deleteAllFromMassImport(guids: any): Observable<any>{
    return this.http.post(`${this.baseUrl}/EmployerReport/deleteAllFromMassImport`,guids);
	}
  finalizeAllFromMassImport(er: any,pmt:any): Observable<any>{
    return this.http.post(`${this.baseUrl}/EmployerReport/FinalizeAllFromMassImport`,{listReports:er,erfPayment:pmt});
  }
}
