<body>
  <!-- Login -->
  <div class="container col-lg-6" *ngIf="!toTpVerification">
    <h1 class="text-center">Login</h1>
    <div class="card">
      <div class="card-body">
        <form class="form-group">
          <div style="color : red" *ngIf='error'>{{error}}</div>
          <div class="form-group">
            <label for="email">User Name :</label>
            <input type="text" class="form-control" id="username" [(ngModel)]="username" placeholder="Enter User Name"
              name="username">
          </div>
          <label for="pwd">Password:</label>
          <!-- <div class="form-group input-group">
            <input type="password" class="form-control" [(ngModel)]="password" id="password"
              placeholder="Enter password" name="password" formControlName="password">
          </div> -->
          <!-- <mat-form-field> -->
            <input type="password" class="form-control"  id="password"
            placeholder="Enter password" name="password" #password (keyup.enter)="doSignin()"/>
            <button mat-button [disableRipple]="true" class="eye" tabindex="-1">
              <mat-icon 
             style="cursor: pointer"
             matSuffix
             (mousedown)="password.type = 'text'"
             (mouseup)="password.type = 'password'"
             [inline]="true">
              {{ password.type == "password" ? "visibility_off" : "visibility" }}
             </mat-icon>
            </button>
             <br>
           <!-- </mat-form-field> -->
          <button (click)=doSignin() class="btn btn-success">Login</button>
        </form>
      </div>
    </div>
  </div>

  <!-- MFA - TOTP -->
  <div class="container col-lg-6" *ngIf="toTpVerification">
    <h1 class="text-center">Multi-Factor Authentication</h1>
    <ng-container *ngIf="qrCode != null && qrCode.length != 0">
      <div style="text-align: center;">
        <img [src]="qrCode" />
        <p style="font-weight: bold;color: red;">For your security please follow the steps below.</p>
        <ol style="text-align: left;">
          <li>Download an Authenticator app of your choice to your phone.</li>
          <li>Open the installed application on your phone and create a new Account.</li>
          <li>This will give you an option to Scan QR Code. Please scan the QR Code provided above.</li>
          <li>Once this is completed a new 6 digit code will be generated every 30 seconds on your phone.</li>
          <li>You will need to input this 6 digit code when signing into this application in the future.</li>
        </ol>
        <p style="font-weight: bold;color: red;">Complete the above steps before trying to Continue.</p>
        <button (click)=clearQrCode() class="btn btn-success">Continue</button>
      </div>
    </ng-container>
    <ng-container *ngIf="qrCode == null || qrCode.length == 0">
      <div class="card">
        <div class="card-body">
          <form class="form-group">
            <div style="color : red" *ngIf='error'>{{error}}</div>
            <div class="form-group">
              <label for="totpCode">Code :</label>
              <input type="text" class="form-control" id="totpCode" [(ngModel)]="verificationCode" placeholder="Enter 6 Digit Code"
                name="totpCode" autocomplete="off">
            </div>
           
            <button (click)=doSignin() class="btn btn-success">Login</button>
          </form>
        </div>
      </div>
    </ng-container>
  </div>
</body>