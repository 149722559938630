import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BankHoliday } from 'src/app/model/BankHoliday/bank-holiday';
import { SystemParameterConstants } from 'src/app/model/constants/system-parameter-constants';
import { DateString } from 'src/app/model/date-string';
import { SystemParameters } from 'src/app/model/systemParameters/system-parameters';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { SystemParametersService } from 'src/app/services/systemParameter/system-parameters.service';
import { ProcessesService } from 'src/app/services/webmaster/processes.service';

@Component({
  selector: 'app-processes',
  templateUrl: './processes.component.html',
  styleUrls: ['./processes.component.scss'],
})
export class ProcessesComponent implements OnInit {
  params: any = [SystemParameterConstants.EFT_ACTIVATED];
  systemParameters: SystemParameters[] = [];

  modalOptions: NgbModalOptions;
  closeResult: string = '';
  @ViewChild('processModal') processModal : any;
  @ViewChild('holidayModal') holidayModal : any;
  
  reportResults:any;
  selected: Date | null = null;
  holidayList:any[] = [];
  dates:any = [];

  loading$ = this.loader.loading$;
  constructor(public loader: SpinnerService,public authService: AuthService, private systemParameterService: SystemParametersService,
    private processesService: ProcessesService,private modalService: NgbModal,private renderer: Renderer2,) {
      this.modalOptions = {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
        size:'xl',
      };
    }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.getParams();
  }

  getParams() {
    this.systemParameterService
      .getSystemParameterDetails(this.params)
      .subscribe(
        (response: any) => {
          this.systemParameters = response.result;
          this.authService.refreshToken().subscribe(
            (result) => {},
            () => {}
          );
        },
        (error: HttpErrorResponse) => {
          alert(error.message);
        }
      );
  }
  open(content: any) {
    console.log('open');
    this.modalService.open(content, this.modalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  isAffirmative(s: string) {
    if(s != null && (s.toUpperCase().match('Y') || s.toUpperCase().match('YES') || s.match("1"))){
      return true;
    } else{
      return false;
    }
	}
  activate(str:any){
    console.log(str);
    this.processesService.generate(str).subscribe(
      (response: any) => {
        console.log(response);
        this.reportResults = response;
        this.open(this.processModal);
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      }
    );
  }
  asIsOrder(a:any, b:any) {
    return 1;
 }


  getHolidays(): void {
    this.processesService.getHolidays().subscribe(
      (response: any) => {
        console.log(response);
        this.holidayList = response;
        for(let h of this.holidayList){
          h.holiday = new DateString(h.holiday);
        }
        this.open(this.holidayModal);
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      }
    );
  }
  addHoliday(){
    const date = this.holidayList.find((obj:any) => {
      return (this.dateToString(new Date(obj.holiday)) === this.dateToString(this.selected))? true : false;
    });
    if(date != null || date != undefined){
      alert("Cannot add a holiday that already exists.");
      return;
    }
    this.processesService.addHoliday(this.selected).subscribe(
      (response: any) => {
        console.log(response);
        this.holidayList = response;
        for(let h of this.holidayList){
          h.holiday = new DateString(h.holiday);
        }
        this.modalService.dismissAll();
        this.open(this.holidayModal);
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      }
    );  
  }
  deleteHoliday(data:any){
    this.processesService.deleteHoliday(data).subscribe(
      (response: any) => {
        console.log(response);
        this.holidayList = response;
        for(let h of this.holidayList){
          h.holiday = new DateString(h.holiday);
        }
        this.modalService.dismissAll();
        this.open(this.holidayModal);
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      }
    );
  }

  dateToString(date: any) {
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2)
    );
  }
  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    const date = cellDate;

    //Highlight Holidays light red
    for(let h of this.holidayList){
      var holiday = new Date(h.holiday.dateString);
      if(holiday.getFullYear() === date.getFullYear() && holiday.getMonth() === date.getMonth() && holiday.getDate() === date.getDate()){
        this.dates.push({date: this.dateToString(holiday), text: "Holiday!"});
        return 'example-custom-date-class';
      }
    }
    return 'example-custom-date';
  }

}
