import { EmployeeWorkHistoryFundDetail } from "../employeeWorkHistoryFundDetail/employee-work-history-fund-detail";
import { FundDetails } from "../fundDetails/fund-details";
import { FundItems } from "../fundDetails/fund-items";

export class ErfEmployee {

    public adminServiceEnvGUID: number | null;
    public cashAppliedDetail: any | null;
    public createdByGUID: number | null;
    public createdByID: number | null;
    public createdDate: string | null;
    public daysReported: number | null;
    public demoAddressLine1: string | null;
    public demoAddressLine2: string | null;
    public demoCellPhone: string | null;
    public demoCity: string | null;
    public demoDateOfBirth: string | null;
    public demoEmail: string | null;
    public demoGender: string | null;
    public demoHomePhone: string | null;
    public demoPreferredCommMethod: string | null;
    public demoState: string | null;
    public demoZipPostalCode: string | null;
    public dob: string | null;
    public eeClass: string | null;
    public emplStatusDate: string | null;
    public emplStatusGuid: string | null;
    public emplStatusReasonGuid: string | null;
    public employee: any | null;
    public employeeFundHoursApplied: any | null;
    public exceptions: any | null;
    public firstName: string | null;
    public fundDetails: FundDetails | any;
    public gender: string | null;
    public guid: number | null;
    public hoursClass: any | null;
    public hoursReportedDT: number | null;
    public hoursReportedOT: number | null;
    public hoursReportedST: number | null;
    public jobClass: any | null;
    public lastName: string | null;
    public lastUpdateByGUID: string | null;
    public lastUpdateByID: string | null;
    public lastUpdateDate: string | null;
    public lineItemNumber: number | null;
    public mi: string | null;
    public mid: string | null;
    public obsolete: string | null;
    public ownerOperator: boolean | null;
    public ownerOperatorGUID: string | null;
    public preListStatus: string | null;
    public project: string | null;
    public projectID: string | null;
    public projectName: string | null;
    public regionGuid: string | null;
    public remark: string | null;
    public reversed: string | null;
    public ssn: string | any | null;
    public typeGuid: string | null;
    public typeId: string | null;
    public updateRequired: string | null;
    public version: string | null;
    public wages: number | null;
    public week: number | null;
    public weeksReported: number | null;
    public workingLocal: string | null;
    public employeeWorkHistoryFundDetail: EmployeeWorkHistoryFundDetail[] | any | null;
    public type = 'ErfEmployeeVO';
  
    constructor(adminServiceEnvGUID: number | null,cashAppliedDetail: any | null,createdByGUID: number | null,createdByID: number | null,
        createdDate: string | null,daysReported: number | null,demoAddressLine1: string | null,demoAddressLine2: string | null,
        demoCellPhone: string | null,demoCity: string | null,demoDateOfBirth: string | null,demoEmail: string | null,demoGender: string | null,
        demoHomePhone: string | null,demoPreferredCommMethod: string | null,demoState: string | null,demoZipPostalCode: string | null,dob: string | null,
        eeClass: string | null,emplStatusDate: string | null,emplStatusGuid: string | null,employee: any | null,employeeFundHoursApplied: any | null,
        exceptions: any | null,firstName: string | null,gender: string | null,guid: number | null,hoursClass: any | null,hoursReportedDT: number | null,
        hoursReportedOT: number | null,hoursReportedST: number | null,jobClass: any | null,lastName: string | null,lastUpdateByGUID: string | null,
        lastUpdateByID: string | null,lastUpdateDate: string | null,lineItemNumber: number | null,mi: string | null,mid: string | null,
        obsolete: string | null,ownerOperator: boolean | null,ownerOperatorGUID: string | null,preListStatus: string | null,project: string | null,
        projectID: string | null,projectName: string | null,regionGuid: string | null,remark: string | null,reversed: string | null,ssn: string | any| null,
        typeGuid: string | null,typeId: string | null,updateRequired: string | null,version: string | null,wages: number | null,
        week: number | null,weeksReported: number | null,workingLocal: string | null,employeeWorkHistoryFundDetail: EmployeeWorkHistoryFundDetail[] | any | null,fundDetails: FundDetails,emplStatusReasonGuid: string | null){
        this.adminServiceEnvGUID = adminServiceEnvGUID;
        this.cashAppliedDetail = cashAppliedDetail;
        this.createdByGUID = createdByGUID;
        this.createdByID = createdByID;
        this.createdDate = createdDate;
        this.daysReported = 0;
        this.demoAddressLine1 = demoAddressLine1;
        this.demoAddressLine2 = demoAddressLine2;
        this.demoCellPhone = demoCellPhone;
        this.demoCity = demoCity;
        this.demoDateOfBirth = demoDateOfBirth;
        this.demoEmail = demoEmail;
        this.demoGender = demoGender;
        this.demoHomePhone = demoHomePhone;
        this.demoPreferredCommMethod = demoPreferredCommMethod;
        this.demoState = demoState;
        this.demoZipPostalCode = demoZipPostalCode;
        this.dob = dob;
        this.eeClass = eeClass;
        this.emplStatusDate = emplStatusDate;
        this.emplStatusGuid = emplStatusGuid;
        this.emplStatusReasonGuid = emplStatusReasonGuid;
        this.employee = employee;
        this.employeeFundHoursApplied = employeeFundHoursApplied;
        this.exceptions = exceptions;
        this.firstName = firstName;
        this.fundDetails = fundDetails;
        this.gender = gender;
        this.guid = guid;
        this.hoursClass = {guid: null, parameterDesc: '-'};
        this.hoursReportedDT = 0;
        this.hoursReportedOT = 0;
        this.hoursReportedST = 0;
        this.jobClass = {guid: null, parameterDesc: '-'};
        this.lastName = lastName;
        this.lastUpdateByGUID = lastUpdateByGUID;
        this.lastUpdateByID = lastUpdateByID;
        this.lastUpdateDate = lastUpdateDate;
        this.lineItemNumber = lineItemNumber;
        this.mi = mi;
        this.mid = mid;
        this.obsolete = 'false';
        this.ownerOperator = ownerOperator;
        this.ownerOperatorGUID = ownerOperatorGUID;
        this.preListStatus = 'A';//Newly added employee used for delete btn
        this.project = project;
        this.projectID = projectID;
        this.projectName = projectName;
        this.regionGuid = regionGuid;
        this.remark = remark;
        this.reversed = reversed;
        this.ssn = ssn;
        this.typeGuid = typeGuid;
        this.typeId = typeId;
        this.updateRequired = updateRequired;
        this.version = version;
        this.wages = 0;
        this.week = 0;
        this.weeksReported = 0;
        this.workingLocal = workingLocal;
        this.employeeWorkHistoryFundDetail = employeeWorkHistoryFundDetail;
        // this.type = type;
    }
  }