export class FundItems {
    public original: number | null;
    public vebaDue: number | null;
    public vebaCalc: number | null;

    constructor(original: number | null,vebaDue: number | null,vebaCalc: number | null){
        this.original = original;
        this.vebaDue = vebaDue;
        this.vebaCalc = vebaCalc;
    }
}
