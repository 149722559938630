import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './layout/body/auth/login/login.component';
import { PageNotFoundComponent } from './layout/body/error/page-not-found/page-not-found.component';
import { HomeComponent } from './layout/body/home/home.component';
import { ReportOptionsComponent } from './layout/body/report-options/report-options.component';
import { ListReportsComponent } from './layout/body/list-reports/list-reports.component';
import { RemittanceReportComponent } from './layout/body/remittance-report/remittance-report.component';
import { EmployeeDemographicsComponent } from './layout/body/employee-demographics/employee-demographics.component';
import { SearchEmployersComponent } from './layout/body/search-employers/search-employers/search-employers.component';
import { ChangePasswordComponent } from './layout/body/change-password/change-password/change-password.component';
import { AdministrativeReportsComponent } from './layout/body/administrative-reports/administrative-reports/administrative-reports.component';
import { StaffUserAccountsComponent } from './layout/body/setup-edit/set-edit/staff-user-accounts/staff-user-accounts.component';
import { EmployerEftAccountDetailsComponent } from './layout/body/setup-edit/set-edit/employer-eft-account-details/employer-eft-account-details.component';
import { ProcessesComponent } from './layout/body/webmaster/utilities/processes/processes.component';
import { DatabaseComponent } from './layout/body/webmaster/utilities/database/database.component';
import { CreateEditComponent } from './layout/body/webmaster/announcements/create-edit/create-edit.component';
import { CensusImportComponent } from './layout/body/census-import/census-import.component';
import { MassImportComponent } from './layout/body/report-options/mass-import/mass-import.component';
import { SpecialMappingsComponent } from './layout/body/specialMappings/special-mappings/special-mappings.component';
import { ContactUsComponent } from './layout/body/contact-us/contact-us.component';
import { DefaultComponent } from './layout/body/liaison/default/default.component';

import { ViewListComponent } from './layout/body/project/view/view-list/view-list.component';
import { CreateProjectComponent } from './layout/body/project/create/create-project/create-project.component';
import { EditProjectComponent } from './layout/body/project/edit/edit-project/edit-project.component';
import { ManageStaffComponent } from './layout/body/manage-staff/manage-staff/manage-staff.component';
import { TermsOfUseComponent } from './layout/body/webmaster/TermsOfUse/terms-of-use/terms-of-use.component';
import { AcceptTermsOfUseComponent } from './layout/body/auth/accept-terms-of-use/accept-terms-of-use.component';

const routes: Routes = [
  { path: '', component: LoginComponent},
  { path: 'acceptTermsOfUse', component: AcceptTermsOfUseComponent},
  { path: 'login', component: LoginComponent},
  { path: 'logout', component: LoginComponent},
  { path: 'home', component: HomeComponent}, 
  { path: 'liaison', component: DefaultComponent}, 
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'manage-staff', component: ManageStaffComponent},
  { path: 'reportOptions', component: ReportOptionsComponent},
  { path: 'reportOptions/massImport', component: MassImportComponent},
  { path: 'listReports', component: ListReportsComponent},
  { path: 'listProject', component: ViewListComponent},
  { path: 'remittanceReport', component: RemittanceReportComponent},
  { path: 'remittanceReport/:agreementId', component: RemittanceReportComponent},
  { path: 'employeeDemographics', component: EmployeeDemographicsComponent},
  { path: 'searchEmployers', component: SearchEmployersComponent}, 
  { path: 'changePW', component: ChangePasswordComponent}, 
  { path: 'censusImport', component: CensusImportComponent},
  { path: 'administrativeReports', component: AdministrativeReportsComponent}, 
  { path: 'staffUserAccounts', component: StaffUserAccountsComponent},
  { path: 'specialMapping', component: SpecialMappingsComponent},
  { path: 'employerEFTaccountDetails', component: EmployerEftAccountDetailsComponent},
  { path: 'webmaster/util/processes', component: ProcessesComponent}, 
  { path: 'webmaster/util/database', component: DatabaseComponent}, 
  { path: 'webmaster/announcements', component: CreateEditComponent}, 
  { path: 'webmaster/termsOfUse', component: TermsOfUseComponent}, 
  { path: '**', component: PageNotFoundComponent},
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
