import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SystemParameters } from 'src/app/model/systemParameters/system-parameters';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FileService } from 'src/app/services/file/file.service';
import { SystemParametersService } from 'src/app/services/systemParameter/system-parameters.service';

@Component({
  selector: 'app-system-parameters',
  templateUrl: './system-parameters.component.html',
  styleUrls: ['./system-parameters.component.scss']
})
export class SystemParametersComponent implements OnInit {

  systemParameters: SystemParameters[] = [];

  constructor(private systemParameterService : SystemParametersService,public authService: AuthService,
    private fileService : FileService) { }

  ngOnInit(): void {
    this.getSystemParameters();
  }

  public getSystemParameters(): void {
    // this.systemParameterService.getSystemParameters().subscribe(
    //   (response: SystemParameters[]) => {
    //     this.systemParameters = response;
    //     this.systemParameters.sort((a, b) => (a.adminServiceEnvGUID < b.adminServiceEnvGUID || a.parameter_CODE < b.parameter_CODE ? -1 : 1));
    //     this.systemParameters.sort((a, b) => (a.parameter_CODE < b.parameter_CODE? -1 : 1)).
    //     sort((a, b) => (a.adminServiceEnvGUID < b.adminServiceEnvGUID? -1 : 1));
    //     this.authService.refreshToken().subscribe((result)=> {}, () => {});

    //   },
    //   (error: HttpErrorResponse) => {
    //     alert(error.message);
    //   }
    // );
    // this.systemParameterService.getSystemParameters().subscribe((response: SystemParameters[]) => this.systemParameters = response, () => console.log('grabed params'));
  }

  selectedFiles?: FileList;
  currentFile?: File;
  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  upload(): void {
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;

        this.fileService.upload(this.currentFile).subscribe(
          (event: any) => {
            alert("Success File Uploaded");
          },
          (err: any) => {
            console.log(err);
            alert("Failed to Upload File");
            this.currentFile = undefined;
          });
      }

      this.selectedFiles = undefined;
    }
  }

}
