<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>
<body>
    <h3 *ngIf="employer">
        <ng-container *ngIf="isEmployerOrStaff() && !isPayRoll()">
            {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
        <ng-container *ngIf="!isEmployerOrStaff() && !isPayRoll()">
            System Administrator on behalf of {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
        <ng-container *ngIf="isPayRoll()">
            Payroll Company
        </ng-container>
    </h3>
    <span class="pageHeader">Employee Demographics</span>
    <mat-form-field class="search" appearance="fill" color="homeary">
        <mat-label>Search SSN or Name</mat-label>
        <input matInput [(ngModel)]="searchValueId" (ngModelChange)=SearchId()>
    </mat-form-field>
    <button class="addBtn" mat-raised-button color="primary" (click)="clearAddTable();open(addModal)">Add Employee</button>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- STATUS Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="objectTypeCheck(element.mid);else status_content">
                    <ng-container *ngIf="!!element.statusHistory && !!element.statusHistory[0]">
                        <!-- {{element.statusHistory[0].statusCode.parameterDesc}}  -->
                        {{getStatusHist(element)}}
                    </ng-container>
                </ng-container>
                <ng-template #status_content>
                    <label title="Work In Progress">WIP</label>
                </ng-template> 
            </td>
        </ng-container>

        <!-- SSN Column -->
        <ng-container matColumnDef="ssn">
          <th mat-header-cell *matHeaderCellDef> SSN </th>
          <td mat-cell *matCellDef="let element"> 
            <ng-container *ngIf="objectTypeCheck(element.mid);else ssn_content">
                {{element.employee.ssn | slice:0:3}}-{{element.employee.ssn | slice:3:5}}-{{element.employee.ssn | slice:5}} 
            </ng-container>
            <ng-template #ssn_content>
                {{element.ssn | slice:0:3}}-{{element.ssn | slice:3:5}}-{{element.ssn | slice:5}} 
            </ng-template>
        </td>
        </ng-container>
      
        <!-- First Name Column -->
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef> First Name </th>
          <td mat-cell *matCellDef="let element"> 
            <ng-container *ngIf="objectTypeCheck(element.mid);else firstName_content">
                {{element.employee.firstName}} 
            </ng-container>
            <ng-template #firstName_content>
                {{element.firstName}} 
            </ng-template>  
        </td>
        </ng-container>
      
        <!-- Last Name Column -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef> Last Name </th>
          <td mat-cell *matCellDef="let element"> 
            <ng-container *ngIf="objectTypeCheck(element.mid);else lastName_content">
                {{element.employee.lastName}}
            </ng-container>
            <ng-template #lastName_content>
                {{element.lastName}} 
            </ng-template>     
        </td>
        </ng-container>
      
        <!-- Middle Name Column -->
        <ng-container matColumnDef="middleName">
          <th mat-header-cell *matHeaderCellDef> Middle Name </th>
          <td mat-cell *matCellDef="let element"> 
            <ng-container *ngIf="objectTypeCheck(element.mid);else middleName_content">
                {{element.employee.middleName}} 
            </ng-container>
            <ng-template #middleName_content>
                {{element.middleName}} 
            </ng-template>    
        </td>
        </ng-container>

        <!-- HIRE DATE Column -->
        <ng-container matColumnDef="hireDate">
            <th mat-header-cell *matHeaderCellDef> Hire Date </th>
            <td mat-cell *matCellDef="let element"> 
                <ng-container *ngIf="objectTypeCheck(element.mid);else hireDate_content">
                    {{element.hireDate | date:'longDate'}} 
                </ng-container>
                <ng-template #hireDate_content>
                    {{element.hireDate | date:'longDate'}} 
                </ng-template>    
            </td>
          </ng-container>

          <!-- TERM DATE Column -->
        <ng-container matColumnDef="termDate">
            <th mat-header-cell *matHeaderCellDef> Term Date </th>
            <td mat-cell *matCellDef="let element"> 
                <ng-container *ngIf="objectTypeCheck(element.mid);else termDate_content">
                    {{element.termDate  | date:'longDate'}} 
                </ng-container>
                <ng-template #termDate_content>
                    {{element.termDate | date:'longDate'}} 
                </ng-template>    
            </td>
        </ng-container>

        <ng-container *ngIf="employer != null">
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                    No employees found. Would you like to add one?<button class="addBtn" mat-raised-button color="primary" (click)="clearAddTable();open(addModal)">Add Employee</button>
                </td>
            </tr>
        </ng-container>

      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hoverRow" (dblclick)="editExistingEmployeeForQ(row)"></tr>
    </table>
    <mat-paginator [length]="dataSource.data.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event" aria-label="Select page">
    </mat-paginator>
        
</body>

<!-- Add Employee Modal -->
<ng-template #addModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Add Employee</h4>
            <button type="button" class="close" aria-label="Close" (click)="clearDemoQ();modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <table>
                <tr>
                    <td><strong><span class="required">*</span>SSN: </strong></td>
                    <td></td>
                    <!-- <td><strong>Name Suffix: </strong></td> -->
                </tr>
                <tr>
                    <td>
                        <input #ssn name="ssn" maxlength="9" title="SSN" (focus)="snnFormatEditer(ssn)" (focusout)="ssn.value=ssnFormater(tmpDemoQ.ssn);" [value]="ssnFormater(tmpDemoQ.ssn)" (change)="updateSsnData(ssn.value)"/>
                        <!-- <input name="ssn" maxlength="9" title="SSN" [(ngModel)]="tmpDemoQ.ssn"/> -->
                    </td>
                    <td></td>
                    <!-- <td>
                        <select class="suffix" name="suffix" id="suffix" [(ngModel)]="tmpDemoQ.nameSuffix">
                            <option value="null">-</option>
                            <ng-container *ngFor="let suffix of personNameSuffixList; let i = index">
                                <option  [ngValue]="suffix.parameterCode">{{suffix.parameterCode}}</option>
                            </ng-container>
                        </select>
                    </td> -->
                </tr>
                <tr>
                    <td><strong><span class="required">*</span>First Name: </strong></td>
                    <td><strong>Middle Name: </strong></td>
                    <td><strong><span class="required">*</span>Last Name: </strong></td>
                    <td><strong>Suffix: </strong></td>
                </tr>
                <tr>
                    <td><input name="firstName" title="First Name" maxlength="50"  [(ngModel)]="tmpDemoQ.firstName"/></td>
                    <td><input name="middleName" title="Middle Name" maxlength="50" [(ngModel)]="tmpDemoQ.middleName"/></td>
                    <td><input name="lastName" title="last Name" maxlength="50" [(ngModel)]="tmpDemoQ.lastName"/></td>
                    <td>
                        <select class="suffix" name="suffix" id="suffix" [(ngModel)]="tmpDemoQ.nameSuffix">
                            <option value="null">-</option>
                            <ng-container *ngFor="let suffix of personNameSuffixList; let i = index">
                                <option  [ngValue]="suffix.parameterCode">{{suffix.parameterCode}}</option>
                            </ng-container>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td><strong>Address 1: </strong></td>
                    <td><strong>Address 2: </strong></td>
                </tr>
                <tr>
                    <td><input name="address1" title="Address 1" maxlength="50" [(ngModel)]="tmpDemoQ.addressLine1"/></td>
                    <td><input name="address2" title="Address 2" maxlength="50" [(ngModel)]="tmpDemoQ.addressLine2"/></td>
                </tr>
                <tr>
                    <td><strong>City: </strong></td>
                    <td><strong>State: </strong></td>
                    <td><strong>ZIP: </strong></td>
                </tr>
                <tr>
                    <td><input name="city" title="City" maxlength="50" [(ngModel)]="tmpDemoQ.city"/></td>
                    <td>
                        <!-- <input name="state" title="State" [(ngModel)]="tmpDemoQ.state"/> -->
                        <select class="states" name="states" id="states" [(ngModel)]="tmpDemoQ.state">
                            <option value="null">-</option>
                            <ng-container *ngFor="let state of statesList; let i = index">
                                <option  [ngValue]="state.parameterCode">{{state.parameterDesc}}</option>
                            </ng-container>
                        </select>
                    </td>
                    <td><input name="zip" title="Zip Code" maxlength="9" [(ngModel)]="tmpDemoQ.zipPostalCode"/></td>
                </tr>
                <tr>
                    <td><strong>Home Phone: </strong></td>
                    <td><strong>Cell Phone: </strong></td>
                    <td><strong>Email: </strong></td>
                </tr>
                <tr>
                    <td>
                        <!-- <input name="homePhone" title="Home Phone" type="number" placeholder="XXX-XXX-XXXX"/> -->
                        <input #home1 (keyup)="(home1.value.length == 3) ? home2.focus() : ''" name="home1" type="text" id="home1" size="2" title="Area Code" maxlength="3" [(ngModel)]="tmpDemoQ.homeAreaCode" class="phone">-<input #home2 (keyup)="(home2.value.length == 3) ? home3.focus() : ''" name="home2" type="text" id="home2" size="2" maxlength="3" [(ngModel)]="tmpHomePhoneMid" class="phone">-<input #home3 (keyup)="(home3.value.length == 4) ? cell1.focus() : ''" name="home3" type="text" id="home3" size="2" maxlength="4" [(ngModel)]="tmpHomePhoneEnd" class="phone">
                    </td>
                    <td>
                        <!-- <input name="cellPhone" title="Cell Phone" type="number" placeholder="XXX-XXX-XXXX"/> -->
                        <input #cell1 (keyup)="(cell1.value.length == 3) ? cell2.focus() : ''" name="cell1" type="text" id="cell1" size="2" title="Area Code" maxlength="3" [(ngModel)]="tmpDemoQ.cellAreaCode" class="phone">-<input #cell2 (keyup)="(cell2.value.length == 3) ? cell3.focus() : ''" name="cell2" type="text" id="cell2" size="2" maxlength="3" [(ngModel)]="tmpCellPhoneMid" class="phone">-<input #cell3 (keyup)="(cell3.value.length == 4) ? email.focus() : ''" name="cell3" type="text" id="cell3" size="2" maxlength="4" [(ngModel)]="tmpCellPhoneEnd" class="phone">
                    </td>
                    <td><input #email name="email" title="Email" type="email" maxlength="50"  [(ngModel)]="tmpDemoQ.emailAddress"/></td>
                </tr>
                <tr>
                    <td><strong>Gender: </strong></td>
                    <td><strong>Date of Birth: </strong></td>
                    <td><strong>Communication Method: </strong></td>
                </tr>
                <tr>
                    <td> 
                        <select name="gender" [(ngModel)]="tmpDemoQ.gender">
                            <option value=""></option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select>
                    </td>
                    <td>
                        <mat-form-field appearance="fill" style="width: 200px">
                            <mat-label>Choose D.O.B.</mat-label>
                            <input matInput [matDatepicker]="picker1" [readonly]="true" [(ngModel)]="tmpDemoQ.dateOfBirthStr">
                            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </td>
                    <td>
                        <select name="method" id="method" [(ngModel)]="tmpDemoQ.preferredCommMethodCode">
                            <ng-container *ngFor="let item of deliveryMethodList; let i = index">
                                <option  [ngValue]="item.parameterCode">{{item.parameterDesc}}</option>
                            </ng-container>
                        </select>
                    </td>
                </tr>

                <tr>
                    <td><strong><span class="required">*</span>Location: </strong></td>
                    <td><strong><span class="required">*</span>Employment Status: </strong></td>
                    <td><strong><span class="required">*</span>Employment Date: </strong></td>
                    <td style="white-space: nowrap;"><strong><span class="required">*</span>Hours Class: </strong></td>
                </tr>
                <tr>
                    <td>
                        <select name="method" id="method" [(ngModel)]="tmpDemoQ.locationGUID" style="width: 180px;">
                            <option value="null">--Select--</option>
                            <ng-container *ngFor="let item of agreementLocations; let i = index">
                                <!-- <ng-container *ngIf="item.activeProjects[i] != undefined"> -->
                                    <option  [ngValue]="item.organizationID">{{locationOption(item)}}</option>
                                    <!-- <option  [ngValue]="locationValue(item,i)">{{locationItem(item,i)}}</option> -->
                                <!-- </ng-container> -->
                            </ng-container>
                        </select>
                    </td>
                    <td>
                        <select name="method" id="method" [(ngModel)]="tmpDemoQ.employementStatusGUID">
                            <option value="null">--Select--</option>
                            <ng-container *ngFor="let item of employmentStatusReason; let i = index">
                                <option  [ngValue]="item.guid">{{item.parameterDesc}}</option>
                            </ng-container>
                        </select>
                    </td>
                    <td>
                        <mat-form-field appearance="fill" style="width: 200px">
                            <mat-label>Choose Date</mat-label>
                            <input matInput [matDatepicker]="picker2" [readonly]="true" [(ngModel)]="tmpDemoQ.employementStatusDate">
                            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </td>
                    <td>
                        <select name="method" id="method" [(ngModel)]="tmpDemoQ.hoursClassGUID">
                            <option value="null">--Select--</option>
                            <ng-container *ngFor="let item of allowedHoursClasses; let i = index">
                                <option  [ngValue]="item.guid">{{item.parameterDesc}}</option>
                            </ng-container>
                        </select>
                    </td>
                </tr>

            </table>

            <ng-container *ngIf="dataSourceAdd.data.length > 0">
                <div style="border: 2pt solid black;">
                    <h4 style="text-align: center;">Similar Items in Queue</h4>
                    <table id="tableAdd" #tableAdd mat-table [dataSource]="dataSourceAdd" multiTemplateDataRows class="mat-elevation-z8">

                        <!-- STATUS Column -->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element">
                                <ng-container *ngIf="objectTypeCheck(element.mid);else status_content">
                                    <ng-container *ngIf="!!element.statusHistory && !!element.statusHistory[0]">
                                        {{element.statusHistory[0].statusCode.parameterDesc}} 
                                    </ng-container>
                                </ng-container>
                                <ng-template #status_content>
                                    Awaiting Admin
                                </ng-template> 
                            </td>
                        </ng-container>
                
                        <!-- SSN Column -->
                        <ng-container matColumnDef="ssn">
                        <th mat-header-cell *matHeaderCellDef> SSN </th>
                        <td mat-cell *matCellDef="let element"> 
                            <ng-container *ngIf="objectTypeCheck(element.mid);else ssn_content">
                                {{element.employee.ssn | slice:0:3}}-{{element.employee.ssn | slice:3:5}}-{{element.employee.ssn | slice:5}} 
                            </ng-container>
                            <ng-template #ssn_content>
                                {{element.ssn | slice:0:3}}-{{element.ssn | slice:3:5}}-{{element.ssn | slice:5}} 
                            </ng-template>
                        </td>
                        </ng-container>
                    
                        <!-- First Name Column -->
                        <ng-container matColumnDef="firstName">
                        <th mat-header-cell *matHeaderCellDef> First Name </th>
                        <td mat-cell *matCellDef="let element"> 
                            <ng-container *ngIf="objectTypeCheck(element.mid);else firstName_content">
                                {{element.employee.firstName}} 
                            </ng-container>
                            <ng-template #firstName_content>
                                {{element.firstName}} 
                            </ng-template>  
                        </td>
                        </ng-container>
                    
                        <!-- Last Name Column -->
                        <ng-container matColumnDef="lastName">
                        <th mat-header-cell *matHeaderCellDef> Last Name </th>
                        <td mat-cell *matCellDef="let element"> 
                            <ng-container *ngIf="objectTypeCheck(element.mid);else lastName_content">
                                {{element.employee.lastName}}
                            </ng-container>
                            <ng-template #lastName_content>
                                {{element.lastName}} 
                            </ng-template>     
                        </td>
                        </ng-container>
                    
                        <!-- Middle Name Column -->
                        <ng-container matColumnDef="middleName">
                        <th mat-header-cell *matHeaderCellDef> Middle Name </th>
                        <td mat-cell *matCellDef="let element"> 
                            <ng-container *ngIf="objectTypeCheck(element.mid);else middleName_content">
                                {{element.employee.middleName}} 
                            </ng-container>
                            <ng-template #middleName_content>
                                {{element.middleName}} 
                            </ng-template>    
                        </td>
                        </ng-container>
                
                        <!-- HIRE DATE Column -->
                        <ng-container matColumnDef="hireDate">
                            <th mat-header-cell *matHeaderCellDef> Hire Date </th>
                            <td mat-cell *matCellDef="let element"> 
                                <ng-container *ngIf="objectTypeCheck(element.mid);else hireDate_content">
                                    {{element.hireDate | date:'shortDate'}} 
                                </ng-container>
                                <ng-template #hireDate_content>
                                    {{element.hireDate | date:'shortDate'}} 
                                </ng-template>    
                            </td>
                        </ng-container>
                
                        <!-- TERM DATE Column -->
                        <ng-container matColumnDef="termDate">
                            <th mat-header-cell *matHeaderCellDef> Term Date </th>
                            <td mat-cell *matCellDef="let element"> 
                                <ng-container *ngIf="objectTypeCheck(element.mid);else termDate_content">
                                    {{element.termDate  | date:'shortDate'}} 
                                </ng-container>
                                <ng-template #termDate_content>
                                    {{element.termDate | date:'shortDate'}} 
                                </ng-template>    
                            </td>
                        </ng-container>

                        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                            <ng-container matColumnDef="expandedDetail">
                                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                    <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                        <table>
                                            <tr class="tableHeader">
                                                <td>Address 1</td>
                                                <td>Address 2</td>
                                                <td>State</td>
                                                <td>City</td>
                                                <td>Zip</td>
                                            </tr>
                                            <tr class="tableElement">
                                                <td><ng-container *ngIf="!!element.addressLine1 || (element.employee != undefined && element.employee.locations != undefined && !!element.employee.locations[0].addressHistory[0].mailingAddress.addressLine1);else empty_content">{{(element.employee != undefined) ?  element.employee.locations[0].addressHistory[0].mailingAddress.addressLine1 : element.addressLine1 }}</ng-container></td>
                                                <td><ng-container *ngIf="!!element.addressLine2 || (element.employee != undefined && element.employee.locations != undefined && !!element.employee.locations[0].addressHistory[0].mailingAddress.addressLine2);else empty_content">{{(element.employee != undefined) ?  element.employee.locations[0].addressHistory[0].mailingAddress.addressLine2 : element.addressLine2}}</ng-container></td>
                                                <td><ng-container *ngIf="!!element.state || (element.employee != undefined && element.employee.locations != undefined && !!element.employee.locations[0].addressHistory[0].mailingAddress.state);else empty_content">{{(element.employee != undefined) ?  element.employee.locations[0].addressHistory[0].mailingAddress.state : element.state}}</ng-container></td>
                                                <td><ng-container *ngIf="!!element.city || (element.employee != undefined && element.employee.locations != undefined && !!element.employee.locations[0].addressHistory[0].mailingAddress.city);else empty_content">{{(element.employee != undefined) ?  element.employee.locations[0].addressHistory[0].mailingAddress.city : element.city}}</ng-container></td>
                                                <td><ng-container *ngIf="!!element.zipPostalCode || (element.employee != undefined && element.employee.locations != undefined && !!element.employee.locations[0].addressHistory[0].mailingAddress.zipPostalCode);else empty_content">{{(element.employee != undefined) ?  element.employee.locations[0].addressHistory[0].mailingAddress.zipPostalCode : element.zipPostalCode}}</ng-container></td>
                                            </tr>
                                            <tr class="tableHeader">
                                                <td>Home Phone</td>
                                                <td>Cell Phone</td>
                                                <td>Gender</td>
                                                <td>Communication Method</td>
                                                <td></td>
                                            </tr>
                                            <tr class="tableElement">
                                                <td><ng-container *ngIf="!!element.homeAreaCode || (element.employee != undefined && !!element.employee.phoneNumber);else empty_content">{{(element.employee != undefined) ? element.employee.phoneNumber.areaCode : element.homeAreaCode}}-{{(element.employee != undefined) ? (element.employee.phoneNumber.localNumber | slice:0:3) : (element.homeLocalNumber | slice:0:3)}}-{{(element.employee != undefined) ? (element.employee.phoneNumber.localNumber| slice:3) : (element.homeLocalNumber | slice:3)}}</ng-container></td>
                                                <td><ng-container *ngIf="!!element.cellAreaCode || (element.employee != undefined && !!element.employee.cellNumber);else empty_content">{{(element.employee != undefined) ? element.employee.cellNumber.areaCode : element.cellAreaCode}}-{{(element.employee != undefined) ? (element.employee.cellNumber.localNumber | slice:0:3) : (element.cellLocalNumber | slice:0:3)}}-{{(element.employee != undefined) ? (element.employee.cellNumber.localNumber | slice:3) : (element.cellLocalNumber | slice:3)}}</ng-container></td>
                                                <td><ng-container *ngIf="!!element.gender || (element.employee != undefined && !!element.employee.gender);else empty_content">{{(element.employee != undefined) ?  element.employee.gender : element.gender}}</ng-container></td>
                                                <td><ng-container *ngIf="!!element.preferredCommMethodCode || (element.employee != undefined && !!element.employee.preferredCommMethod);else empty_content">{{(element.employee != undefined) ?  element.employee.preferredCommMethod : element.preferredCommMethodCode}}</ng-container></td>
                                            </tr>
                                            <tr class="tableHeader">
                                                <td>Date of Birth</td>
                                                <td>Name Suffix</td>
                                                <td>Email</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr class="tableElement">
                                                <td><ng-container *ngIf="!!element.dateOfBirthStr || (element.employee != undefined && !!element.employee.birthDate);else empty_content">{{(element.employee != undefined) ?  (element.employee.birthDate | date:'longDate') : (element.dateOfBirthStr | date:'longDate')}}</ng-container></td>
                                                <td><ng-container *ngIf="!!element.nameSuffix || (element.employee != undefined && !!element.employee.nameSuffix);else empty_content">{{(element.employee != undefined) ?  element.employee.nameSuffix : element.nameSuffix}}</ng-container></td>
                                                <td><ng-container *ngIf="!!element.emailAddress || (element.employee != undefined && !!element.employee.emailAddress);else empty_content">{{(element.employee != undefined) ?  element.employee.emailAddress : element.emailAddress}}</ng-container></td>
                                            </tr>
                                            
                                            <ng-template #empty_content>
                                                -
                                            </ng-template> 
                                        </table>
                                    </div>
                                </td>
                            </ng-container>
                    
                        <tr mat-header-row *matHeaderRowDef="similarDisplayedColumns"></tr>
                        <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
                        <tr mat-row *matRowDef="let element; columns: similarDisplayedColumns;" class="example-element-row"
                            [class.example-expanded-row]="expandedElement === element"
                            (click)="expandedElement = expandedElement === element ? null : element;">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                </div>
            </ng-container>
        </div>
        <div class="modal-footer">
            <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="clearDemoQ();modal.close('Save click')">Cancel</button>
            <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="validationChecker();">Save</button>
        </div>
    </div>
</ng-template>