import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { ProcessesService } from 'src/app/services/webmaster/processes.service';

@Component({
  selector: 'app-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.scss']
})
export class DatabaseComponent implements OnInit {

  loading$ = this.loader.loading$;
  constructor(public loader: SpinnerService,private processesService: ProcessesService) { }

  ngOnInit(): void {
  }

  activate(str:any){
    console.log(str);
    this.processesService.databaseSync(str).subscribe(
      (response: any) => {
        console.log(response);
        alert(response.message);
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      }
    );
  }
}
