<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>

<body *ngIf="employer">
    <h3>{{employer.organizationName}} : {{employer.organizationID}}</h3>
    <span class="pageHeader">Administrative Reports Criteria</span>

    <!-- Employer -->
    <ng-container *ngIf="type === 'Employer'">
        <!-- Employer -->
        <ng-container *ngIf="subType === 'employerContribLedger'">
            <h5>Generate an Employer Administrative Report</h5>
            <p>Select from the following options:</p>
            <div class="shaded">
                <p><strong>Employer Contributions Ledger</strong></p>
                <table class="fullwidth">
                    <tr>
                        <td class="nowrap"><span class="required">Ending Work
                                Period*</span><strong>:</strong>&nbsp;&nbsp;
                            <mat-form-field appearance="fill">
                                <mat-label>Ending Work Period</mat-label>
                                <input matInput [matDatepicker]="dp3" [readonly]="true" [(ngModel)]="reportDate">
                                <mat-hint>MM/DD/YYYY</mat-hint>
                                <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                                <mat-datepicker #dp3 color="primary"></mat-datepicker>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td class="nowrap">
                            <mat-checkbox class="example-margin" color="primary" [(ngModel)]="hours">Show applicable hours (straight time, overtime, doubletime)</mat-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td class="nowrap">
                            <mat-checkbox class="example-margin" color="primary" [(ngModel)]="wages">Show wages</mat-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td class="nowrap">
                            <mat-checkbox class="example-margin" color="primary" [(ngModel)]="contrib">Show contributions</mat-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td class="nowrap">
                            <mat-checkbox class="example-margin" color="primary" [(ngModel)]="contribRate">Show contribution rate</mat-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td class="nowrap">
                            <mat-checkbox class="example-margin" color="primary" [(ngModel)]="agreement">Show agreement</mat-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td class="nowrap">
                            <mat-checkbox class="example-margin" color="primary" [(ngModel)]="jobClass">Show job class</mat-checkbox>
                        </td>
                    </tr>
                </table>
            </div>
        </ng-container>
        <!-- Employee -->
        <ng-container *ngIf="subType === 'employeeContribLedger'">
            <h5>Generate an Employer Administrative Report</h5>
            <p>Select from the following options:</p>
            <div class="shaded">
                <p><strong>Employee Contributions Ledger</strong></p>
                <table class="fullwidth">
                    <tr>
                        <td class="nowrap">Work Period Year:&nbsp;&nbsp;</td>
                        <!-- <select property="year">
                            <options property="years" />
                        </select> -->
                        <!-- <select (change)="selectSort1Change(sort1);" [(ngModel)]="sort1"> -->
                        <select (change)="checkdate();" [(ngModel)]="employeeDate">
                            <option *ngFor="let item of yearList" [ngValue]="item">
                                {{ item }}
                            </option>
                        </select>
                    </tr>
                    <tr>
                        <td class="nowrap">
                            <mat-checkbox class="example-margin" color="primary" [(ngModel)]="subByCalQtr">Sub-totals by calendar quarter
                            </mat-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td class="nowrap">
                            <mat-checkbox class="example-margin" color="primary" [(ngModel)]="ttls">Show totals only</mat-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td class="nowrap">
                            <mat-checkbox class="example-margin" color="primary" [(ngModel)]="hours">Show hours</mat-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td class="nowrap">
                            <mat-checkbox class="example-margin" color="primary" [(ngModel)]="contrib">Show contributions</mat-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td class="nowrap">
                            <mat-checkbox class="example-margin" color="primary" [(ngModel)]="wages">Show wages</mat-checkbox>
                        </td>
                    </tr>
                </table>
            </div> <!-- end shaded -->
        </ng-container>
    </ng-container>

    <!-- Web Master -->
    <ng-container></ng-container>

    <!-- Buttons -->
    <div>
        <button mat-raised-button (click)="generate()">Generate</button>
        <button mat-raised-button>Cancel</button>
    </div>
</body>

<!-- PDF Modal -->
<ng-template #pdfModal let-modal class="modal-dialog modal-fullscreen">
    <div class="modal-header pdfHeader">
        <h1>Generated Report</h1>
    </div>
    <div class="modal-body">
        <p >
            <object [data]="pdfURL()" type="application/pdf" width="100%" height="800px" ></object>
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
    </div>
</ng-template>