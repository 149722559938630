<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>
<body *ngIf="employer">
    <h3>
        <ng-container *ngIf="isEmployerOrStaff() && !isPayRoll()">
            {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
        <ng-container *ngIf="!isEmployerOrStaff() && !isPayRoll()">
            System Administrator on behalf of {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
        <ng-container *ngIf="isPayRoll()">
            Payroll Company
        </ng-container>
    </h3>

    <button mat-raised-button type="button" color="primary" class="btn btn-outline-dark" title="Previously Submitted Census Files" (click)="retrieveSubmittedList()" style="right: 25%;
    position: absolute;
">
        <mat-icon>list</mat-icon>
    </button>
    
    <table mat-table [dataSource]="employerList" class="agreementList">

        <!-- Status Column -->
        <ng-container matColumnDef="ID">
            <th mat-header-cell *matHeaderCellDef class="header"> ID </th>
            <td mat-cell *matCellDef="let element" class="ID">
                <label class="hoverRow">
                    {{element.organizationID}}
                </label>
            </td>
        </ng-container>

        <!-- Location Column -->
        <ng-container matColumnDef="Location">
            <th mat-header-cell *matHeaderCellDef class="header"> Location </th>
            <td mat-cell *matCellDef="let element; let i = index">
                <label class="hoverRow">
                    {{element.organizationName}}
                </label>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hoverRow" (dblclick)="startImportProcess(row)"></tr>
    </table>


</body>

<!-- Import Modal -->
<ng-template #importModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Census Import</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- used to hold all the tables NOT including preview -->
        <table style="width: 100%;">
            <tr>
                <!-- Census Fields that need mapping -->
                <td style="width: 50%;">
                    <table>
                        <tr style="border: solid;">
                            <td style="border: solid;white-space: nowrap;">Mapping ID</td>
                            <td style="border: solid;">Census Field</td>
                        </tr>
                        <ng-container *ngFor="let item of censusFields;let i = index">
                            <tr style="border: solid;">
                                <td style="border: solid;">
                                    <input property="headerRow" size="5" maxlength="3" title="Assigned Mapping" [disabled]="saveDisabled" [(ngModel)]="censusColumnMapping[i].columnOrder" />
                                </td>
                                <td style="border: solid;">
                                    <ng-container *ngIf="item.required; else notRequired">
                                        {{item.fieldName}}<label class="required">*</label>
                                    </ng-container>
                                    <ng-template #notRequired>
                                        {{item.fieldName}}
                                    </ng-template>
                                </td>
                            </tr>
                        </ng-container>
                        <!-- Action Buttons -->
                        <tr>
                            <td>
                                <button mat-raised-button type="button" color="accent" class="btn btn-outline-dark" (click)="editMapping()">Edit</button>
                            </td>
                            <td>
                                <button mat-raised-button type="button" color="primary" class="btn btn-outline-dark" [disabled]="saveDisabled" (click)="saveMapping()">Save/Update</button>
                            </td>
                        </tr>
                    </table>
                </td>
                <!-- Fields From import File -->
                <td style="width: 50%;vertical-align: top;">
                    <table style="border: solid;">
                        <tr style="border: solid;">
                            <td style="border: solid;">Mapping ID</td>
                            <td style="border: solid;">Import Fields</td>
                        </tr>
                        <ng-container *ngFor="let item of fileHeaders;let i = index">
                            <tr style="border: solid;">
                                <td style="border: solid;">
                                    {{i+1}}
                                </td>
                                <td style="border: solid;">
                                    {{item}}
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                </td>
            </tr>
        </table>
        <!-- Preview Data -->
        <h2 style="text-align: center;">Census Preview</h2>
        <div style="overflow: auto;">
            <table style="border: solid;">
                <tr style="border: solid;">
                    <ng-container *ngFor="let column of censusFields;let i = index">
                        <td style="border: solid;padding: 0px 5px 0px 5px;white-space: nowrap;">{{column.fieldName}}</td>
                    </ng-container>
                </tr>
                <tr style="border: solid;">
                    <ng-container *ngFor="let column of censusFields;let i = index">
                        <ng-container *ngIf="column.fieldType ==='DATE';else notDateField">
                            <td style="border: solid;padding: 0px 5px 0px 5px;white-space: nowrap;">{{formatExcelDate(fileRowPreview[censusColumnMapping[i].columnOrder-1]) | date:'MM/dd/YYYY'}}</td>
                        </ng-container>
                        <ng-template #notDateField>
                            <td style="border: solid;padding: 0px 5px 0px 5px;white-space: nowrap;">{{fileRowPreview[censusColumnMapping[i].columnOrder-1]}}</td>
                        </ng-template>
                    </ng-container>
                </tr>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button mat-raised-button type="button" color="warn" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button mat-raised-button type="button" color="primary" class="btn btn-outline-dark" [disabled]="!saveDisabled" (click)="runImport();">Import Census</button>
    </div>
</ng-template>

<!-- Import Select File Modal -->
<ng-template #importSelectFile let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Census Import File</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <h5>Step 1: Select File</h5>
        <input type="file" style="padding-left: 60px;" (change)="selectFile($event)"/>
        <br>
        <br>
        <h5>Step 2: Enter Header Row #</h5>
        <div style="padding-left: 60px;">
            <input property="headerRow" size="10" maxlength="16" title="Excel Sheet Header Row" [(ngModel)]="headerRow" />
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="checkImportFile();">Continue</button>
    </div>
</ng-template>

<!-- Employer Class Mapping Modal -->
<ng-template #employerClassMappingModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Additional Mapping</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Some fields do not match expected data. Please provide the mappings below.</p>
        <table style="border: solid;">
            <tr style="border: solid;">
                <td style="border: solid;">Type</td>
                <td style="border: solid;">ID From Import</td>
                <td style="border: solid;">Mapped ID</td>
            </tr>
            <ng-container *ngFor="let item of hoursNotMapped;let i = index">
                <tr style="border: solid;">
                    <td style="border: solid;">Hours Class</td>
                    <td style="border: solid;">{{item.idFromEmployer}}</td>
                    <td style="border: solid;">
                        <select (change)="hoursClassMapping($event,item);" name="selectMapping">
                            <option>--Select--</option>
                            <option *ngFor="let hours of hoursToBeMapped" [ngValue]="hours.guid">
                                {{ hours.parameterCode }}
                            </option>
                        </select>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngFor="let item of employmentReasonNotMapped;let i = index">
                <tr style="border: solid;">
                    <td style="border: solid;">Employment Reason</td>
                    <td style="border: solid;">{{item.idFromEmployer}}</td>
                    <td style="border: solid;">
                        <select (change)="employmentReasonClassMapping($event,item);" name="selectMapping">
                            <option>--Select--</option>
                            <option *ngFor="let hours of employmentReasonToBeMapped" [ngValue]="hours.guid">
                                {{ hours.parameterCode }}
                            </option>
                        </select>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button"  color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Cancel</button>
        <button type="button"  color="primary" mat-raised-button class="btn btn-outline-dark" (click)="saveClassMapping()">Save Mappings</button>
    </div>
</ng-template>


<!-- Submitted List Modal -->
<ng-template #submittedListFile let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Previously Submitted Census Files</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="subTbl">
            <table mat-table [dataSource]="submittedList" class="agreementList">


                <!-- LocationID Column -->
                <ng-container matColumnDef="ID">
                    <th mat-header-cell *matHeaderCellDef class="header subList"> Location </th>
                    <td mat-cell *matCellDef="let element" class="subList">
                        {{element.locationID}}
                    </td>
                </ng-container>

                <!-- File Name Column -->
                <ng-container matColumnDef="File Name">
                    <th mat-header-cell *matHeaderCellDef class="header subList"> File Name </th>
                    <td mat-cell *matCellDef="let element" class="subList">
                        {{element.fileName}}
                    </td>
                </ng-container>
        
                <!-- Total Rows Column -->
                <ng-container matColumnDef="Total Rows">
                    <th mat-header-cell *matHeaderCellDef class="header subList"> Rows </th>
                    <td mat-cell *matCellDef="let element; let i = index" class="subList">
                        {{element.totalRows}}
                    </td>
                </ng-container>

                <!-- Total Added To Queue Column -->
                <ng-container matColumnDef="Total Added To Queue">
                    <th mat-header-cell *matHeaderCellDef class="header subList"> Rows Added To Queue </th>
                    <td mat-cell *matCellDef="let element" class="subList">
                        {{element.totalRowsInDemo}}
                    </td>
                </ng-container>
        
                <!-- Total Rows Column -->
                <ng-container matColumnDef="Submitted Date">
                    <th mat-header-cell *matHeaderCellDef class="header subList"> Submitted </th>
                    <td mat-cell *matCellDef="let element; let i = index" class="subList">
                        {{element.createdDate | date:'short'}}
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="submittedListDisplayedColumns; sticky: true" ></tr>
                <tr mat-row *matRowDef="let row; columns: submittedListDisplayedColumns;"></tr>
            </table>
            <!-- <mat-paginator [length]="submittedList.data.length" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"
            (page)="pageEvent = $event" showFirstLastButtons>
            </mat-paginator> -->
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>