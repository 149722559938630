import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../services/auth/auth.service'
import { Request } from '../../../../model/auth/request';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    username: string = '';
	password: string = '';
	verificationCode: string = '';
	qrCode : string = '';
	isSignedin = false;

	toTpVerification = false;

	error: string = '';

	constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {}

	ngOnInit() {
		this.isSignedin = this.authService.isUserSignedin();

		if(this.isSignedin) {
			this.router.navigateByUrl('home');
		}
	}

	doSignin() {
		const pass = document.getElementById("password") as HTMLInputElement;
		if(this.verificationCode == null || this.verificationCode == '' || this.verificationCode == undefined){
			this.password = pass.value;
		}
		if(this.username !== '' && this.username !== null && this.password !== '' && this.password !== null) {
			const request: Request = { 
				userName: this.username, 
				userPwd: this.password,userType: 'W',
				verificationKey: this.verificationCode
			};

			this.authService.signin(request).subscribe((result)=> {
				if (this.authService.getUserMustAcceptTermsOfUse()){this.router.navigate(['/acceptTermsOfUse']);}
				//TODO
				this.toTpVerification = result.result.provideVerifyCode;
				if(this.toTpVerification){
					this.qrCode = result.result.qrCode;
				} else {
					if (this.authService.getUserMustAcceptTermsOfUse()){
						this.router.navigate(['/acceptTermsOfUse']);
					}else{
						this.authService.checkPasswordExpired().subscribe(
							(response: any) => {
							// check if password is expired
							if(response){
								alert("Please change password.");
								this.authService.setPassExp('true');
								this.router.navigateByUrl('changePW');
							}else{
								this.router.navigateByUrl('home');
							}
							},
							(error: HttpErrorResponse) => {
							if(error.status == 200){
								alert(error.error.text);
							}else{
								alert(error.error);
							}
							}
						);
						this.router.navigateByUrl('home');
					}
				}
			},
			(error: HttpErrorResponse) => {
			  this.error = error.error.reason;
			});
		} else {
			this.error = 'Invalid Credentials';
		}
	}

	clearQrCode(){
		this.qrCode = '';
	}
}
