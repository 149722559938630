import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SystemParameters } from 'src/app/model/systemParameters/system-parameters';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemParametersService {

  private baseUrl = environment.apiBaseUrl + '/spring';

  constructor(private http: HttpClient) { }

  // getSystemParameters(): Observable<SystemParameters[]>{
	// 	return this.http.get<any>(this.baseUrl + '/SystemParameter/all',{responseType: 'json'}).pipe(map((resp) => {
	// 		return resp;
	// 	}));
	// }

  getSystemParameterDetails(params: string[]): Observable<SystemParameters[]>{
    return this.http.post<any>(`${this.baseUrl}/SystemParameter/getDetails`, params);
	}
  getHeaderDetails(params: string[]): Observable<SystemParameters[]>{
    return this.http.post<any>(`${this.baseUrl}/SystemParameter/header`, params);
	}
}
