import { FundDetail } from "./fund-detail";

export class EmployeeWorkHistoryFundDetail {
    public adminServiceEnvGUID: number | null;
    public contributionsReported: string | null;
    public daysApplied: string | null;
    public doNotOverrideCalculation: boolean | null;
    public fundDetail: FundDetail | null;
    public hoursAppliedDT: string | null;
    public hoursAppliedOT: string | null;
    public hoursAppliedST: string | null;
    public wagesApplied: string | null;
    public weeksApplied: string | null;

    constructor(adminServiceEnvGUID: number | null,contributionsReported: string | null,daysApplied: string | null,doNotOverrideCalculation: boolean | null,
        fundDetail: FundDetail | null,hoursAppliedDT: string | null,hoursAppliedOT: string | null,hoursAppliedST: string | null,wagesApplied: string | null,weeksApplied: string | null){
            this.adminServiceEnvGUID = adminServiceEnvGUID;
            this.contributionsReported = contributionsReported;
            this.daysApplied = daysApplied;
            this.doNotOverrideCalculation = doNotOverrideCalculation;
            this.fundDetail = fundDetail;
            this.hoursAppliedDT = hoursAppliedDT;
            this.hoursAppliedOT = hoursAppliedOT;
            this.hoursAppliedST = hoursAppliedST;
            this.wagesApplied = wagesApplied;
            this.weeksApplied = weeksApplied;
    }
}
