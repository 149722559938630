import { ErfEmployee } from "../erfEmployee/erf-employee";
import { TrustFund } from "./trust-fund";

export class FundDetail {
    public trustFund: TrustFund | null;
    public employeeWorkHistoryDetail: ErfEmployee | null;

    constructor(trustFund: TrustFund | null,employeeWorkHistoryDetail: ErfEmployee | null){
        this.trustFund = trustFund;
        this.employeeWorkHistoryDetail = employeeWorkHistoryDetail;
    }
}
