import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Employer } from 'src/app/model/userManagement/employer';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-view-list',
  templateUrl: './view-list.component.html',
  styleUrls: ['./view-list.component.scss'],
})
export class ViewListComponent implements OnInit {

  page = 1;

  @ViewChild('createModal') createModal : any;
  @ViewChild('viewModal') viewModal : any;
  @ViewChild('editModal') editModal : any;
  @ViewChild('searchModal') searchModal : any;
  modalOptions: NgbModalOptions;
  closeResult: string = '';

  employerId: any = '';
  employer!: Employer;
  role: any = '';
  private isRoleEmployer: any = 'EmployerUserVO';
  private isRoleEmployerStaff: any = 'EmployerStaffUserVO';
  private isRoleAdmin: any = 'WEBERFADMIN';

  isCreate = false;
  statesList: any[] = [];
  employerProjectsList:any[] = [];
  selectedProj:any;
  selectedSearchProject:any;

  searchProjectList:any[]=[];
  //Project Search Criteria//
  mId:string='';
  mName:string='';
  aaId:string='';
  aaName:string='';
  aaContract:string='';
  genContactor:string='';
  addrLine1:string='';
  city:string='';
  zip:string='';
  ///////////////////////////

  //Create New Project Data//
  createProjectId:any;
  createAA:any;
  createAAC:any;
  createGC:any;
  createContact:any;
  createName:any;
  createAddr1:any;
  createAddr2:any;
  createCity:any;
  createState:any;
  createCountry:any;
  createZip:any;
  createEff:any;
  createExp:any;
  createComment:any;
  ///////////////////////////

  loading$ = this.loader.loading$;
  constructor(
    public loader: SpinnerService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public authService: AuthService,
    public projectService: ProjectService
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      size: 'lg'
    };
  }

  ngOnInit(): void {
    this.getPageParams();
    this.loadData();
  }

  loadData() {
    // Get User Role
    this.role = this.authService.getSignedinUserRole();
    this.getEmployerProjects();
  }

  getEmployerProjects(): void {
    this.projectService.getEmployerProjects(this.employerId).subscribe(
      (response: any) => {
        this.employer = response.result.employer;
        this.employerProjectsList = response.result.employerProjectsList;
        this.statesList = response.result.statesList;
        this.authService.refreshToken().subscribe((result)=> {}, () => {});
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      }
    );
  }
  search(): void {
    this.projectService.retrieveProjects(this.mId,this.mName,this.aaId,this.aaName,this.aaContract,this.genContactor,this.addrLine1,this.city,this.zip).subscribe(
      (response: any) => {
        this.searchProjectList = response.result;
        this.authService.refreshToken().subscribe((result)=> {}, () => {});
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      }
    );
  }
  update(){
    //Only update master project reference
    if(this.selectedSearchProject != null && this.selectedSearchProject != undefined){
      // console.log('EmployerProjectVO GUID: ' + this.selectedProj.guid + '\nWebERFProjectVO GUID: ' + this.selectedSearchProject.guid);
      this.projectService.updateProjectReference(this.selectedProj.guid,this.selectedSearchProject.guid).subscribe(
        (response: any) => {
          this.loadData();
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
    } else {//update actual data within master project since no master project
      this.projectService.updateWebERFEmployerProject(this.selectedProj.guid,this.selectedProj.project.addrLine1,this.selectedProj.project.addrLine2,
        this.selectedProj.project.city,this.selectedProj.project.country,this.selectedProj.project.state,this.selectedProj.project.zip,
        this.selectedProj.project.awardingAgencyContract,this.selectedProj.project.awardingAgencyID,this.selectedProj.project.generalContractor,
        this.selectedProj.project.projectName,this.selectedProj.project.effDate == null ? null :this.dateToString(new Date(this.selectedProj.project.effDate)),this.selectedProj.project.expDate == null ? null : this.dateToString(new Date(this.selectedProj.project.expDate))).subscribe(
        (response: any) => {
          this.loadData();
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
    }
    //reset data
    this.selectedSearchProject = null;
  }
  create(){
    //default master project
    if(this.selectedSearchProject != null && this.selectedSearchProject != undefined){
      this.projectService.createHalfNewProject(this.selectedSearchProject.guid,this.createProjectId,this.createContact,
        this.createEff == null ? null : this.dateToString(new Date(this.createEff)),
        this.createExp == null ? null : this.dateToString(new Date(this.createExp)),
        this.createComment).subscribe(
        (response: any) => {
          this.loadData();
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
    } else {//no master project everything is fresh
      this.projectService.createNewProject(this.createProjectId,this.createAA,this.createAAC,this.createGC,this.createContact,this.createName,
        this.createAddr1,this.createAddr2,this.createCity,this.createState,this.createCountry,this.createZip,
        this.createEff == null ? null : this.dateToString(new Date(this.createEff)),
        this.createExp == null ? null : this.dateToString(new Date(this.createExp)),
        this.createComment).subscribe(
        (response: any) => {
          this.loadData();
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
    }
    this.resetCreate();
  }

  viewEditData(item:any){
    this.selectedProj = item;
    if(item.source == 'TRUSTpartner'){
      this.open(this.viewModal);
    }else{
      this.open(this.editModal);
    }
  }

  openSearch(str:string){
    if(str == 'EDIT'){
      this.isCreate = false;
    }else{
      this.isCreate = true;
    }
    this.modalService.dismissAll();
    this.openXL(this.searchModal);
  }
  open(content: any) {
    console.log('open');
    this.modalService.open(content, this.modalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  openXL(content: any) {
    console.log('open');
    this.modalService.open(content, {backdrop: 'static',
    backdropClass: 'customBackdrop',
    size: 'xl'}).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getPageParams(): void {
    this.employerId = atob(this.route.snapshot.paramMap.get('employerId')!);
  }

  isEmployerOrStaff() {
    if (
      this.role == this.isRoleEmployer ||
      this.role == this.isRoleEmployerStaff
    ) {
      return true;
    } else {
      return false;
    }
  }
  isAdmin() {
    if (this.role == this.isRoleAdmin) {
      return true;
    } else {
      return false;
    }
  }
  clearSearch(){
    this.mId='';
    this.mName='';
    this.aaId='';
    this.aaName='';
    this.aaContract='';
    this.genContactor='';
    this.addrLine1='';
    this.city='';
    this.zip='';
  }
  searchProjectSelect(item:any){
    this.selectedSearchProject = item;
    this.modalService.dismissAll();
    if(this.isCreate){
      this.open(this.createModal);
    }else{
      this.open(this.editModal);
    }
  }
  dateToString(date: any) {
    return (
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2)
    );
  }
  resetCreate(){
    this.createProjectId = null;
    this.createAA = null;
    this.createAAC = null;
    this.createGC = null;
    this.createContact = null;
    this.createName = null;
    this.createAddr1 = null;
    this.createAddr2 = null;
    this.createCity = null;
    this.createState = null;
    this.createCountry = null;
    this.createZip = null;
    this.createEff = null;
    this.createExp = null;
    this.createComment = null;
  }
  statesCanList:any = [
    {parameterCode:'AB',parameterDesc:'Alberta'},
    {parameterCode:'BC',parameterDesc:'British Columbia'},
    {parameterCode:'MB',parameterDesc:'Manitoba'},
    {parameterCode:'NB',parameterDesc:'New Brunswick'},
    {parameterCode:'NL',parameterDesc:'Newfoundland and Labrador'},
    {parameterCode:'NT',parameterDesc:'Northwest Territories'},
    {parameterCode:'NS',parameterDesc:'Nova Scotia'},
    {parameterCode:'NU',parameterDesc:'Nunavut'},
    {parameterCode:'ON',parameterDesc:'Ontario'},
    {parameterCode:'PE',parameterDesc:'Prince Edward Island'},
    {parameterCode:'SK',parameterDesc:'Saskatchewan'},
    {parameterCode:'YT',parameterDesc:'Yukon'}
  ];
}
