import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  private baseUrl = environment.apiBaseUrl + '/spring';

  constructor(private http: HttpClient) { }

  deleteEmployerImportMapping(importtypeguid:any,employer:any,agreement:any): Observable<any> {
    let Params = new HttpParams();
    Params = Params.append('employer', employer.guid);
    Params = Params.append('agreement', agreement.masterAgreementGUID);
    Params = Params.append('importtypeguid', importtypeguid);
    
    return this.http.get(`${this.baseUrl}/Import/deleteEmployerImportMapping`,{params: Params});
  }
  retrieveEmployerCensusMapping(employer:any, payroll:any): Observable<any> {
    let Params = new HttpParams();
    Params = Params.append('employer', employer);
    Params = Params.append('payroll', payroll);
    return this.http.get(`${this.baseUrl}/Import/retrieveEmployerCensusMapping`,{params: Params});
  }
  getHeaders(file: File,agreement:any,employer:any,headerRowNumber:any, employerRptGUID:any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    let Params = new HttpParams();
    Params = Params.append('employer', employer.guid);
    Params = Params.append('agreement', agreement.masterAgreementGUID);
    Params = Params.append('headerRowNumber', headerRowNumber);
    Params = Params.append('employerRptGUID', employerRptGUID);
    
    return this.http.post(`${this.baseUrl}/Import/getHeaders`,formData,{reportProgress: true,responseType: 'json',params: Params});
  }
  getImportMapping(er: any,pmt:any): Observable<any>{
    return this.http.post(`${this.baseUrl}/Import/getImportMapping`,{employerReport:er,erfPayment:pmt});
  }

  saveNewMapping(newMappingName:any, agreementColumnsSortOrder:any, agreementColumns:any,employerGUID:any,agreementGUID:any, employerRptGUID:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('employerRptGUID', employerRptGUID);
    return this.http.post(`${this.baseUrl}/Import/saveNewMapping`,{newMappingName:newMappingName,agreementColumnsSortOrder:agreementColumnsSortOrder,agreementColumns:agreementColumns,employerGUID:employerGUID,agreementGUID:agreementGUID},{responseType: 'json',params: Params});
  }
  setUpImportReport(file: File,agreement:any,employer:any,headerRowNumber:any,importType:any, employerRptGUID:any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    let Params = new HttpParams();
    Params = Params.append('employer', employer.guid);
    Params = Params.append('agreement', agreement.masterAgreementGUID);
    Params = Params.append('headerRowNumber', headerRowNumber);
    Params = Params.append('importType', importType);
    Params = Params.append('employerRptGUID', employerRptGUID);
    
    return this.http.post(`${this.baseUrl}/Import/setUpImportReport`,formData,{reportProgress: true,responseType: 'json',params: Params});
  }
  importUpdateEmployerReport(file: File,agreement:any,employer:any,headerRowNumber:any,importType:any,erfAgreement:any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    let Params = new HttpParams();
    Params = Params.append('employer', employer.guid);
    Params = Params.append('agreement', agreement.masterAgreementGUID);
    Params = Params.append('headerRowNumber', headerRowNumber);
    Params = Params.append('importType', importType);
    Params = Params.append('erfAgreement', erfAgreement);
    
    return this.http.post(`${this.baseUrl}/Import/importUpdateEmployerReport`,formData,{reportProgress: true,responseType: 'json',params: Params});
  }
  checkClassMapping(file: File,agreement:any,employer:any,headerRowNumber:any,importType:any,erfAgreement:any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    let Params = new HttpParams();
    Params = Params.append('employer', employer.guid);
    Params = Params.append('agreement', agreement.masterAgreementGUID);
    Params = Params.append('headerRowNumber', headerRowNumber);
    Params = Params.append('importType', importType);
    Params = Params.append('erfAgreement', erfAgreement);
    
    return this.http.post(`${this.baseUrl}/Import/checkClassMapping`,formData,{reportProgress: true,responseType: 'json',params: Params});
  }
  saveClassMapping(hoursNotMapped:any,employmentReasonNotMapped:any,jobsNotMapped:any): Observable<any>{
    return this.http.post(`${this.baseUrl}/Import/saveClassMapping`,{hoursNotMapped:hoursNotMapped,employmentReasonNotMapped:employmentReasonNotMapped,jobsNotMapped:jobsNotMapped});
  }
  getEmployerCensusData(guid:any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('guid', guid);
    return this.http.get<any>(`${this.baseUrl}/Import/employerCensus`,{ params: Params });
	}

  getCensusImportData(file: File,location:any,employer:any,headerRowNumber:any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    let Params = new HttpParams();
    Params = Params.append('employer', employer.guid);
    Params = Params.append('location', location);
    Params = Params.append('headerRowNumber', headerRowNumber);
    
    return this.http.post(`${this.baseUrl}/Import/getCensusImportData`,formData,{reportProgress: true,responseType: 'json',params: Params});
  }
  saveCensusMapping(map:any){
    return this.http.post<any>(`${this.baseUrl}/Import/saveCensusMapping`,map);
  }
  runCensusImportData(file: File,location:any,employer:any,headerRowNumber:any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    let Params = new HttpParams();
    Params = Params.append('employer', employer.guid);
    Params = Params.append('location', location);
    Params = Params.append('headerRowNumber', headerRowNumber);
    
    return this.http.post(`${this.baseUrl}/Import/runCensusImportData`,formData,{reportProgress: true,responseType: 'json',params: Params});
  }
  checkCensusClassMapping(file: File,employer:any,firstDataRow:any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    let Params = new HttpParams();
    Params = Params.append('employer', employer.guid);
    Params = Params.append('firstDataRow', firstDataRow);    
    return this.http.post(`${this.baseUrl}/Import/checkCensusClassMapping`,formData,{reportProgress: true,responseType: 'json',params: Params});
  }

  runMassImport(selectedfiles:FileList,id:any,date:any){
    const formData: FormData=new FormData();
    for(let i=0;i<selectedfiles.length;i++){
      formData.append('selectedfiles', selectedfiles[i]);
    }
    formData.append('id', id);
    let Params = new HttpParams();
    Params = Params.append('byPayDate', date);
    // formData.append('byPayDate', date);
    return this.http.post(`${this.baseUrl}/Import/runMassImport`,formData,{params: Params});
  }
}
