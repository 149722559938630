import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Announcement } from 'src/app/model/announcement/announcement';
import { SystemParameters } from 'src/app/model/systemParameters/system-parameters';
import { Employer } from 'src/app/model/userManagement/employer';
import { UserManagement } from 'src/app/model/userManagement/user-management';
import { AnnouncementService } from 'src/app/services/announcement/announcement.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { SystemParametersService } from 'src/app/services/systemParameter/system-parameters.service';
import { UserManagementService } from 'src/app/services/userManagement/user-management.service';
import { SystemParameterConstants } from '../../../model/constants/system-parameter-constants';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  systemParameters: SystemParameters[] = [];
  announcements: Announcement[] = [];
  role: any = ''; 
  employer!: Employer;
  private isRoleEmployer: any = "EmployerUserVO";
  private isRoleEmployerStaff: any = "EmployerStaffUserVO";
  private isRoleLiaison: any = "WEBERFLIAISON";
  private isRoleAdmin: any = "WEBERFADMIN";
  private isRoleWebMaster: any = "WEBERFWEBMASTER";
  private isRoleAuditor: any = "WEBERFAUDITOR";
  params: any = [
    SystemParameterConstants.TRUST_NAME,
    SystemParameterConstants.ER_MAINPAGE_HTML,
    SystemParameterConstants.ADMINREPORTS,
    SystemParameterConstants.EMPLOYER_CAN_VIEW_RATES,
    SystemParameterConstants.ER_PROJECT_MAINT,
    SystemParameterConstants.EMPLOYER_CAN_MANAGE_STAFF,
    SystemParameterConstants.CHANGE_PW_AFTER_LOGIN,
    SystemParameterConstants.EFT_ACTIVATED,
    SystemParameterConstants.DEMOGRAPHIC_Q_ENABLE
  ];

  loading$ = this.loader.loading$;
  constructor(public authService: AuthService, private systemParameterService: SystemParametersService,
    private announcementService: AnnouncementService, private userManagementService: UserManagementService,public loader: SpinnerService) {}

  ngOnInit(): void {
    this.loadData();
  }
  loadData() {
    // Get User Role
    this.role = this.authService.getSignedinUserRole();
    //Get Employer Details & Get Announcements & SystemParameters
    this.getUserInfo();
  }
  getUserInfo(): void {
    // if(this.isEmployerOrStaff()){
      this.userManagementService
      .getUserInfo()
      .subscribe(
        (response: any) => {
          this.employer = response.result.employer;
          this.announcements = response.result.announcements;
          this.systemParameterService
            .getSystemParameterDetails(this.params)
            .subscribe(
              (response: any) => {
                this.systemParameters = response.result;
                this.authService.refreshToken().subscribe((result)=> {}, () => {});
              },
              (error: HttpErrorResponse) => {
                alert(error.error.reason);
              }
            );
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
    // }else{
    //   console.log("getUserInfo() is not Employer");
    // }
  }


  isAffirmative(s: string) {
    if(s != null && (s.toUpperCase().match('Y') || s.toUpperCase().match('YES') || s.match("1"))){
      return true;
    } else{
      return false;
    }
	}

  // CHECK FOR USER ROLE
  isEmployer(){
    if(this.role == this.isRoleEmployer){
      return true;
    } else {
      return false;
    }
  }
  isEmployerOrStaff(){
    if(this.role == this.isRoleEmployer || this.role == this.isRoleEmployerStaff){
      return true;
    } else {
      return false;
    }
  }
  isAdmin(){
    if(this.role == this.isRoleAdmin){
      return true;
    } else {
      return false;
    }
  }
  isAuditor(){
    if(this.role == this.isRoleAuditor){
      return true;
    } else {
      return false;
    }
  }
  isWebMaster(){
    if(this.role == this.isRoleWebMaster){
      return true;
    } else {
      return false;
    }
  }
  isLiaison(){
    if(this.role == this.isRoleLiaison){
      return true;
    } else {
      return false;
    }
  }
}
