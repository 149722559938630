import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { ProcessesService } from 'src/app/services/webmaster/processes.service';

@Component({
  selector: 'app-accept-terms-of-use',
  templateUrl: './accept-terms-of-use.component.html',
  styleUrls: ['./accept-terms-of-use.component.scss']
})
export class AcceptTermsOfUseComponent implements OnInit {

  termsObject:any;

  loading$ = this.loader.loading$;
  constructor(public loader: SpinnerService, public authService: AuthService, private processesService: ProcessesService, private router: Router) {   }

  ngOnInit(): void {
    this.loadData();
  }


  loadData(){
    this.processesService
      .getActiveTermsOfUse()
      .subscribe(
        (response: any) => {
          this.termsObject = response.result;
          this.authService.refreshToken().subscribe((result)=> {}, () => {});
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      });
  }

  agree(){
    this.authService
      .agreeToTermsOfUse(this.termsObject.guid)
      .subscribe(
        (response: any) => {
					this.authService.checkPasswordExpired().subscribe(
						(response: any) => {
              this.authService.setUserMustAcceptTermsOfUse(false);
						  // check if password is expired
						  if(response){
							  alert("Please change password.");
                this.authService.setPassExp('true');
							  this.router.navigateByUrl('changePW');
						  }else{
							  this.router.navigateByUrl('home');
						  }
						},
						(error: HttpErrorResponse) => {
						  if(error.status == 200){
							alert(error.error.text);
						  }else{
							alert(error.error);
						  }
						}
					  );
					this.router.navigateByUrl('home');
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      });
  }

}
