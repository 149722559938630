<body>
    <span class="pageHeader">System Parameters</span>
    <div class="entryform">
        <!-- <table>
            <tr class="defaults">
                <th></th>
                <th class="column info">ASE</th>
                <th class="column info">Parameter Code</th>
                <th class="column info">Parameter Value</th>
                <th class="column info">Description</th>
            </tr>
            <tr *ngFor="let sys of systemParameters">
                <ng-container *ngIf="sys.adminServiceEnvGUID != 1;else editable">
                    <td>
                        <button mat-icon-button color="primary" title="Edit system parameter">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-template #editable>
                    <td></td>
                </ng-template>
                <td class="info rowData">{{sys.adminServiceEnvGUID}}</td>
                <td class="info rowData">{{sys.parameter_CODE}}</td>
                <td>
                    <input [disabled]="true" [(ngModel)]="sys.parameter_VALUE">
                </td>
                <td class="info rowData">{{sys.parameter_DESC}}</td>
            </tr>
        </table> -->
    </div>

    <!-- <ng-container>
        <h3>tClientImage Add Logo</h3>
        <label class="btn btn-default">
            <input type="file" (change)="selectFile($event)"/>
        </label>
        <button class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="upload()">
            Upload file
        </button>
    </ng-container> -->
</body>