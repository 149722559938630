import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ssn',
  pure: false
})
export class SsnPipe implements PipeTransform {

  transform(value: string): string {
    if(value == null || value == undefined) return '';
    if (value.includes('-')) return value;

    var fSSN = '';
    fSSN += value.substr(0, 3);
    fSSN += '-' + value.substr(3, 2);
    fSSN += '-' + value.substr(5, 4);
    console.log(value);
    console.log(fSSN);
    return fSSN;
  }

}
