import { FundItems } from "./fund-items";

export class FundDetails {
    public fund0 : FundItems;
    public fund1 : FundItems;
    public fund2 : FundItems;
    public fund3 : FundItems;
    public fund4 : FundItems;
    public fund5 : FundItems;
    public fund6 : FundItems;
    public fund7 : FundItems;
    public fund8 : FundItems;
    public fund9 : FundItems;
    public fund10 : FundItems;
    public fund11 : FundItems;
    public fund12 : FundItems;
    public fund13 : FundItems;
    public fund14 : FundItems;
    public fund15 : FundItems;
    public fund16 : FundItems;
    public fund17 : FundItems;
    public fund18 : FundItems;
    public fund19 : FundItems;
    public fund20 : FundItems;
    public fund21 : FundItems;
    public fund22 : FundItems;
    public fund23 : FundItems;
    public fund24 : FundItems;
    public fund25 : FundItems;
    public fund26 : FundItems;
    public fund27 : FundItems;
    public fund28 : FundItems;
    public fund29 : FundItems;
    public fund30 : FundItems;
    public fund31 : FundItems;
    public fund32 : FundItems;
    public fund33 : FundItems;
    public fund34 : FundItems;
    public fund35 : FundItems;
    public fund36 : FundItems;
    public fund37 : FundItems;
    public fund38 : FundItems;
    public fund39 : FundItems;

    constructor(){
        this.fund0 = new FundItems(null,null,null);
        this.fund1 = new FundItems(null,null,null);
        this.fund2 = new FundItems(null,null,null);
        this.fund3 = new FundItems(null,null,null);
        this.fund4 = new FundItems(null,null,null);
        this.fund5 = new FundItems(null,null,null);
        this.fund6 = new FundItems(null,null,null);
        this.fund7 = new FundItems(null,null,null);
        this.fund8 = new FundItems(null,null,null);
        this.fund9 = new FundItems(null,null,null);
        this.fund10 = new FundItems(null,null,null);
        this.fund11 = new FundItems(null,null,null);
        this.fund12 = new FundItems(null,null,null);
        this.fund13 = new FundItems(null,null,null);
        this.fund14 = new FundItems(null,null,null);
        this.fund15 = new FundItems(null,null,null);
        this.fund16 = new FundItems(null,null,null);
        this.fund17 = new FundItems(null,null,null);
        this.fund18 = new FundItems(null,null,null);
        this.fund19 = new FundItems(null,null,null);
        this.fund20 = new FundItems(null,null,null);
        this.fund21 = new FundItems(null,null,null);
        this.fund22 = new FundItems(null,null,null);
        this.fund23 = new FundItems(null,null,null);
        this.fund24 = new FundItems(null,null,null);
        this.fund25 = new FundItems(null,null,null);
        this.fund26 = new FundItems(null,null,null);
        this.fund27 = new FundItems(null,null,null);
        this.fund28 = new FundItems(null,null,null);
        this.fund29 = new FundItems(null,null,null);
        this.fund30 = new FundItems(null,null,null);
        this.fund31 = new FundItems(null,null,null);
        this.fund32 = new FundItems(null,null,null);
        this.fund33 = new FundItems(null,null,null);
        this.fund34 = new FundItems(null,null,null);
        this.fund35 = new FundItems(null,null,null);
        this.fund36 = new FundItems(null,null,null);
        this.fund37 = new FundItems(null,null,null);
        this.fund38 = new FundItems(null,null,null);
        this.fund39 = new FundItems(null,null,null);
    }
}
