import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SystemParameterConstants } from 'src/app/model/constants/system-parameter-constants';
import { SystemParameters } from 'src/app/model/systemParameters/system-parameters';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { SystemParametersService } from 'src/app/services/systemParameter/system-parameters.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  systemParameters: SystemParameters[] = [];

  params: any = [
    SystemParameterConstants.TRUST_NAME,
    SystemParameterConstants.TRUST_ADDRESS1,
    SystemParameterConstants.TRUST_ADDRESS2,
    SystemParameterConstants.TRUST_CONTACT,
    SystemParameterConstants.TRUST_DEPT,
    SystemParameterConstants.TRUST_EMAIL_ADDRESS,
    SystemParameterConstants.TRUST_FAX,
    SystemParameterConstants.TRUST_PHONE1,
    SystemParameterConstants.TRUST_PHONE2,
    SystemParameterConstants.TRUST_PHONE_TOLLFREE,
    SystemParameterConstants.CUSTOM_CONTACT_INCLUDE,
    SystemParameterConstants.PLAN_ADMINISTRATOR
  ];

  loading$ = this.loader.loading$;
  constructor(public authService: AuthService, private systemParameterService: SystemParametersService, public loader: SpinnerService) { 

  }

  ngOnInit(): void {
    this.contactInfo();
  }

  contactInfo(){
    this.systemParameterService
      .getSystemParameterDetails(this.params)
      .subscribe(
        (response: any) => {
          this.systemParameters = response.result;
          this.authService.refreshToken().subscribe((result)=> {}, () => {});
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
  }


}
