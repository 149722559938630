import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { DateString } from 'src/app/model/date-string';
import { SystemParameters } from 'src/app/model/systemParameters/system-parameters';
import { Employer } from 'src/app/model/userManagement/employer';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EmployerService } from 'src/app/services/employer/employer.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-special-mappings',
  templateUrl: './special-mappings.component.html',
  styleUrls: ['./special-mappings.component.scss']
})
export class SpecialMappingsComponent implements OnInit {

  role: any = '';
  employer!: Employer;
  employerId:any = '';
  private isRoleEmployer: any = 'EmployerUserVO';
  private isRoleEmployerStaff: any = 'EmployerStaffUserVO';
  specialMappings = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['type', 'idFromEmployer', 'idFinder.parameterCode','action'];
  @ViewChild(MatPaginator)
  private paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 30, 40, 50];
  // MatPaginator Output
  pageEvent!: PageEvent;

  loading$ = this.loader.loading$;
  constructor(
    public loader: SpinnerService,private route: ActivatedRoute,
    public authService: AuthService,private employerService: EmployerService) { }

  ngAfterViewInit() {
    this.specialMappings.paginator = this.paginator;
    this.specialMappings.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'idFinder.parameterCode': return item.idFinder.parameterCode;
        case 'type' : return item.idFinder.mid;
        default: return item[property];
      }
    };
    this.specialMappings.sort = this.sort;
  }
  ngOnInit(): void {
    this.getPageParams();
    this.loadData();
  }
  getPageParams():void{
    this.employerId = atob(this.route.snapshot.paramMap.get('employerId')!);
  }
  loadData() {
    // Get User Role
    this.role = this.authService.getSignedinUserRole();
    this.getAllEmployerClassMappings();
  }
  getAllEmployerClassMappings(){
    this.employerService
    .getAllEmployerClassMappings(this.employerId)
    .subscribe(
      (response: any) => {
        this.employer = response.employer;
        this.specialMappings.data = response.ecmList;
        this.authService.refreshToken().subscribe((result)=> {}, () => {});
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      }
    );
  }
  deleteSingleEmployerClassMapping(data:any){
    if(confirm("Delete this special import mapping?")){
      this.employerService
      .deleteSingleEmployerClassMapping(data)
      .subscribe((response:any) => {
        // console.log(response);
        this.getAllEmployerClassMappings();
      },
      (error: HttpErrorResponse) => {
        alert(error.error.message);
      });
    }
  }
  whatType(data:any){
    if(data.idFinder.mid.includes('HoursClassVO')){
      return 'Hours Class';
    } else if(data.idFinder.mid.includes('EmploymentStatusReasonVO')){
      return 'Employment Reason';
    } else if(data.idFinder.mid.includes('JobClassVO')){
      return 'Job Class';
    }else {
      return 'Unknown Contact Support';
    }

  }

  isEmployerOrStaff() {
    if (
      this.role == this.isRoleEmployer ||
      this.role == this.isRoleEmployerStaff
    ) {
      return true;
    } else {
      return false;
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.specialMappings.filter = filterValue.trim().toLowerCase();

    if (this.specialMappings.paginator) {
      this.specialMappings.paginator.firstPage();
    }
  }

}
