import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Employer } from 'src/app/model/userManagement/employer';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EmployerService } from 'src/app/services/employer/employer.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-employer-eft-account-details',
  templateUrl: './employer-eft-account-details.component.html',
  styleUrls: ['./employer-eft-account-details.component.scss']
})
export class EmployerEftAccountDetailsComponent implements OnInit {

  closeResult: string = '';
  private isRoleEmployer: any = 'EmployerUserVO';
  private isRoleEmployerStaff: any = 'EmployerStaffUserVO';
  role: any = '';
  @ViewChild('alertModal') alertModal : any;

  eftAccountDetailsForm: any;
  isEdit = false;


  loading$ = this.loader.loading$;
  constructor(public loader: SpinnerService,public authService: AuthService,private employerService: EmployerService,private modalService: NgbModal) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    // Get User Role
    this.role = this.authService.getSignedinUserRole();
    this.getEmployerEftAccountDetails();
  }
  public getEmployerEftAccountDetails(): void {
    this.employerService.getEmployerEftAccountDetails().subscribe(
      (response: any) => {
        this.eftAccountDetailsForm = response;
        this.authService.refreshToken().subscribe(
          (result) => {},
          () => {}
        );
        this.role = this.authService.getSignedinUserRole();
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      }
    );
  }
  save(){
    this.employerService.saveEmployerEftAccountDetails(this.eftAccountDetailsForm).subscribe(
      (response: any) => {
        this.eftAccountDetailsForm = response.result;
        this.isEdit = false;
        this.authService.refreshToken().subscribe(
          (result) => {},
          () => {}
        );
        this.role = this.authService.getSignedinUserRole();
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      }
    );
  }
  remove(){
    this.employerService.removeEmployerEftAccountDetails(this.eftAccountDetailsForm).subscribe(
      (response: any) => {
        this.eftAccountDetailsForm = response.result;
        this.isEdit = false;
        this.authService.refreshToken().subscribe(
          (result) => {},
          () => {}
        );
        this.role = this.authService.getSignedinUserRole();
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      }
    );
  }
  edit(){
    this.isEdit = true;
    this.eftAccountDetailsForm.accountNumber = '';
  }
  validation(){
    var bankr = this.eftAccountDetailsForm.routingNumber;
    var banka = this.eftAccountDetailsForm.accountNumber;

    if(bankr == "" && banka != "" ) {
      alert("Enter a Routing Number or remove Account Number");
      return;
    }

    if(bankr != "" && banka == "" ) {
      alert("Enter an Account Number or remove Routing Number");
      return;
    }

    if(isNaN(bankr)) {
      alert("Routing Number must be numeric");
      return;
    }

    if(isNaN(banka)) {
      alert("Account Number must be numeric");
      return;
    }
    this.openPdf(this.alertModal);
  }

  isEmployerOrStaff() {
    if (
      this.role == this.isRoleEmployer ||
      this.role == this.isRoleEmployerStaff
    ) {
      return true;
    } else {
      return false;
    }
  }
  openPdf(content: any) {
    console.log('open');
    this.modalService.open(content, {backdrop: 'static',backdropClass: 'customBackdrop',size:'xl'}).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
