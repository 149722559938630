export class Announcement {
  public guid: number | null;
  public adminServiceEnvGUID: number | null;
  public message: string | null;
  public header: string | null;
  public url: string | null;
  public footer: string | null;
  public urlTitle: string | null;
  public effDate: Date | null;
  public expDate: Date | null;
  public active: boolean | null;
  public preview: boolean | null;
  public mid: string | null;
  public obsolete: boolean | null;
  public updateRequired: boolean | null;

  constructor(){
    this.guid = null;
    this.adminServiceEnvGUID = null;
    this.message = null;
    this.header = null;
    this.url = null;
    this.footer = null;
    this.urlTitle = null;
    this.effDate = null;
    this.expDate = null;
    this.active = null;
    this.preview = null;
    this.mid = null;
    this.obsolete = false;
    this.updateRequired = null;
  }
}
