<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>
<body>    
    <h3 *ngIf="employer">
        <ng-container *ngIf="isEmployerOrStaff()">
            {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
        <ng-container *ngIf="!isEmployerOrStaff()">
            System Administrator on behalf of {{employer.organizationName}} : {{employer.organizationID}}
        </ng-container>
    </h3>
    <span class="pageHeader">Special Import Mappings</span>

    <div>
        <mat-form-field appearance="standard">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Hours Class" #input>
          </mat-form-field>
        <table mat-table [dataSource]="specialMappings" class="mat-elevation-z8" matSort>
            <!-- Type Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="mainColumns"> Type </th>
              <td mat-cell class="mainColumns" *matCellDef="let element"> {{whatType(element)}} </td>
            </ng-container>
          
            <!-- Name From Employer Column -->
            <ng-container matColumnDef="idFromEmployer">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="mainColumns"> Name From Employer </th>
              <td mat-cell class="mainColumns" *matCellDef="let element"> {{element.idFromEmployer}} </td>
            </ng-container>
          
            <!-- Real Value Column -->
            <ng-container matColumnDef="idFinder.parameterCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="mainColumns"> Real Value </th>
              <td mat-cell class="mainColumns" *matCellDef="let element"> {{element.idFinder.parameterCode}} --> {{element.idFinder.parameterDesc}} </td>
            </ng-container>
    
            <!-- action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="deleteColumns"> Delete </th>
                <td mat-cell class="deleteColumns" *matCellDef="let element"> 
                    <button mat-icon-button color="warn" title="Delete special mapping used for import processes" (click)="deleteSingleEmployerClassMapping(element)">
                        <mat-icon>delete</mat-icon>
                    </button>    
                </td>
              </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [length]="specialMappings.data.length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent = $event" aria-label="Select page">
        </mat-paginator>
    </div>
</body>