import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs';
import { Employer } from 'src/app/model/userManagement/employer';
import { AdministrativeReportsService } from 'src/app/services/admininstrative-reports/administrative-reports.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EmployerService } from 'src/app/services/employer/employer.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-administrative-reports',
  templateUrl: './administrative-reports.component.html',
  styleUrls: ['./administrative-reports.component.scss'],
  providers: [DatePipe]
})
export class AdministrativeReportsComponent implements OnInit {
  type: string = '';
  subType: string = '';
  employer!: Employer;
  yearList: any = [];
  role: any = '';

  administrativeReportsForm: any;
  employeeDate: any = '';

  //Employer Date selector
  reportDate = new Date();
  // Toggles
  hours: any;
  wages: any;
  contrib: any;
  contribRate: any;
  agreement: any;
  jobClass: any;
  subByCalQtr: any;
  ttls: any;

  closeResult: string = '';
  fileURL: any;
  @ViewChild('pdfModal') pdfModal: any;

  loading$ = this.loader.loading$;
  constructor(
    public loader: SpinnerService,
    private employerService: EmployerService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private administrativeReportsService: AdministrativeReportsService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    public datepipe: DatePipe
  ) {
    //This will allow the parameters to reload
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  ngOnInit(): void {
    this.getPageParams();
  }
  getPageParams(): void {
    this.type = atob(this.route.snapshot.paramMap.get('type')!);
    this.subType = atob(this.route.snapshot.paramMap.get('sub')!);
    if (this.type === 'Employer') {
      this.getEmployer();
    }
  }

  public getEmployer(): void {
    this.employerService.getEmployer().subscribe(
      (response: any) => {
        this.administrativeReportsForm = response;
        this.employer = response.employer;
        this.yearList = response.years;
        this.employeeDate = this.yearList[0];
        this.authService.refreshToken().subscribe(
          (result) => {},
          () => {}
        );
        this.role = this.authService.getSignedinUserRole();
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      }
    );
  }
  checkdate() {
    console.log(this.employeeDate);
  }

  generate() {
    this.administrativeReportsForm.page = this.subType;
    this.administrativeReportsForm.generate = "Generate";
    this.administrativeReportsForm.sortOrder = "erName";
    if (this.subType === 'employerContribLedger') {
      this.administrativeReportsForm.toDate = this.datepipe.transform(this.reportDate, 'MM/dd/yyyy');
      if(this.agreement){
        this.administrativeReportsForm.agreement = "on";
      }
      if(this.contrib){
        this.administrativeReportsForm.contrib = "on";
      }
      if(this.contribRate){
        this.administrativeReportsForm.contribRate = "on";
      }
      if(this.hours){
        this.administrativeReportsForm.hours = "on";
      }
      if(this.jobClass){
        this.administrativeReportsForm.jobClass = "on";
      }
      if(this.wages){
        this.administrativeReportsForm.wages = "on";
      }
    } else if(this.subType == 'employeeContribLedger'){
      this.administrativeReportsForm.year = this.employeeDate;
      if(this.contrib){
        this.administrativeReportsForm.contrib = "on";
      }
      if(this.hours){
        this.administrativeReportsForm.hours = "on";
      }
      if(this.wages){
        this.administrativeReportsForm.wages = "on";
      }
      if(this.subByCalQtr){
        this.administrativeReportsForm.subByCalQtr = "on";
      }
      if(this.ttls){
        this.administrativeReportsForm.ttlsOnly = "on";
      }
    }

    this.administrativeReportsService
      .generateReport(this.administrativeReportsForm)
      .pipe(take(1))
      .subscribe((response: any) => {
        this.modalService.dismissAll();
        let file = new Blob([response], { type: 'application/pdf' });
        this.fileURL = URL.createObjectURL(file);
        this.openPdf(this.pdfModal);
        this.authService.refreshToken().subscribe(
          (result) => {},
          () => {}
        );
      });
  }
  openPdf(content: any) {
    console.log('open');
    this.modalService
      .open(content, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
        size: 'xl',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  pdfURL(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.fileURL);
  }
}
