import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError, finalize } from 'rxjs';
import { SpinnerService } from '../spinner/spinner.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  // Every call to the backend API will show the isLoading Component
  //  This will make it so the user cant interact with the page
  //  while the API is grabbing data

  totalRequests = 0;
  requestsCompleted = 0;
  constructor(
    private authService: AuthService,
    private loader: SpinnerService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.authService.isUserSignedin() && this.authService.getToken()) {
      const request = req.clone({
        headers: new HttpHeaders({
          Authorization: this.authService.getToken(),
        }),
      });
      this.loader.show();
      this.totalRequests++;
      return next.handle(request).pipe(
        finalize(() => {
          this.requestsCompleted++;

          console.log(this.requestsCompleted, this.totalRequests);

          if (this.requestsCompleted === this.totalRequests) {
            this.loader.hide();
            this.totalRequests = 0;
            this.requestsCompleted = 0;
          }
          if (this.authService.getUserMustAcceptTermsOfUse()){
            this.router.navigate(['/acceptTermsOfUse']);
          }
          else if (this.authService.getPassExp()){
            this.router.navigate(['/changePW']);
          }
        }),
        catchError((err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.authService.signout();
          }
          return throwError(err);
        })
      );
    }
    return next.handle(req);
  }
}
