import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalOptions, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SystemParameterConstants } from 'src/app/model/constants/system-parameter-constants';
import { SystemParameters } from 'src/app/model/systemParameters/system-parameters';
import { Employer } from 'src/app/model/userManagement/employer';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SystemParametersService } from 'src/app/services/systemParameter/system-parameters.service';
import { UserManagementService } from 'src/app/services/userManagement/user-management.service';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import { FormControl } from '@angular/forms';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { EmployerReportService } from 'src/app/services/employerReport/employer-report.service';
import { ImportService } from 'src/app/services/import/import.service';
import { take } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver'; 
import { DateString } from 'src/app/model/date-string';
const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
// export const MY_FORMATS = {
//   parse: {
//     dateInput: 'MM/YYYY',
//   },
//   display: {
//     dateInput: 'MM/YYYY',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY',
//   },
// };
export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM D,YYYY',
  },
  display: {
    dateInput: 'MMM D,YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-report-options',
  templateUrl: './report-options.component.html',
  styleUrls: ['./report-options.component.scss'],
  providers: [DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},],
})
export class ReportOptionsComponent implements OnInit {
  employerId:any = '';
  modalOptions: NgbModalOptions;
  closeResult: string = '';
  @ViewChild('nchModal') nchModal : any;
  @ViewChild('selectPreviousModal') selectPreviousModal : any;
  @ViewChild('importSelectFile') importSelectFile : any;
  @ViewChild('importSetup') importSetup : any;
  @ViewChild('byPayPeriodModal') byPayPeriodModal : any;
  @ViewChild('pdfModal') pdfModal : any;
  @ViewChild('excelModal') excelModal : any;
  
  
  fileURL:any;
  agreementId:any;
  locationId:any;
  displayedColumns: string[] = ['No.', 'NCH', 'Status', 'Agreement'];
  displayedColumnsIsVeba: string[] = ['No.', 'NCH', 'Status', 'Agreement','Statement'];
  systemParameters: SystemParameters[] = [];
  agreementList: any[] = [];
  role: any = '';
  employer!: Employer;
  private isRoleEmployer: any = 'EmployerUserVO';
  private isRoleEmployerStaff: any = 'EmployerStaffUserVO';
  private isRoleAdmin: any = "WEBERFADMIN";
  private isRoleAuditor: any = "WEBERFAUDITOR";
  params: any = [SystemParameterConstants.REPORT_SHOW_DAY, SystemParameterConstants.REPORT_VERIFY_WORK_PERIOD];
  erfSortOrder: any[] = [];
  sort1: number = 0;
  sort2: number = 0;
  sort3: number = 0;
  dataSourceList: any = [];
  erfTypeList: any = [];
  reportType: number = 0;
  reportSource: number = 0;
  finalizedReports: any[] = [];
  @ViewChild(MatTable) table!: MatTable<any>;

  tmpListRpts: any[]=[];

  //DATE
  statementDate = new DateString();
  reportDate = new DateString();
  date = new FormControl(moment());
  minDate = new DateString();
  maxDate = new DateString();

  nchData:any;
  selectPreviousData:any = [];
  selectPrevious:any = null;
  contributionReportID:any;

  payrollCompanySelectedEmployer:any=null;

  //For Import TODO: condense into a model
  selectedFiles?: FileList;
  currentFile?: File;
  headerRow: any = 1;
  importReady = false;
  importType : any[] = [];
  agreementColumns:any;
  agreementColumnsSortOrder:any;
  fileImportFormat: any;
  fileColumnsPreview:any[] = [];
  importAgreementDetails:any;
  newMappingName:any;
  allExistingMappings:any;
  importNamesList:any;
  defaultMapping:any = null;
  importedEmployees:any[] = [];
  //End Import
  objToSave:any = null;
  byPayPeriodDate:any;
  byPayPeriodDates: any[] = [];

  isProjectBased = false;

  loading$ = this.loader.loading$;
  constructor(
    public loader: SpinnerService,
    public authService: AuthService,
    private systemParameterService: SystemParametersService,
    private userManagementService: UserManagementService,
    private importService: ImportService,
    private employerReportService: EmployerReportService,
    private router: Router,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private changeDet:ChangeDetectorRef,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      size:'xl',
    };
  }

  ngOnInit(): void {
    this.getPageParams();
    this.loadData();
  }

  loadData() {
    // Get User Role
    this.role = this.authService.getSignedinUserRole();
    this.systemParameterService
      .getSystemParameterDetails(this.params)
      .subscribe(
        (response: any) => {
          this.systemParameters = response.result;
          //Get Employer Details & Get Announcements
          this.getUserInfo();
        },
        (error: HttpErrorResponse) => {
          alert(error.error.reason);
        }
      );
  }

  getUserInfo(): void {
    // if (this.isEmployerOrStaff()) {
      this.userManagementService
        .getUserReportOptions(this.employerId)
        .subscribe(
          (response: any) => {
            this.isProjectBased = response.result.isProjectBased;
            this.statementDate = new DateString(response.result.statementDate);
            // this.statementDate.setMonth(this.statementDate.getMonth());
            this.employer = response.result.employer;
            // console.log('massImport'+this.employer.massImport);
            this.agreementList = response.result.agreementList;
            this.erfSortOrder = response.result.erfSortOrder;
            this.sort1 = Number(response.result.sortOrder1);
            this.sort2 = Number(response.result.sortOrder2);
            this.sort3 = Number(response.result.sortOrder3);
            this.dataSourceList = response.result.dataSourceList;
            // Remove Import option, only used in report page now
            this.dataSourceList = this.dataSourceList.filter((obj: { guid: number; }) => {
              return obj.guid != 3;
            });

            this.erfTypeList = response.result.erfTypeList;
            this.reportSource = Number(response.result.reportSource);
            this.reportType = Number(response.result.reportType);

            //Used to grab large amounts of data
            this.tmpListRpts = [];
            for(let i = 0; i < response.result.listReportGUIDS.length;i++){
              this.getReportsInChunks(response.result.listReportGUIDS[i]);
            }
            // this.sortOutLists(response.result.listReports);

            // this.reportDate = new Date(response.beginDate);
            this.reportDate = new DateString(response.result.beginDate);

            const ctrlValue = moment(this.reportDate.dateString);
            this.date.setValue(ctrlValue);

            this.minDate = new DateString(response.result.minDate);
            this.maxDate = new DateString(response.result.maxDate);
            this.sortAgreements();
            // this.authService.refreshToken().subscribe((result)=> {}, () => {});
          },
          (error: HttpErrorResponse) => {
            alert(error.error.reason);
          }
          );
  }

  getReportsInChunks(data:any){
    this.userManagementService
    .getReportsInChunks(this.employerId,data)
    .subscribe(
      (response: any) => {
        this.tmpListRpts = this.tmpListRpts.concat(response.result);
        this.sortOutLists(this.tmpListRpts);
        this.authService.refreshToken().subscribe((result)=> {}, () => {});
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      }
    );
  }


  setAgreementLineEnd(data:any){
    var tmp:string = data.rateAsOfType.parameterCode;
    var str:string = tmp.match("FROZEN") ? ": Frozen (" + this.datePipe.transform(data.rateAsOfDate,'M/d/YYYY') + ")" : "";
    str = str + this.setProjectTag(data);
    return str;
  }
  sortAgreements(){
    var tmp = [];
    for(let i = 0; i < this.employer.erCbas.length; i++){
      var effDate2:string|null = this.employer.erCbas[i].effDate;
      var expDate2:string|null = this.employer.erCbas[i].expDate;

      //The below 2 var's fixes a timezone issue 
      //Hacky fix issue with DateString
      var effDate:any = this.to_dateString(effDate2);
      var expDate:any = this.to_dateString(expDate2);
      
      if(expDate == null){
        if(new Date(effDate!) <= this.reportDate.dateString && expDate == null){
          tmp.push(this.employer.erCbas[i]);
        }
      } else {
        if(new Date(effDate!) < this.reportDate.dateString && new Date(expDate) > this.reportDate.dateString){
          tmp.push(this.employer.erCbas[i]);
        }
      }
    }
    tmp = tmp.sort((a, b) => (a.agreement.cbaName < b.agreement.cbaName ? -1 : 1));
    this.agreementList = tmp;
    console.log(tmp);
  }
  sortOutLists(allReports: any) {
    this.finalizedReports = [];
    for (let i = 0; i < allReports.length; i++) {
      if (
        allReports[i].referenceNo != null &&
        allReports[i].agreement != null
      ) {
        this.finalizedReports.push(allReports[i]);
      }
    }
  }
  statusCount(data:any):number{
    var count = 0;
    // this.selectPreviousData = [];
    for(let i = 0; i < this.finalizedReports.length; i++){
      var date1:Date = new Date(this.finalizedReports[i].workPeriodBegin);
      if(this.finalizedReports[i].erCba.agreement.guid == data 
        // && 
        // date1.getDay() == this.reportDate.getDay() &&
        // date1.getMonth() == this.reportDate.getMonth() &&                // Removed so it gets reports when using a future workperiod
        // date1.getFullYear() == this.reportDate.getFullYear()
        ){
          // this.selectPreviousData.push(this.finalizedReports[i]);
          count++
      }
    }
    return count;
  }
  agreementStatusCount(data:any):number{
    var count = 0;
    // this.selectPreviousData = [];
    // console.log('this.reportDate.dateString: '+this.reportDate.dateString);
    // console.log('this.reportDate.dateString.toString(): '+this.reportDate.dateString.toString());
    for(let i = 0; i < this.finalizedReports.length; i++){
      var date1:Date = new Date(this.finalizedReports[i].workPeriodBegin);
      var dateCheck = new DateString(this.finalizedReports[i].workPeriodBegin);

      var wrkPrd = this.reportDate.dateString == undefined ? this.reportDate : this.reportDate.dateString;
      var tmpDateArr = wrkPrd.toString().split(" ", 4);
      // var reportDateCheck: Date = new Date(tmpDateArr[1] + ' ' + tmpDateArr[2] +', ' +tmpDateArr[3]);

      // var tmpDateArr = this.reportDate.dateString.toString().split(" ", 4);
      var reportDateCheck: Date = new Date(tmpDateArr[1] + ' ' + tmpDateArr[2] +', ' +tmpDateArr[3]);
      var tmpCheckMe:DateString = new DateString(reportDateCheck.getFullYear()+'-'+(reportDateCheck.getMonth()+1)+'-'+reportDateCheck.getDate()+'T')

      if(this.finalizedReports[i].erCba.agreement.guid == data && 
        dateCheck.dateString.getDay() == tmpCheckMe.dateString.getDay() &&
        dateCheck.dateString.getMonth() == tmpCheckMe.dateString.getMonth() &&
        dateCheck.dateString.getFullYear() == tmpCheckMe.dateString.getFullYear()
        ){
          count++
      }
    }
    return count;
  }
  getSelectPreviousData(data:any){
    this.selectPreviousData = [];
    for(let i = 0; i < this.finalizedReports.length; i++){
      var date1:Date = new Date(this.finalizedReports[i].workPeriodBegin);
      if(this.finalizedReports[i].erCba.agreement.guid == data 
        // && 
        // date1.getDay() == this.reportDate.getDay() &&
        // date1.getMonth() == this.reportDate.getMonth() &&                // Removed so it gets reports when using a future workperiod
        // date1.getFullYear() == this.reportDate.getFullYear()
        ){
          this.selectPreviousData.push(this.finalizedReports[i]);
      }
    }
  }
  datechange(){
    console.log("IN DATE CHANGE");
    this.sortAgreements();
    this.table.renderRows();
  }

  isAffirmative(s: string) {
    if (
      s != null &&
      (s.toUpperCase().match('Y') ||
        s.toUpperCase().match('YES') ||
        s.match('1'))
    ) {
      return true;
    } else {
      return false;
    }
  }

  // CHECK FOR USER ROLE
  isEmployer() {
    if (this.role == this.isRoleEmployer) {
      return true;
    } else {
      return false;
    }
  }
  isEmployerOrStaff() {
    if (
      this.role == this.isRoleEmployer ||
      this.role == this.isRoleEmployerStaff
    ) {
      return true;
    } else {
      return false;
    }
  }
  isAdmin() {
    if (this.role == this.isRoleAdmin) {
      return true;
    } else {
      return false;
    }
  }
  isAuditor() {
    if (this.role == this.isRoleAuditor) {
      return true;
    } else {
      return false;
    }
  }
  //
  selectSort1Change(data: any) {
    console.log(data);
  }
  selectSort2Change(data: any) {
    console.log(data);
  }
  selectSort3Change(data: any) {
    console.log(data);
  }

  orderEquals(val1: any, val2: any) {
    return val1 != 6 && val2 != 6 && val1 == val2;
  }
  checkSorter() {
    if (this.sort1 != 6 || this.sort2 != 6 || this.sort3 != 6) {
      if (
        this.orderEquals(this.sort1, this.sort2) ||
        this.orderEquals(this.sort1, this.sort3) ||
        this.orderEquals(this.sort2, this.sort3)
      ) {
        alert('Sort Order must be unique');
        // return false;
      }
    }
  }
  setNCHData(data:any){
    this.reportType = 2;
    this.reportSource = 5;
    this.nchData = data;
  }
  setSelectPreviousData(data:any){
    console.log(data);
  }
  cancelSelectPrevious(){
    this.selectPrevious = null;
  }
  createNewAgreement(data: any) {
    this.objToSave = null;
    // this.importAgreementDetails = null;
    if(this.isAffirmative(this.systemParameters[1].parameterValue1!)) {
      var wrkPrd = this.reportDate.dateString == undefined ? this.reportDate : this.reportDate.dateString;
      var tmpDateArr = wrkPrd.toString().split(" ", 4);
      var reportDateCheck: Date = new Date(tmpDateArr[1] + ' ' + tmpDateArr[2] +', ' +tmpDateArr[3]);
      if(!confirm("You have selected a work period of " + reportDateCheck.toLocaleDateString())){
        return;
      }
    }

    // If No Employees is selected
    if(this.reportType === 2){
      this.reportSource = 5;
    }
    // If Select Previous
    if(this.selectPrevious == null){
      if(this.reportSource === 4 && this.statusCount(data.agreement.guid) == 0){
        this.reportType = 2
        this.reportSource = 5;
      } else if(this.reportSource === 4){
        this.getSelectPreviousData(data.agreement.guid);
        // Open Modal
        this.open(this.selectPreviousModal);
        return;
      }
    }
    // If Use Last report
    if(this.reportSource == 2){
      if(this.statusCount(data.agreement.guid) != 0){
        this.getSelectPreviousData(data.agreement.guid);
        this.selectPrevious = this.selectPreviousData[0];
        data = this.selectPreviousData[0];
      }
    }
    if(this.reportSource == 3 && data.agreement.masterAgreement.isVEBA){
      alert("This is a Veba Agreement, select Blank Form!");
      return;
    }

    // Import
    var importName = null;
    if(this.reportSource == 3 && !this.importReady){
      this.importAgreementDetails = data;
      this.open(this.importSelectFile);
      return;
    } else if(this.reportSource == 3 && this.importReady){
      // alert('Ready to IMport do code!');
      const result = this.allExistingMappings.filter((obj: { type: { guid: any; }; }) => {
        return obj.type.guid === this.defaultMapping;
      });
      importName = result[0].type;
    }
    console.log(data);
    
    var dataToSave: { reportedEmployees: any; agreementId?: any; sortOrder1?: any; sortOrder2?: any; sortOrder3?: any; reportSource?: number; reportType?: number; beginDate?: Date | DateString; contributionReportID?: any; importType?: any; };
    if(this.selectPrevious == null){
      dataToSave =  {
        agreementId: data.guid,
        sortOrder1: this.sort1,
        sortOrder2: this.sort2,
        sortOrder3: this.sort3,
        reportSource: this.reportSource,
        reportType: this.reportType,
        beginDate: this.reportDate.dateString == undefined ? this.reportDate : this.reportDate.dateString,
        reportedEmployees: this.importedEmployees,
        contributionReportID: this.contributionReportID,
        importType: importName,
      };
    } else {
      dataToSave =  {
        agreementId: data.erCba.guid,
        sortOrder1: data.sort1.guid,
        sortOrder2: data.sort2.guid,
        sortOrder3: data.sort3.guid,
        reportSource: this.reportSource,
        reportType: this.reportType,
        beginDate: this.reportDate.dateString == undefined ? this.reportDate : this.reportDate.dateString,
        reportedEmployees: this.importedEmployees,
        contributionReportID: this.contributionReportID,
        importType: importName,
      };
    }
    if(data.reportedEmployees != null){
      for(let i = 0; i < data.reportedEmployees.length; i++){
        data.reportedEmployees[i].guid = null;
      }
      dataToSave.reportedEmployees = data.reportedEmployees;
    }

    this.payrollCompanySelectedEmployer = this.authService.getPGUID() != null && this.authService.getPGUID() != 'null' ? data.employerGUID : this.employerId;
    //check for reportByPayPeriod vs report by workPeriod
    this.employerReportService.checkReportByPayPeriod(dataToSave,this.payrollCompanySelectedEmployer).subscribe(
      (response: any) => {
        console.log(response);
        if(response.result.length > 0){//Select Pay period before report creation
          this.objToSave = dataToSave;
          this.byPayPeriodDates = response.result;
          this.open(this.byPayPeriodModal);
        } else {
          this.createReport(dataToSave);
        }
      },
      (error: HttpErrorResponse) => {
        alert(error.error.reason);
      }
    );
  }
  getVebaStatement(data:any){
    this.agreementId = null;
    this.agreementId = data.guid;
    this.locationId = data.agreement.agreementLongName;
    this.employerReportService.vebaStatementPdf(data.guid)
        .pipe(take(1))
        .subscribe((response:any) => {
          let file = new Blob([response], { type: 'application/pdf' });           
          this.fileURL = URL.createObjectURL(file);
          this.open(this.pdfModal);
          // this.authService.refreshToken().subscribe((result)=> {}, () => {});
        });
  }
  getVebaStatementExcel(){
    this.employerReportService.vebaStatementExcel(this.agreementId)
        .pipe(take(1))
        .subscribe((response:any) => {
          // let file = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });          
          let file = new Blob([response], { type: 'application/vnd.ms-excel' });   
          // this.fileURL = URL.createObjectURL(file);
          // this.open(this.excelModal);
          saveAs(file, ('Statement_' + this.locationId.replace('.', "") + '_' + this.datePipe.transform(this.statementDate.dateString,'M/d/YYYY')));
          // this.authService.refreshToken().subscribe((result)=> {}, () => {});
        });
  }
  pdfURL(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.fileURL);
  }
  goToMassImport(){
    this.router.navigate([
      '/reportOptions/massImport',
      { employerId: btoa(this.employerId) },
    ]);
  }
  createReport(dataToSave:any){
    //Start new report
    this.employerReportService.saveNewReport(dataToSave,this.payrollCompanySelectedEmployer/*this.employerId*/).subscribe(
      (response: any) => {
        console.log(response);
        this.modalService.dismissAll();
        if(this.payrollCompanySelectedEmployer/*this.employerId*/ === '' || this.payrollCompanySelectedEmployer/*this.employerId*/ == null){
          this.router.navigate([
            '/remittanceReport',
            { agreementId: btoa(response.result), reportId: btoa(this.reportType.toString()) },
          ]);
        }else{
          this.router.navigate([
            '/remittanceReport',
            { agreementId: btoa(response.result), reportId: btoa(this.reportType.toString()), employerId: btoa(this.payrollCompanySelectedEmployer/*this.employerId*/) },
          ]);
        }
      },
      (error: HttpErrorResponse) => {
        this.importReady = false;
        alert(error.error.reason);
      }
    );
  }
  byPayPeriod(){
    if(this.byPayPeriodDate == null || this.byPayPeriodDate == undefined || this.byPayPeriodDate == '' || this.byPayPeriodDate == '--Select--'){
      alert('Pay period date must be selected to create report.');
      return;
    }
    this.objToSave.payRollPeriodEnd = new Date(this.byPayPeriodDate);
    this.createReport(this.objToSave);
  }
  selectPayPeriod(event:any){
    const value = event.target.value;
    this.byPayPeriodDate = value;
  }
  open(content: any) {
    console.log('open');
    this.modalService.open(content, this.modalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    this.reportDate = this.date.value._d;
    this.changeDet.detectChanges();//Detects changes and updates value
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.reportDate = this.date.value._d;
    console.log(this.reportDate);
    this.changeDet.detectChanges();//Detects changes and updates value
  }
  
  getPageParams():void{
    this.employerId = atob(this.route.snapshot.paramMap.get('employerId')!);
  }
  checkImportFile(){
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      if(file){
        this.currentFile = file;
        //send file to get headers and sort order if exists
        this.importService
          .getHeaders(this.currentFile,this.importAgreementDetails.agreement,this.employer,this.headerRow, null/*TODO: Dont know how to handle yet not sure if used in here anymore*/)
          .subscribe(
            (response: any) => {
              this.fileImportFormat = response.result.fileColumns;
              this.fileColumnsPreview = response.result.fileColumnsPreview;
              this.agreementColumns = response.result.agreementColumns;
              this.agreementColumnsSortOrder = response.result.agreementColumnsSortOrder;
              this.allExistingMappings = response.result.allExistingMappings;
              this.importNamesList = response.result.importNamesList;
              if(this.importNamesList != null && this.importNamesList.length > 0){
                this.defaultMapping = this.importNamesList[0].guid;
                this.getMapping();
              }
            },
            (error: HttpErrorResponse) => {
              alert(error.error.reason);
            }
          );
        ///////
        this.modalService.dismissAll();
        this.open(this.importSetup);
      }else{
        alert('Select a file to continue.');
      }
    }else{
      alert('Select a file to continue.');
    }
  }
  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }
  previewReport(){
    this.modalService.dismissAll();
    console.log(this.agreementColumnsSortOrder);
    this.open(this.importSetup);
  }
  sortPreview(data:any){
    if(data != ""){
      return true;
    }
    return false;
  }
  saveNewmapping(){
    if(this.newMappingName == null || this.newMappingName == "" || this.newMappingName == undefined){
      alert("Please add new Mapping Name!");
    }else{
      this.importService
      .saveNewMapping(this.newMappingName, this.agreementColumnsSortOrder, this.agreementColumns,this.employer.guid,this.importAgreementDetails.agreement.masterAgreementGUID,  null/*TODO: Dont know how to handle yet not sure if used in here anymore*/ )
      .subscribe(
        (response: any) => {
          console.log(response);
          //This is for newly saved reports
          this.allExistingMappings = response.result.allExistingMappings;
          const result = response.result.importNamesList.filter((obj: { name: any; }) => {
            return obj.name === this.newMappingName.toUpperCase();
          });
          this.defaultMapping = result[0].guid;
          alert("Mapping has been saved! \nYou Can create a Report now.");
        },
        (error: HttpErrorResponse) => {
          // alert("Error while saving mapping: "+error.message);
          alert(error.error.reason);
        }
      );
    }
  }
  getMapping(){
    for(let i = 0; i < this.agreementColumns.length; i++){
      const result = this.allExistingMappings.filter((obj: { type: { guid: any; }; }) => {
        return obj.type.guid === this.defaultMapping;
      });

      if(result[i].columnOrder == null){
        this.agreementColumnsSortOrder[i] = "";
      }else{
        this.agreementColumnsSortOrder[i] = result[i].columnOrder;
      }
    }
    console.log(this.agreementColumnsSortOrder);
  }
  createImportReport(){
    
    this.importedEmployees = [];
    //SET REPORTED EMPLOYEES
    this.importService
    .setUpImportReport(this.currentFile!,this.importAgreementDetails.agreement,this.employer,this.headerRow,this.defaultMapping, null/*TODO: Dont know how to handle yet not sure if used in here anymore*/ )
    .subscribe(
      (response: any) => {
        console.log(response);
        this.importReady = true;
        this.importedEmployees = response;
        this.createNewAgreement(this.importAgreementDetails);
      },
      (error: HttpErrorResponse) => {
        this.importReady = false;
        alert(error.message);
      }
    );
  }
  checkIfAllVeba(){
    for(let i = 0; i < this.agreementList.length;i++){
      if(!this.agreementList[i].agreement.masterAgreement.isVEBA){
        return true;
      }
    }
    return false;
  }

  to_dateString(str?:string|null){
    if (str == null) return null;
    var d = new Date();
    if(str != null || str != undefined){
        var arr = str.split("-", 3);
        arr[2] = arr[2].substring(0,arr[2].lastIndexOf('T'));
        d.setFullYear(Number(arr[0]));//year
        d.setMonth(Number(arr[1])-1);//month
        d.setDate(Number(arr[2]));//day
    }
    d.setHours(0);//hours
    d.setMinutes(0);//minutes
    d.setSeconds(0);//seconds
    d.setMilliseconds(0);//mi-sec
    // return d;
    // this.dateString = d;
    return d;
  }

  setProjectTag(data:any){
    if(this.isProjectBased){
      if(data.projects != null && data.projects != undefined && data.projects.length != 0){
        return '['+data.projects[0].project.projectName +']';
      }
    }
    return ""
  }
}
