<body *ngIf="employer">

    <ng-container *ngIf="loading$ | async">
        <app-is-loading></app-is-loading>
    </ng-container>

    <ng-container *ngIf="isEmployerOrStaff()">
        <h3> {{employer.organizationName}} : {{employer.organizationID}}</h3>
    </ng-container>
    <ng-container *ngIf="isAdmin()">
        <h3> System Administrator on behalf of {{employer.organizationName}} : {{employer.organizationID}}</h3>
    </ng-container>
    <span class="pageHeader">View/Edit Employer Project</span>
    <br>
    <button mat-button color="primary" style="outline: none;text-decoration: underline;padding: 0;" (click)="open(createModal)">
        Create a new Project
    </button>
    <p>To edit an existing project, click the ID&#47;Name of the project in the list.</p>

    <div>
        <table style="width: 100%;white-space: nowrap;border-style: solid;">
            <tr style="border-bottom-style: solid;">
                <th style="padding: 4px;border-right: 2px solid;">#</th>
                <th style="padding: 4px;border-right: 2px solid;">Project ID / Name</th>
                <th style="padding: 4px;border-right: 2px solid;">Agreement Name</th>
                <th style="padding: 4px;border-right: 2px solid;">Project Description</th>
                <th style="padding: 4px;border-right: 2px solid;">Eff Date</th>
                <th style="padding: 4px;border-right: 2px solid;">Exp Date</th>
                <th style="padding: 4px;">Active</th>
            </tr>
            <ng-container *ngFor="let item of employerProjectsList; let i = index">
                <tr style="border-bottom: 2px solid;">
                    <td style="text-align: center;padding: 2px;border: 2px solid;">{{i + 1}}</td>
                    <td style="padding: 2px;border: 2px solid;">
                        <!-- Cannot Edit Details -->
                        <ng-container *ngIf="item.source == 'TRUSTpartner';else sourceWebERF">
                            <button mat-button color="primary" class="projLink" (click)="viewEditData(item)">
                                {{item.employerProjectID}}
                            </button>
                        </ng-container>
                        <!-- Can Edit Details -->
                        <ng-template #sourceWebERF>
                            <button mat-button color="primary" class="projLink" (click)="viewEditData(item)">
                                {{item.employerProjectID}}
                            </button>
                        </ng-template> 
                    </td>
                    <td style="padding: 2px;border: 2px solid;">
                        <ng-container *ngIf="item.erCba == null">
                            All Agreements
                        </ng-container>
                        <ng-container *ngIf="item.erCba != null">
                            {{item.erCba.agreement.cbaName}}
                        </ng-container>
                    </td>
                    <td style="padding: 2px;border: 2px solid;">{{item.project.projectName}}</td>
                    <td style="padding: 2px;border: 2px solid;text-align: center;">{{item.project.effDate | date : 'MM/dd/yyy'}}</td>
                    <td style="padding: 2px;border: 2px solid;text-align: center;">{{item.project.expDate | date : 'MM/dd/yyy'}}</td>
                    <td style="padding: 2px;border: 2px solid;text-align: center;">{{item.project.active ? "Yes" : "No"}}</td>
                </tr>
            </ng-container>
        </table>
    </div>
</body>


<!-- Create Project Modal -->
<ng-template #createModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Setup New Employer Project</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="tbl">
            <table>
                <tr>
                    <td><label style="font-weight: bold;">Master Project ID</label>:</td>
                    <td>
                        <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                            <input readonly [(ngModel)]="selectedSearchProject.projectId">
                        </ng-container>
                        <ng-container *ngIf="selectedSearchProject == null || selectedSearchProject == undefined">
                            <input readonly>
                        </ng-container>
                        <button color="primary" mat-raised-button class="btn btn-outline-dark" (click)="openSearch('CREATE');resetCreate();">Search</button>
                    </td>
                </tr> 
                <tr>
                    <td><span class="required">Project ID*</span>:</td>
                    <td>
                        <input [(ngModel)]="createProjectId">
                    </td>
                </tr> 
                <tr>
                    <td><label style="font-weight: bold;">Awarding Agency:</label></td>
                    <td>
                        <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                            <!-- <input readonly> -->
                            {{selectedSearchProject.awardingAgencyID}}
                        </ng-container>
                        <ng-container *ngIf="selectedSearchProject == null || selectedSearchProject == undefined">
                            <input [(ngModel)]="createAA">
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td><label style="font-weight: bold;">Awarding Agency Contract #:</label></td>
                    <td>
                        <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                            <!-- <input readonly> -->
                            {{selectedSearchProject.awardingAgencyContract}}
                        </ng-container>
                        <ng-container *ngIf="selectedSearchProject == null || selectedSearchProject == undefined">
                            <input [(ngModel)]="createAAC">
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td><label style="font-weight: bold;" for="project.generalContractor">General Contractor:</label></td>
                    <td>
                        <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                            <!-- <input readonly> -->
                            {{selectedSearchProject.generalContractor}}
                        </ng-container>
                        <ng-container *ngIf="selectedSearchProject == null || selectedSearchProject == undefined">
                            <input [(ngModel)]="createGC">
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td><label style="font-weight: bold;">Contact:</label></td>
                    <td>
                        <input [(ngModel)]="createContact">
                    </td>
                </tr>
                <tr>
                    <td colspan="2">&nbsp;</td>
                </tr>
                <tr>
                    <td><span class="required">Name*</span>:<br><span>(maximum of 50 characters allowed)</span></td>
                    <td>
                        <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                            <!-- <input readonly> -->
                            {{selectedSearchProject.projectName}}
                        </ng-container>
                        <ng-container *ngIf="selectedSearchProject == null || selectedSearchProject == undefined">
                            <input [(ngModel)]="createName">
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">&nbsp;</td>
                </tr>
                <tr>
                    <td width="30%"><label style="font-weight: bold;">Address Line 1:</label>&nbsp;&nbsp;</td>
                    <td>
                        <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                            <!-- <input readonly> -->
                            {{selectedSearchProject.addrLine1}}
                        </ng-container>
                        <ng-container *ngIf="selectedSearchProject == null || selectedSearchProject == undefined">
                            <input [(ngModel)]="createAddr1">
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td width="30%"><label style="font-weight: bold;">Address Line 2:</label>&nbsp;&nbsp;</td>
                    <td>
                        <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                            <!-- <input readonly> -->
                            {{selectedSearchProject.addrLine2}}
                        </ng-container>
                        <ng-container *ngIf="selectedSearchProject == null || selectedSearchProject == undefined">
                            <input [(ngModel)]="createAddr2">
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td width="30%"><label style="font-weight: bold;">City:</label>&nbsp;&nbsp;</td>
                    <td>
                        <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                            <!-- <input readonly> -->
                            {{selectedSearchProject.city}}
                        </ng-container>
                        <ng-container *ngIf="selectedSearchProject == null || selectedSearchProject == undefined">
                            <input [(ngModel)]="createCity">
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td width="30%"><label style="font-weight: bold;">State&#47;Province:</label>&nbsp;&nbsp;</td>
                    <td>
                        <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                            <!-- <input readonly> -->
                            {{selectedSearchProject.state}}
                        </ng-container>
                        <ng-container *ngIf="selectedSearchProject == null || selectedSearchProject == undefined">
                            <!-- <input [(ngModel)]="createState"> -->

                            <select class="states" name="states" id="states" [(ngModel)]="createState">
                                <option value="null">-</option>
                                <ng-container *ngIf="createCountry == 'USA'">
                                    <ng-container *ngFor="let state of statesList; let i = index">
                                        <option  [ngValue]="state.parameterCode">{{state.parameterDesc}}</option>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="createCountry == 'CAN'">
                                    <ng-container *ngFor="let state of statesCanList; let i = index">
                                        <option  [ngValue]="state.parameterCode">{{state.parameterDesc}}</option>
                                    </ng-container>
                                </ng-container>
                            </select>

                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td width="30%"><label style="font-weight: bold;">Country:</label>&nbsp;&nbsp;</td>
                    <td>
                        <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                            <!-- <input readonly> -->
                            {{selectedSearchProject.country}}
                        </ng-container>
                        <ng-container *ngIf="selectedSearchProject == null || selectedSearchProject == undefined">
                            <!-- <input [(ngModel)]="createCountry"> -->
                            <select property="createCountry" [(ngModel)]="createCountry" styleId="Country" >
                                <option value="USA" >USA</option>
                                <option value="CAN" >Canada</option>
                            </select>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td width="30%"><label style="font-weight: bold;">Zip&#47;Postal Code:</label>&nbsp;&nbsp;</td>
                    <td>
                        <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                            <!-- <input readonly> -->
                            {{selectedSearchProject.zip}}
                        </ng-container>
                        <ng-container *ngIf="selectedSearchProject == null || selectedSearchProject == undefined">
                            <input [(ngModel)]="createZip">
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">&nbsp;</td>
                </tr>
                <tr>
                    <td colspan="2">&nbsp;</td>
                </tr> 
                <tr>
                    <td><label for="begindate"><span class="required">Effective Date (mm/dd/ccyy)*</span>:</label></td>
                    <td>
                        <mat-form-field appearance="fill" style="width: 200px">
                            <mat-label>Choose EffDate</mat-label>
                            <input matInput [matDatepicker]="picker3" [readonly]="true" [(ngModel)]="createEff">
                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td><label style="font-weight: bold;" for="finishdate">Expiration Date (mm/dd/ccyy):</label></td>
                    <td>
                        <mat-form-field appearance="fill" style="width: 200px">
                            <mat-label>Choose EffDate</mat-label>
                            <input matInput [matDatepicker]="picker4" [readonly]="true" [(ngModel)]="createExp">
                            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                            <mat-datepicker #picker4></mat-datepicker>
                        </mat-form-field>
                        <button mat-raised-button color="warn" (click)="createExp = null">Clear</button>
                    </td>
                </tr>
                <tr>
                    <td><label style="font-weight: bold;">Comments:</label><br><span>(maximum of 250 characters allowed)</span></td>
                    <td>
                        <textarea name="Text1" rows="4" maxlength="250" [(ngModel)]="createComment" style="width: -webkit-fill-available;"></textarea>
                    </td>
                </tr>
            </table>
           </div>
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="resetCreate();modal.close('Save click')">Cancel</button>
        <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
            <button [disabled]="createEff == null || createEff == undefined || createEff == '' || createProjectId == null || createProjectId == undefined || createProjectId == ''" 
            type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="create();modal.close('Save click')">Create</button>
        </ng-container>
        <ng-container *ngIf="selectedSearchProject == null || selectedSearchProject == undefined">
            <button [disabled]="createEff == null || createEff == undefined || createEff == '' || createProjectId == null || createProjectId == undefined || createProjectId == '' || createName == null || createName == undefined || createName == ''" 
            type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="create();modal.close('Save click')">Create</button>
        </ng-container>
        
    </div>
</ng-template>
<!-- View Project Modal -->
<ng-template #viewModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">View Employer Project</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
       <div class="tbl">
        <table *ngIf="selectedProj.project != null">
            <tr>
                <td><span class="required">Master Project ID</span>:</td>
                <td>{{selectedProj.project.projectId}}</td>
            </tr> 
            <tr>
                <td><span class="required">Project ID</span>:</td>
                <td>{{selectedProj.employerProjectID}}</td>
            </tr> 
            <tr>
                <td>Awarding Agency:</td>
                <td>{{selectedProj.project.awardingAgencyID}}</td>
            </tr>
            <tr>
                <td>Awarding Agency Contract #:</td>
                <td>{{selectedProj.project.awardingAgencyContract}}</td>
            </tr>
            <tr>
                <td><label style="font-weight: bold;" for="project.generalContractor">General Contractor:</label></td>
                <td>{{selectedProj.project.generalContractor}}</td>
            </tr>
            <tr>
                <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
                <td><span class="required">Name</span>:</td>
                <td>{{selectedProj.project.projectName}}</td>
            </tr>
            <tr>
                <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
                <td width="30%">Address Line 1:&nbsp;&nbsp;</td>
                <td>{{selectedProj.project.addrLine1}}</td>
            </tr>
            <tr>
                <td width="30%">Address Line 2:&nbsp;&nbsp;</td>
                <td>{{selectedProj.project.addrLine2}}</td>
            </tr>
            <tr>
                <td width="30%">City:&nbsp;&nbsp;</td>
                <td>{{selectedProj.project.city}}</td>
            </tr>
            <tr>
                <td width="30%">State&#47;Province:&nbsp;&nbsp;</td>
                <td>{{selectedProj.project.state}}</td>
            </tr>
            <tr>
                <td width="30%">Country:&nbsp;&nbsp;</td>
                <td>{{selectedProj.project.country}}</td>
            </tr>
            <tr>
                <td width="30%">Zip&#47;Postal Code:&nbsp;&nbsp;</td>
                <td>{{selectedProj.project.zip}}</td>
            </tr>
            <tr>
                <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
                <td>Status:</td>
                <td>
                    {{selectedProj.project.active ? "Active" : "InActive"}}
                </td>
            </tr>
            <tr>
                <td colspan="2">&nbsp;</td>
            </tr> 
            <tr>
                <td><label for="begindate"><span class="required">Effective Date (mm/dd/ccyy)*</span>:</label></td>
                <td>{{selectedProj.effDate | date : 'MM/dd/yyy'}}</td>
            </tr>
            <tr>
                <td><label style="font-weight: bold;" for="finishdate">Expiration Date (mm/dd/ccyy):</label></td>
                <td>{{selectedProj.expDate | date : 'MM/dd/yyy'}}</td>
            </tr>
        </table>
       </div>
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>
<!-- Edit Project Modal -->
<ng-template #editModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit Employer Project</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Edit project details and then click the Save button.</p>
        <div class="tbl">
            <table *ngIf="selectedProj.project != null">
                <tr>
                    <td><label><span class="required">Master Project ID*</span>:</label></td>
                    <td>
                        <ng-container *ngIf="selectedProj.project.source == 'TRUSTpartner';else canEdit">
                            <input [(ngModel)]="selectedProj.project.projectId" readonly>
                        </ng-container>
                        <ng-template #canEdit>
                            <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined;else noMaster">
                                <input [(ngModel)]="selectedSearchProject.projectId" readonly>
                            </ng-container>
                            <ng-template #noMaster>
                                <input readonly>
                                <button color="primary" mat-raised-button class="btn btn-outline-dark" (click)="openSearch('EDIT')">Search</button>
                            </ng-template>
                        </ng-template>
                    </td>
                </tr> 
                <tr>
                    <td><span class="required">Project ID*</span>:</td>
                    <td>{{selectedProj.employerProjectID}}</td>
                </tr> 
                <tr>
                    <td>Awarding Agency:</td>
                    <td>
                        <ng-container *ngIf="selectedProj.project.source == 'TRUSTpartner';else canEditAa">
                            {{selectedProj.project.awardingAgencyID}}
                        </ng-container>
                        <ng-template #canEditAa>
                            <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                                {{selectedSearchProject.awardingAgencyID}}
                            </ng-container>
                            <ng-container *ngIf="selectedSearchProject == null && selectedSearchProject == undefined">
                                <input [(ngModel)]="selectedProj.project.awardingAgencyID">
                            </ng-container>
                        </ng-template>
                    </td>
                </tr>
                <tr>
                    <td>Awarding Agency Contract #:</td>
                    <td>
                        <ng-container *ngIf="selectedProj.project.source == 'TRUSTpartner';else canEditAaC">
                            {{selectedProj.project.awardingAgencyContract}}
                        </ng-container>
                        <ng-template #canEditAaC>
                            <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                                {{selectedSearchProject.awardingAgencyContract}}
                            </ng-container>
                            <ng-container *ngIf="selectedSearchProject == null && selectedSearchProject == undefined">
                                <input [(ngModel)]="selectedProj.project.awardingAgencyContract">
                            </ng-container>
                        </ng-template>
                    </td>
                </tr>
                <tr>
                    <td><label style="font-weight: bold;" for="project.generalContractor">General Contractor:</label></td>
                    <td>
                        <ng-container *ngIf="selectedProj.project.source == 'TRUSTpartner';else canEditgc">
                            {{selectedProj.project.generalContractor}}
                        </ng-container>
                        <ng-template #canEditgc>
                            <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                                {{selectedSearchProject.generalContractor}}
                            </ng-container>
                            <ng-container *ngIf="selectedSearchProject == null && selectedSearchProject == undefined">
                                <input [(ngModel)]="selectedProj.project.generalContractor">
                            </ng-container>
                        </ng-template>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">&nbsp;</td>
                </tr>
                <tr>
                    <td><span class="required">Name*</span>:<br><span>(maximum of 50 characters allowed)</span></td>
                    <td>
                        <ng-container *ngIf="selectedProj.project.source == 'TRUSTpartner';else canEditpn">
                            {{selectedProj.project.projectName}}
                        </ng-container>
                        <ng-template #canEditpn>
                            <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                                {{selectedSearchProject.projectName}}
                            </ng-container>
                            <ng-container *ngIf="selectedSearchProject == null && selectedSearchProject == undefined">
                                <input [(ngModel)]="selectedProj.project.projectName">
                            </ng-container>
                        </ng-template>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">&nbsp;</td>
                </tr>
                <tr>
                    <td width="30%">Address Line 1:&nbsp;&nbsp;</td>
                    <td>
                        <ng-container *ngIf="selectedProj.project.source == 'TRUSTpartner';else canEdita1">
                            {{selectedProj.project.addrLine1}}
                        </ng-container>
                        <ng-template #canEdita1>
                            <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                                {{selectedSearchProject.addrLine1}}
                            </ng-container>
                            <ng-container *ngIf="selectedSearchProject == null && selectedSearchProject == undefined">
                                <input [(ngModel)]="selectedProj.project.addrLine1">
                            </ng-container>
                        </ng-template>
                    </td>
                </tr>
                <tr>
                    <td width="30%">Address Line 2:&nbsp;&nbsp;</td>
                    <td>
                        <ng-container *ngIf="selectedProj.project.source == 'TRUSTpartner';else canEdita2">
                            {{selectedProj.project.addrLine2}}
                        </ng-container>
                        <ng-template #canEdita2>
                            <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                                {{selectedSearchProject.addrLine2}}
                            </ng-container>
                            <ng-container *ngIf="selectedSearchProject == null && selectedSearchProject == undefined">
                                <input [(ngModel)]="selectedProj.project.addrLine2">
                            </ng-container>
                        </ng-template>
                    </td>
                </tr>
                <tr>
                    <td width="30%">City:&nbsp;&nbsp;</td>
                    <td>
                        <ng-container *ngIf="selectedProj.project.source == 'TRUSTpartner';else canEditac">
                            {{selectedProj.project.city}}
                        </ng-container>
                        <ng-template #canEditac>
                            <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                                {{selectedSearchProject.city}}
                            </ng-container>
                            <ng-container *ngIf="selectedSearchProject == null && selectedSearchProject == undefined">
                                <input [(ngModel)]="selectedProj.project.city">
                            </ng-container>
                        </ng-template>
                    </td>
                </tr>
                <tr>
                    <td width="30%">State&#47;Province:&nbsp;&nbsp;</td>
                    <td>
                        <ng-container *ngIf="selectedProj.project.source == 'TRUSTpartner';else canEditas">
                            {{selectedProj.project.state}}
                        </ng-container>
                        <ng-template #canEditas>
                            <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                                {{selectedSearchProject.state}}
                            </ng-container>
                            <ng-container *ngIf="selectedSearchProject == null && selectedSearchProject == undefined">
                                <!-- <input [(ngModel)]="selectedProj.project.state"> -->
                                <ng-container *ngIf="selectedProj.project.country == 'USA';else canStates">
                                    <select class="states" name="states" id="states" [(ngModel)]="selectedProj.project.state">
                                        <option value="null">-</option>
                                        <ng-container *ngFor="let state of statesList; let i = index">
                                            <option  [ngValue]="state.parameterCode">{{state.parameterDesc}}</option>
                                        </ng-container>
                                    </select>
                                </ng-container>
                                <ng-template #canStates>
                                    <select class="states" name="states" id="states" [(ngModel)]="selectedProj.project.state">
                                        <option value="null">-</option>
                                        <ng-container *ngFor="let state of statesCanList; let i = index">
                                            <option  [ngValue]="state.parameterCode">{{state.parameterDesc}}</option>
                                        </ng-container>
                                    </select>
                                </ng-template>
                            </ng-container>
                        </ng-template>
                    </td>
                </tr>
                <tr>
                    <td width="30%">Country:&nbsp;&nbsp;</td>
                    <td>
                        <ng-container *ngIf="selectedProj.project.source == 'TRUSTpartner';else canEditc">
                            {{selectedProj.project.country}}
                        </ng-container>
                        <ng-template #canEditc>
                            <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                                {{selectedSearchProject.country}}
                            </ng-container>
                            <ng-container *ngIf="selectedSearchProject == null && selectedSearchProject == undefined">
                                <select property="selectedProj.project.country" [(ngModel)]="selectedProj.project.country" styleId="Country" >
                                    <option value="USA" >USA</option>
                                    <option value="CAN" >Canada</option>
                                </select>
                            </ng-container>
                        </ng-template>
                    </td>
                </tr>
                <tr>
                    <td width="30%">Zip&#47;Postal Code:&nbsp;&nbsp;</td>
                    <td>
                        <ng-container *ngIf="selectedProj.project.source == 'TRUSTpartner';else canEditz">
                            {{selectedProj.project.zip}}
                        </ng-container>
                        <ng-template #canEditz>
                            <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                                {{selectedSearchProject.zip}}
                            </ng-container>
                            <ng-container *ngIf="selectedSearchProject == null && selectedSearchProject == undefined">
                                <input [(ngModel)]="selectedProj.project.zip">
                            </ng-container>
                        </ng-template>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">&nbsp;</td>
                </tr>
                <tr>
                    <td>Status:</td>
                    <td>
                        {{selectedProj.project.active ? "Active" : "InActive"}}
                    </td>
                </tr>
                <tr>
                    <td colspan="2">&nbsp;</td>
                </tr> 
                <tr>
                    <td><label for="begindate"><span class="required">Effective Date (mm/dd/ccyy)*</span>:</label></td>
                    <td>
                        <ng-container *ngIf="selectedProj.project.source == 'TRUSTpartner';else canEditEff">
                            {{selectedProj.effDate | date : 'MM/dd/yyy'}}
                        </ng-container>
                        <ng-template #canEditEff>
                            <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                                {{selectedSearchProject.effDate  | date : 'MM/dd/yyy'}}
                            </ng-container>
                            <ng-container *ngIf="selectedSearchProject == null && selectedSearchProject == undefined">
                                <mat-form-field appearance="fill" style="width: 200px">
                                    <mat-label>Choose EffDate</mat-label>
                                    <input matInput [matDatepicker]="picker1" [readonly]="true" [(ngModel)]="selectedProj.project.effDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                            </ng-container>
                        </ng-template>
                    </td>
                </tr>
                <tr>
                    <td><label style="font-weight: bold;" for="finishdate">Expiration Date (mm/dd/ccyy):</label></td>
                    <td>
                        <ng-container *ngIf="selectedProj.project.source == 'TRUSTpartner';else canEditExp">
                            {{selectedProj.expDate | date : 'MM/dd/yyy'}}
                        </ng-container>
                        <ng-template #canEditExp>
                            <ng-container *ngIf="selectedSearchProject != null && selectedSearchProject != undefined">
                                {{selectedSearchProject.expDate  | date : 'MM/dd/yyy'}}
                            </ng-container>
                            <ng-container *ngIf="selectedSearchProject == null && selectedSearchProject == undefined">
                                <mat-form-field appearance="fill" style="width: 200px">
                                    <mat-label>Choose ExpDate</mat-label>
                                    <input matInput [matDatepicker]="picker2" [readonly]="true" [(ngModel)]="selectedProj.project.expDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                                <button mat-raised-button color="warn" (click)="selectedProj.project.expDate = null">Clear</button>
                            </ng-container>
                        </ng-template>
                    </td>
                </tr>
            </table>
           </div>
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click');selectedSearchProject = null">Cancel</button>
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" [disabled]="selectedProj.project.source == 'TRUSTpartner'" (click)="update();modal.close('Save click')">Update</button>
    </div>
</ng-template>

<!-- Search Project Modal -->
<ng-template #searchModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Project Search</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
       <div>
            <table class="tbl" style="width: -webkit-fill-available;">
                <tr>
                    <td><label class="bold">Master Project ID:</label></td>
                    <td>
                        <input [(ngModel)]="mId" size="12" maxlength="10">
                    </td>
                </tr>
                <tr>
                    <td><label class="bold">Master Project Name:</label></td>
                    <td>
                        <input [(ngModel)]="mName" size="12" maxlength="10">
                    </td>
                </tr>
                <tr>
                    <td><label class="bold">Awarding Agency ID:</label></td>
                    <td>
                        <input [(ngModel)]="aaId" maxlength="20">
                    </td>
                </tr>
                <tr>
                    <td><label class="bold">Awarding Agency Name:</label></td>
                    <td>
                        <input [(ngModel)]="aaName" maxlength="20">
                    </td>
                </tr>
                <tr>
                    <td><label class="bold">Awarding Agency Contract #:</label></td>
                    <td>
                        <input [(ngModel)]="aaContract" maxlength="20">
                    </td>
                </tr>
                <tr>
                    <td><label class="bold">General Contractor:</label></td>
                    <td>
                        <input [(ngModel)]="genContactor" maxlength="20">
                    </td>
                </tr>
                <tr>
                    <td><label class="bold">Street Address:</label></td>
                    <td>
                        <input [(ngModel)]="addrLine1" size="50" maxlength="80" title="First line of project address">
                    </td>
                </tr>
                <tr>
                    <td><label class="bold">City:</label></td>
                    <td>
                        <input [(ngModel)]="city" size="50" maxlength="50" title="Project city">
                    </td>
                </tr>
                <tr>
                    <td><label class="bold">Zip/Postal Code:</label></td>
                    <td>
                        <input [(ngModel)]="zip" size="12" maxlength="10" title="Project postal code"> (No dashes)
                    </td>
                </tr>
            </table>
       </div>
       <div *ngIf="searchProjectList.length > 0">
        <div style="float: right;">
            <pagination-controls (pageChange)="page = $event"></pagination-controls>
        </div>
        <table class="table table-striped" style="line-height: 0.9em;">
            <thead>
                <tr style="white-space: nowrap;">
                    <th scope="col">#</th>
                    <th scope="col">Project ID</th>
                    <th scope="col">Project Name</th>
                    <th scope="col">Street Address</th>
                    <th scope="col">City</th>
                    <th scope="col">Zip</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of searchProjectList | paginate: { itemsPerPage: 20, currentPage: page };let i = index">
                    <td>{{i + 1}}</td>
                    <td>
                        <button mat-button color="primary" class="projLink" (click)="searchProjectSelect(item)">
                            {{item.projectId}}
                        </button>
                    </td>
                    <td>{{item.projectName}}</td>
                    <td>{{item.addrLine1}}</td>
                    <td>{{item.city}}</td>
                    <td>{{item.zip}}</td>
                </tr>
            </tbody>
        </table>
       </div>
    </div>
    <div class="modal-footer">
        <button type="button" color="accent" mat-raised-button class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
        <button type="button" color="primary" mat-raised-button class="btn btn-outline-dark" (click)="search()">Search</button>
    </div>
</ng-template>