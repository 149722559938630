<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>
<body>
    <h3>
        Webmaster
    </h3>
    <span class="pageHeader">Webmaster Utilities Database</span>
    <ul>
		<li type="disc">
            <button mat-raised-button color="primary" (click)="activate('DatabaseSynchronizationAction');" title="Perform a selective refresh of the WebERF database with data from TRUSTpartner">Selective synchronize with TRUSTpartner</button>
        </li> 
        <li type="disc">
            <button mat-raised-button color="primary" (click)="activate('RefreshMViewsAction');" title="Perform a full refresh of the WebERF database with data from TRUSTpartner">Full synchronize with TRUSTpartner</button>
        </li>
    </ul>
</body>