import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModalOptions, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { Agreement } from 'src/app/model/announcement/agreement';
import { Announcement } from 'src/app/model/announcement/announcement';
import { Employer } from 'src/app/model/announcement/employer';
import { Global } from 'src/app/model/announcement/global';
import { AnnouncementService } from 'src/app/services/announcement/announcement.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'app-create-edit',
  templateUrl: './create-edit.component.html',
  styleUrls: ['./create-edit.component.scss'],
  providers: [DatePipe],
})
export class CreateEditComponent implements OnInit {

  modalOptions: NgbModalOptions;
  closeResult: string = '';
  @ViewChild('createAnnouncement') createAnnouncement : any;
  @ViewChild('deleteModal') deleteModal : any;
  partialDeleteIndex = -1;
  
  announcementType = 1;
  displayedColumns: string[] = ['id','title','type','status','eff', 'exp'];
  displayedAgreementColumns: string[] = ['id','name','remove'];
  displayedEmployerColumns: string[] = ['id','employer','remove'];
  dataSource = new MatTableDataSource<any>([]);
  agreementDataSource = new MatTableDataSource<any>([]);
  employerDataSource = new MatTableDataSource<any>([]);
  existingInfo:any;
  announcementObject:any;
  announcementText: Announcement= new Announcement();
  agreements:any;
  employers:any;

  gAnn: Global[] = [];
  aAnn: any[] = [];
  eAnn: any[] = [];
  tAnn: Announcement[] = [];

  loading$ = this.loader.loading$;
  constructor(public loader: SpinnerService,private modalService: NgbModal,private announcementService: AnnouncementService,
    public authService: AuthService,) { 
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
      size:'xl',
    };
  }

  ngOnInit(): void {
    this.loadData();
  }


  loadData(){
    this.announcementService
      .getAllAnnouncements()
      .subscribe(
        (response: any) => {
          console.log(response);
          this.existingInfo = response;
          this.tAnn = response.annList;
          this.gAnn = response.gAnnList;
          this.aAnn = response.aAnnList;
          this.eAnn = response.eAnnList;
          // Make dataSource not contain all of specific set but filter by announcement guids for AGREEMENTS/EMPLOYERS
          this.dataSource.data = response.gAnnList;
          this.setUpExistingAnnouncements();

          this.agreements = response.agreements;
          this.employers = response.employers;
          // Set agreementDataSource with default null data
          // this.agreementDataSource.data = [new Agreement()];
          // this.employerDataSource.data = [new Employer()];
          this.authService.refreshToken().subscribe((result)=> {}, () => {});
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      });
  }
  setUpExistingAnnouncements(){
    // console.log("setUpExistingAnnouncements");
    //Agreeements
    for(let i = 0; i < this.tAnn.length; i++){
      const result = this.aAnn.filter((obj) => {
        return obj.announcement.guid === this.tAnn[i].guid;
      });
      if(result.length > 0){
        this.dataSource.data = this.dataSource.data.concat(result[0]);
      }
      // console.log(result);
    }
    //Employer
    for(let i = 0; i < this.tAnn.length; i++){
      const result = this.eAnn.filter((obj) => {
        return obj.announcement.guid === this.tAnn[i].guid;
      });
      if(result.length > 0){
        this.dataSource.data = this.dataSource.data.concat(result[0]);
      }
      // console.log(result);
    }
  }

  open(content: any) {
    console.log('open');
    this.modalService.open(content, this.modalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  typeName(num:any,data:any){
    if(data == null){
      if(num == 1){
        return "Global";
      } else if(num == 2){
        return "Agreement";
      } else {
        return "Employer";
      }
    } else {
      if(data.includes("AnnouncementGlobalVO")){
        return "Global";
      } else if(data.includes("AnnouncementAgreementVO")){
        return "Agreement-Specific";
      } else {
        return "	Employer-Specific";
      }
    }
  }
  clearData(){
    this.announcementObject = null;
    if(this.announcementType == 2){//Agreement
      this.announcementObject = new Agreement();
      // this.agreementDataSource.data = [new Agreement()];
      this.agreementDataSource.data = [];
    }else if(this.announcementType == 3){//Employer
      this.announcementObject = new Employer();
      // this.employerDataSource.data = [new Employer()];
      this.employerDataSource.data = [];
    }else{//Global
      this.announcementObject = new Global();
    }
    this.announcementText = new Announcement();
  }
  // Set data used to save informatino
  editAnnouncement(row:any){
    var strType = this.typeName(null,row.mid);
    if(strType == "Global"){
      this.announcementType = 1;
    }else if(strType == "Agreement-Specific"){
      this.announcementType = 2;
      //Get Agreements connected to this Announcement
      this.setUpAgreementDataSource(row.announcement.guid);
    }else{
      this.announcementType = 3;
      //Get Employers connected to this Announcement
      this.setUpEmployerDataSource(row.announcement.guid);
    }
    this.announcementObject = row;
    this.announcementText = this.announcementObject.announcement;
    console.log(this.announcementObject);
    console.log(this.announcementText);
    this.open(this.createAnnouncement);
  }
  saveGlobalData(){
    if(!this.validation()){
      return;//Bad Data
    }
    // Set announcement,effDate, expDate for announcementObject = announcementText
    this.announcementObject.announcement = this.announcementText;
    this.announcementObject.effDate = this.announcementText.effDate;
    this.announcementObject.expDate = this.announcementText.expDate;
    console.log(this.announcementObject);
    this.announcementService
      .saveGlobalData(this.announcementObject)
      .subscribe(
        (response: any) => {
          console.log(response);
          alert(response.message);
          this.modalService.dismissAll();
          this.loadData();
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      });
  }
  saveEmployerData(){
    if(!this.validation()){
      return;//Bad Data
    }
    // Set announcement,effDate, expDate for announcementObject = announcementText
    for(let i = 0; i < this.employerDataSource.data.length; i++){
      this.employerDataSource.data[i].announcement = this.announcementText;
      this.employerDataSource.data[i].effDate = this.announcementText.effDate;
      this.employerDataSource.data[i].expDate = this.announcementText.expDate;
    }
    // console.log(this.announcementObject);
    //Remove Empty employers
    this.employerDataSource.data = this.employerDataSource.data.filter((obj) => obj.erGUID !== null); 
    this.announcementService
      .saveEmployerData(this.employerDataSource.data)
      .subscribe(
        (response: any) => {
          // console.log(response);
          alert(response.message);
          this.modalService.dismissAll();
          this.loadData();
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      });
  }
  saveAgreementData(){
    if(!this.validation()){
      return;//Bad Data
    }
    // Set announcement,effDate, expDate for announcementObject = announcementText
    for(let i = 0; i < this.agreementDataSource.data.length; i++){
      this.agreementDataSource.data[i].announcement = this.announcementText;
      this.agreementDataSource.data[i].effDate = this.announcementText.effDate;
      this.agreementDataSource.data[i].expDate = this.announcementText.expDate;
    }
    // console.log(this.agreementDataSource.data);
    //Remove Empty employers
    this.agreementDataSource.data = this.agreementDataSource.data.filter((obj) => obj.cbaGUID !== null); 
    this.announcementService
      .saveAgreementData(this.agreementDataSource.data)
      .subscribe(
        (response: any) => {
          // console.log(response);
          alert(response.message);
          this.modalService.dismissAll();
          this.loadData();
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      });
  }
  getStatus(data:any){
    var effDate = data.announcement.effDate;
    var expDate = data.announcement.expDate;
    var today = new Date();
    
    if(expDate == null){
      if(new Date(effDate!) <= today && expDate == null){
        return 'Active';
      }
    } else {
      if(new Date(effDate!) < today && new Date(expDate) > today){
        return 'Active';
      }
    }
    return 'In-Active';
  }
  agreementChange(event:any){
    const value = event.target.value;
    console.log(event);
    console.log(value);
  }
  employerChange(event:any){
    const value = event.target.value;
    console.log(event);
    console.log(value);
  }
  setUpAgreementDataSource(num:any){
    const result = this.aAnn.filter((obj) => {
      return obj.announcement.guid === num;
    });
    this.agreementDataSource.data = result;
    // this.agreementDataSource.data = this.agreementDataSource.data.concat(new Agreement());
    console.log(this.agreementDataSource.data);
  }
  setUpEmployerDataSource(num:any){
    const result = this.eAnn.filter((obj) => {
      return obj.announcement.guid === num;
    });
    this.employerDataSource.data = result;
    // this.employerDataSource.data = this.employerDataSource.data.concat(new Employer());
    console.log(this.employerDataSource.data);
  }
  addToAgreementDataSource(){
    this.agreementDataSource.data = this.agreementDataSource.data.concat(new Agreement());
  }
  addToEmployerDataSource(){
    this.employerDataSource.data = this.employerDataSource.data.concat(new Employer());
  }
  validation(){
    if(this.announcementText.header == "" || this.announcementText.header == null) {
      alert("Enter Announcement Title ");
      return false;
    }
    if(this.announcementText.header.length > 300 ) {
      alert( "Announcement Title length must be less than 300 characters");
      return false;
    }
    if(this.announcementText.message == "" || this.announcementText.message == null) {
      alert("Enter Announcement Text ");
      return false;
    }
    if(this.announcementText.message.length > 4000 ) {
      alert("Announcement Text must be less than 4000 characters");
      return false;
    }
    if(this.announcementText.footer != null && this.announcementText.footer.length > 300 ) {
      alert("Announcement Footer must be less than 300 characters");
      return false;
    }
    if(this.announcementText.urlTitle != null && this.announcementText.urlTitle.length > 300 ) {
      alert("Name/Title of Associated File or URL must be less than 300 characters");
      return false;
    }
    if(this.announcementText.effDate == null || this.announcementText.effDate == undefined){
      alert("Must have an effective date.");
      return false;
    }
    if(this.announcementText.expDate != null && this.announcementText.effDate > this.announcementText.expDate){
      alert("Expiration date must be after effective date.");
      return false;
    }

    //Agreement
    if(this.announcementType == 2 && this.agreementDataSource.data.length == 0){
      alert("At least one agreement must be attached to this announcement.");
      return false
    }
    if(this.announcementType == 2 && this.agreementDataSource.data.length > 0){
      var tmp:any[] = this.agreementDataSource.data.filter((obj) => obj.cbaGUID == null);
      if(tmp.length > 0){
        alert(tmp.length + " Agreement(s) was not choosen, select a value or delete empty Agreements.");
        return false;
      }
    }
    //Employer
    if(this.announcementType == 3 && this.employerDataSource.data.length == 0){
      alert("At least one employer must be attached to this announcement.");
      return false;
    }
    if(this.announcementType == 3 && this.employerDataSource.data.length > 0){
      var tmp:any[] = this.employerDataSource.data.filter((obj) => obj.erGUID == null);
      if(tmp.length > 0){
        alert(tmp.length + " Employer(s) was not choosen, select a value or delete empty Employers.");
        return false;
      }
    }
    return true;
  }

  checkDeleteItem(row:any,index:any){
    console.log(index);
    console.log(row);
    if(row.guid != null){
      this.partialDeleteIndex = index;
    } else {
      this.partialDeleteIndex = -1;
    }
    //Just remove it since it hasnt been saved
    if(this.partialDeleteIndex == -1){
      if(this.announcementType == 2){//Agreement
        this.agreementDataSource.data = this.agreementDataSource.data.filter(i => i !== row)
      }
      if(this.announcementType == 3){//Employer
        this.employerDataSource.data = this.employerDataSource.data.filter(i => i !== row)
      }
    }else{
      this.modalService.dismissAll();
      this.open(this.deleteModal);
    }
  }
  showSingleDelteItem(){
    var detail: any;
    if(this.announcementType == 2){//Agreement
      detail = this.agreementDataSource.data[this.partialDeleteIndex].cbaGUID;
      return this.agreements.filter((obj: { agreement: { masterAgreementGUID: any; }; }) => obj.agreement.masterAgreementGUID == detail);
    }
    if(this.announcementType == 3){//Employer
      detail = this.employerDataSource.data[this.partialDeleteIndex].erGUID;
      return this.employers.filter((obj: { guid: any; }) => obj.guid == detail);
    }
    return;
  }
  fullDelete(){
    this.partialDeleteIndex = -1;
    this.open(this.deleteModal);
  }

  deleteGlobal(){
    this.announcementService
      .deleteGlobalData(this.announcementObject)
      .subscribe(
        (response: any) => {
          console.log(response);
          alert(response.message);
          this.modalService.dismissAll();
          this.loadData();
      },
      (error: HttpErrorResponse) => {
        alert(error.message);
      });
  }
  deleteEmployer(all:boolean){
    if(all){//delete all
      this.announcementService
        .deleteEmployerData(this.employerDataSource.data)
        .subscribe(
          (response: any) => {
            console.log(response);
            alert(response.message);
            this.modalService.dismissAll();
            this.loadData();
        },
        (error: HttpErrorResponse) => {
          alert(error.message);
        });
    } else {
      // delete single
      var tmp:any[] = [];
      tmp.push( this.employerDataSource.data[this.partialDeleteIndex]);
      this.announcementService
        .deleteEmployerData(tmp)
        .subscribe(
          (response: any) => {
            console.log(response);
            alert(response.message);
            this.modalService.dismissAll();
            this.loadData();
        },
        (error: HttpErrorResponse) => {
          alert(error.message);
        });
    }
  }
  deleteAgreement(all:boolean){
    if(all){//delete all
      this.announcementService
        .deleteAgreementData(this.agreementDataSource.data)
        .subscribe(
          (response: any) => {
            console.log(response);
            alert(response.message);
            this.modalService.dismissAll();
            this.loadData();
        },
        (error: HttpErrorResponse) => {
          alert(error.message);
        });
    } else {
      // delete single
      var tmp:any[] = [];
      tmp.push( this.agreementDataSource.data[this.partialDeleteIndex]);
      this.announcementService
        .deleteAgreementData(tmp)
        .subscribe(
          (response: any) => {
            console.log(response);
            alert(response.message);
            this.modalService.dismissAll();
            this.loadData();
        },
        (error: HttpErrorResponse) => {
          alert(error.message);
        });
    }
  }
}
