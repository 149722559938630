export class TermsofuseModule { 

  public guid: number | null;
  public adminServiceEnvGUID: number | null;
  public title: string | null;
  public paragraph: string | null;
  public inUse: boolean | null;
  public mid: string | null;
  public obsolete: boolean | null;
  public updateRequired: boolean | null;
  public createdByGUID: number | null;
  public createdByID: number | null;
  public createdDate: Date | null;
  public lastUpdateByGUID: number | null;
  public lastUpdateByID: number | null;
  public lastUpdateDate: Date | null;
  

  constructor(){
    this.guid = null;
    this.adminServiceEnvGUID = null;
    this.title = null;
    this.paragraph = null;
    this.inUse = false;
    this.mid = null;
    this.obsolete = false;
    this.updateRequired = null;
    this.createdByGUID = null;
    this.createdByID = null;
    this.createdDate = null;
    this.lastUpdateByGUID = null;
    this.lastUpdateByID = null;
    this.lastUpdateDate = null;
  }
}
