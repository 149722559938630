import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './services/auth/auth.service';
import {
  Observable,
  map,
  Subject,
  of,
  timer,
  Subscription,
  interval,
} from 'rxjs';
import { delay, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  // If user is idle for 25 minutes this page will show
  //  IDLE Popup, after 5 Minutes user logged out
  //

  title = 'TrustPartnerFE';

  @ViewChild('idleModal') idleModal: any;
  @ViewChild('loggedOutModal') loggedOutModal: any;
  closeResult: string = '';
  timerSubs!: Subscription;
  alertCount = 0;
  alert = false;

  constructor(public authService: AuthService, private modalService: NgbModal) {
    this.authService.getUserLoggedIn().subscribe((userLoggedIn) => {
      if (userLoggedIn) {
        this.authService.getAlertExpire().subscribe((alert) => {
          if (alert && this.alertCount == 0) {
            this.alertCount++;
            this.modalService.dismissAll();
            this.open(this.idleModal);
            this.alert = true;
            this.calculateTimeForTimer();
          }
        });
      }
    });
  }

  open(content: any) {
    console.log('open');
    this.modalService
      .open(content, {
        backdrop: 'static',
        backdropClass: 'idle',
        windowClass: 'idle',
        size: 'l',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  logout() {
    this.authService.signout();
    this.resetAlertCounter();
  }

  resetAlertCounter() {
    this.alertCount = 0;
    this.alert = false;
  }
  refresh(){
    this.authService.refreshToken().subscribe((result)=> {}, () => {});
  }
  // after idle popup
  // after 5 minutes from then user is logged out
  calculateTimeForTimer() {
    let expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 600 * 1000); // 10 minutes from now.
    let alertDate = new Date(expirationDate);
    alertDate.setTime(alertDate.getTime() - 300 * 1000); // 5 minutes before expiration
    let timeDuration = expirationDate.getTime() - alertDate.getTime(); // 5 minutes before expiration
    this._expTimer(timeDuration);
  }
  //Dont let user be logged out if token has been refreshed
  private _expTimer(exp: any) {
    if (this.timerSubs) {
      this.timerSubs.unsubscribe();
    }
    this.timerSubs = timer(exp).subscribe((data) => {
      if (this.alert) {
        this.logout();
        this.modalService.dismissAll();
        this.open(this.loggedOutModal);
      }
    });
  }
}
