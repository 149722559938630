import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map, Subject, of, timer, Subscription } from 'rxjs';
import { Request } from '../../model/auth/request';
import { environment } from 'src/environments/environment';
import { catchError, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // This file is used to track if user is idle --- along with app.component.ts
  // 	if idle alert then log them out if time limit reached
  //	This also sets users session cookies
  //	ALERT at 25 MINUTES IDLE
  //	FORCE LOGOUT AT 30 MINUTES IDLE --- app.component.ts
  //
  //	IDLE restarts with every refreshToken()

  private baseUrl = environment.apiBaseUrl + '/spring';
  private userLoggedIn = new Subject<boolean>();
  private expired = new Subject<boolean>();
  private alertExpire = new Subject<boolean>();

  private userMustAcceptTermsOfUse:boolean = false;

  timerSubs!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient
  ) {
    this.userLoggedIn.next(false);
    this.expired.next(false);
    this.alertExpire.next(false);
  }

  signin(request: Request): Observable<any> {
    return this.http
      .post<any>(this.baseUrl + '/signin', request, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        map((resp) => {
          if(resp.result.token != null){
            sessionStorage.setItem('role', resp.result.role);
            sessionStorage.setItem('token', 'HTTP_TOKEN ' + resp.result.token);
            sessionStorage.setItem('username', resp.result.username);
            sessionStorage.setItem('manyRoles', resp.result.manyRoles);
            sessionStorage.setItem('roleList', JSON.stringify(resp.result.roleList));
            sessionStorage.setItem('pGUID',resp.result.pGUID);
            sessionStorage.setItem('t',resp.result.userMustAcceptTermsOfUse);
            this.setUserLoggedIn(true);
            this.setExpired(false);
            this.setAlertExpire(false);
            this.calculateTimeForTimer();
            this.setUserMustAcceptTermsOfUse(resp.result.userMustAcceptTermsOfUse);
          }
          return resp;
        })
      );
  }
  refreshToken(): Observable<any> {
    let Params = new HttpParams();
    Params = Params.append('username', this.getUsername());
    Params = Params.append('userType', 'W');
    return this.http
      .post<any>(this.baseUrl + '/refreshToken', this.getUsername(), {
        params: Params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      })
      .pipe(
        map((resp) => {
          // sessionStorage.setItem('role', resp.role);
          sessionStorage.setItem('token', 'HTTP_TOKEN ' + resp.token);
          sessionStorage.setItem('username', resp.username);
          if(resp.pGUID != null){
            sessionStorage.setItem('pGUID',resp.pGUID);
          }
          this.setUserLoggedIn(true);
          this.setExpired(false);
          this.setAlertExpire(false);
          this.calculateTimeForTimer();
          return resp;
        })
      );
  }
  changePassword(oldPass:any,newPass:any): Observable<string> {
    let Params = new HttpParams();
    Params = Params.append('oldPass', oldPass);
    Params = Params.append('newPass', newPass);
    return this.http.post<any>(`${this.baseUrl}/changePw`,'',{ params: Params });
  }
  checkPasswordExpired(): Observable<string> {
    let Params = new HttpParams();
    Params = Params.append('username', this.getUsername());
    return this.http.post<any>(`${this.baseUrl}/checkPasswordExpired`,'',{ params: Params });
  }
  agreeToTermsOfUse(termGUID: any): Observable<any>{
    let Params = new HttpParams();
    Params = Params.append('termGUID', termGUID);
    return this.http.post(`${this.baseUrl}/agreeToTermsOfUse`,'',{ params: Params });
	}
  changeRole(r:string){
    sessionStorage.setItem('role', 'WEBERF'+r);
  }

  signout() {
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('manyRoles');
    sessionStorage.removeItem('roleList');
    sessionStorage.removeItem('pGUID');
    sessionStorage.removeItem('t');
    sessionStorage.removeItem('cp');
    this.setUserLoggedIn(false);
    this.setExpired(true);
    this.router.navigateByUrl('login');
  }

  isUserSignedin() {
    return sessionStorage.getItem('token') !== null;
  }

  getToken() {
    let token = sessionStorage.getItem('token') as string;
    return token;
  }

  getUsername() {
    return sessionStorage.getItem('username') as string;
  }
  getManyRoles() {
    return sessionStorage.getItem('manyRoles') == 'true' ? true : false;
  }
  getRoleList() {
    return JSON.parse(sessionStorage.getItem('roleList') as string);
  }

  getSignedinUserRole() {
    return sessionStorage.getItem('role') as string;
  }
  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }
  setExpired(expired: boolean) {
    this.expired.next(expired);
  }

  getExpired(): Observable<boolean> {
    return this.expired.asObservable();
  }

  setAlertExpire(alertExpire: boolean) {
    this.alertExpire.next(alertExpire);
  }

  getAlertExpire(): Observable<boolean> {
    return this.alertExpire.asObservable();
  }

  getPGUID() {
    return sessionStorage.getItem('pGUID') as string;
  }
  setPGUID(pGUID:any){
    sessionStorage.setItem('pGUID',pGUID);
  }

  calculateTimeForTimer() {
    let expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 1800 * 1000); // 30 Minutes from now.
    let alertDate = new Date(expirationDate);
    alertDate.setTime(alertDate.getTime() - 1500 * 1000); // 25 minutes before expiration
    let timeDuration = expirationDate.getTime() - alertDate.getTime();// 5 minutes before expiration
    this._startTimer(timeDuration, expirationDate.getTime());
  }

  setUserMustAcceptTermsOfUse(val:any){
    sessionStorage.setItem('t',val);
  }
  getUserMustAcceptTermsOfUse(){
    return (sessionStorage.getItem('t') as string) == 'true' ? true : false;
  }
  //cp=changepass
  setPassExp(val:any){
    sessionStorage.setItem('cp',val);
  }
  getPassExp(){
    return (sessionStorage.getItem('cp') as string) == 'true' ? true : false;
  }

  private _startTimer(alert: any, exp: any) {
    if (this.timerSubs) {
      this.timerSubs.unsubscribe();
    }

    this.timerSubs = timer(alert).subscribe((data) => {
      this.setAlertExpire(true);
    });
  }
}
