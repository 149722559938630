<ng-container *ngIf="loading$ | async">
    <app-is-loading></app-is-loading>
</ng-container>

<body>
    <h3>
        Webmaster
    </h3>
    <span class="pageHeader">Terms of Use (Maintenance)</span>

    <div class="topDiv">
    </div>

    <div class="bottomDiv">
        <span class="pageHeader">Existing Terms of Use</span>
        <p class="topParagraph">To edit an existing record, double-click the row within the list.</p>
        <button class="createBtn" mat-raised-button color="primary" (click)="clearData();open(createTerms)">Create</button>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 existingTerms">

            <!-- # Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Identifier </th>
                <td mat-cell *matCellDef="let element;let i = index">
                    {{element.createdDate | date:'YYYYMMddHHmm'}}
                </td>
            </ng-container>
            <!-- Title Column -->
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> Title </th>
                <td mat-cell *matCellDef="let element">
                    {{element.title }}
                </td>
            </ng-container>
            <!-- Status Column -->
            <ng-container matColumnDef="inUse">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">
                    {{element.inUse == false ? "Inactive" : "Active"}}
                </td>
            </ng-container>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length" style="background-color: lightcoral;">
                  No Terms of Use Created Yet.
                </td>
              </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr><!--sticky: true //removed for visual fix with modal-->
            <tr class="rowHover" mat-row *matRowDef="let row; columns: displayedColumns;" (dblclick)="editTerms(row)"></tr>
        </table>
    </div>
</body>


<!-- Create Announcement Modal -->
<ng-template #createTerms let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create/Edit <label style="color:blue">Terms Of Use</label></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Be sure to preview the <label style="color:blue">Terms Of Use</label> and check capitalization, spelling and grammar before saving.&nbsp;
            Fields marked with an asterisk (&#8220;<span class="required">*</span>&#8221;) are required.<br/>
            <label style="color: red;">Use Preview mode to see the application of HTML tags.</label>
        </p>
        <table>
            <tr>
                <td><label for="title">Title<span class="required">*</span>:</label></td>
              </tr>
            <tr>
                <td>
                    <textarea name="annTxtHeader" maxlength="100" cols="147" rows="1" id="title" title="Title of Terms Of Use" [(ngModel)]="termsObject.title">{{termsObject.title}}</textarea>
                    <mat-hint align="end" style="float: right;">{{termsObject.title?.length || 0}}/100</mat-hint>
                </td>
            </tr>

            <tr>
              <td><label for="title">Body<span class="required">*</span>:</label></td>
            </tr>
            <tr>
                <td>
                   <textarea name="annTxtHeader" maxlength="4000" cols="147" rows="20" id="title" title="Body of Term Of Use" [(ngModel)]="termsObject.paragraph">{{termsObject.paragraph}}</textarea>
                   <mat-hint align="end" style="float: right;">{{termsObject.paragraph?.length || 0}}/4000</mat-hint>
                </td>
            </tr>

            <tr>
              <td><label for="title">Footer<span class="required">*</span>:</label></td>
            </tr>
            <tr>
                <td>
                   <textarea name="annTxtHeader" maxlength="1000" cols="147" rows="6" id="title" title="Footer On Terms Of Use" [(ngModel)]="termsObject.footer">{{termsObject.footer}}</textarea>
                   <mat-hint align="end" style="float: right;">{{termsObject.footer?.length || 0}}/1000</mat-hint>
                </td>
            </tr>

            <tr>
              <td><label for="title">Status:</label></td>
            </tr>
            <tr>
                <mat-radio-group [(ngModel)]="termsObject.inUse">
                  <mat-radio-button class="example-margin" [value]=true [checked]="true" color="primary">Active</mat-radio-button>
                  <mat-radio-button class="example-margin" [value]=false [checked]="false" color="accent">Inactive</mat-radio-button>
                </mat-radio-group>
            </tr>

        </table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" mat-raised-button color="warn" style="margin-right: auto;" (click)="obsoleteTerms();">Delete</button>
        <button type="button" class="btn btn-outline-dark" mat-raised-button color="primary" (click)="modal.dismiss('Cross click');open(previewModal)">Preview</button>
        <button type="button" class="btn btn-outline-dark" mat-raised-button color="primary" (click)="saveTermsOfUse();">Save</button>
    </div>
</ng-template>


<!-- Preview Modal -->
<ng-template #previewModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Preview</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');open(createTerms)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body announcements annContainer">
        <!-- <app-header ></app-header> -->
        <div>
            <div [innerHTML]="termsObject.title"></div>
            <div style="text-align: left;" [innerHTML]="termsObject.paragraph"></div>
            <div [innerHTML]="termsObject.footer"></div>
            <div style="float: left; padding: 16px;"><button type="button" class="btn btn-outline-dark" mat-raised-button color="primary">I Agree</button></div>
        </div>
        <!-- <app-footer></app-footer> -->
    </div>
</ng-template>