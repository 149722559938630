<body *ngIf="employer">

    <ng-container *ngIf="loading$ | async">
        <app-is-loading></app-is-loading>
    </ng-container>

    <ng-container *ngIf="isEmployerOrStaff()">
        <h3> {{employer.organizationName}} : {{employer.organizationID}}</h3>
        <span class="pageHeader">WebERF Home</span>
    </ng-container>
    <ng-container *ngIf="isAdmin()">
        <h3> System Administrator</h3>
        <span class="pageHeader">WebERF Home</span>
    </ng-container>
    <ng-container *ngIf="isWebMaster()">
        <h3>Webmaster</h3>
        <span class="pageHeader">Webmaster Options Home</span>
    </ng-container>
    <ng-container *ngIf="isLiaison()">
        <h3>Employer Liaison</h3>
        <span class="pageHeader">WebERF Home</span>
    </ng-container>
    <ng-container *ngIf="isAuditor()">
        <h3>Auditor</h3>
        <span class="pageHeader">WebERF Home</span>
    </ng-container>
    
    <p *ngIf="systemParameters[0] != null && systemParameters[0] != undefined">Welcome to WebERF, the {{systemParameters[0].parameterValue1}} electronic Employer Report Form submission
        facility.
    </p>
    <!-- <ng-container *ngIf="systemParameters.length > 0">
        <span class="pageHeader">WebERF Home</span>
        <p>Welcome to WebERF, the {{systemParameters[0].parameter_VALUE}} electronic Employer Report Form submission
            facility.
        </p>

        <br />
        <table width="100%">
            <tr>
                <td width="100%">
                    <p>Select an option below:</p>
                    <ul>
                        <ng-container *ngIf="isEmployerOrStaff()">
                            <li><a href="changePW">Change User ID / Password</a></li>
                        </ng-container>

                        <ng-container *ngIf="isAdmin() || isAuditor()">
                            <li><a href="selectEmployer">Select Employer</a></li>
                        </ng-container>

                        <li><a href="reportOptions">Create a New Report</a></li>

                        <li><a href="listReports">View List of Pended and Finalized Reports</a></li>

                        <ng-container *ngIf="isAffirmative(systemParameters[2].parameter_VALUE)">
                            <li><a href="adminReports">Employer Administrative Reports</a></li>
                        </ng-container>
                        <ng-container *ngIf="isAffirmative(systemParameters[3].parameter_VALUE)">
                            <li><a href="ErCbaView">View Agreements and Rates</a></li>
                        </ng-container>

                        <ng-container *ngIf="isAffirmative(systemParameters[4].parameter_VALUE)">
                            <ng-container *ngIf="isEmployerOrStaff()">
                                <li><a href="listProject">Add/Edit Employer Projects</a></li>
                            </ng-container>
                            <ng-container *ngIf="isEmployerOrStaff()">
                                <li><a href="listProject">Add/Edit Projects</a></li>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="isAffirmative(systemParameters[5].parameter_VALUE) && isEmployer()">
                            <li><a href="staffSetup">Setup / Edit Staff User Accounts</a>
                        </ng-container>

                        <ng-container *ngIf="isAffirmative(systemParameters[7].parameter_VALUE) && isEmployerOrStaff()">
                            <li>
                                <a href="UpdateErBankAccount">Setup / Edit Employer EFT Account Details</a>&nbsp;&nbsp;
                            </li>
                        </ng-container>

                        <ng-container *ngIf="systemParameters[1].parameter_VALUE != '' && isEmployerOrStaff()">
                            <li>
                                <div class="link" [innerHTML]="systemParameters[1].parameter_VALUE"></div>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="isAffirmative(systemParameters[8].parameter_VALUE)">
                            <li><a href="employeeDemographics">Employee Demographics</a></li>
                        </ng-container>
                    </ul>
                </td>
            </tr>
        </table>
    </ng-container> -->

    <!-- Start Announcements -->
    <div class="announcements annContainer">
        <p class="announcehead">Announcements</p>

        <ng-container *ngIf="announcements.length == 0">
            <p class="center">No Current Announcements</p>
        </ng-container>
        <ng-container *ngFor="let ann of announcements;let i = index">
            <div class="annBlock annBackGround">
                <!-- <p class="announcetitle annBackGround">{{ann.header}}</p>
                <p class="announcetext annBackGround">{{ann.message}}</p>
                <ng-container *ngIf="ann.url != null && ann.urlTitle != null">
                    <p class="announceurl annBackGround">
                        <a href="{{ann.url}}" target="_blank">{{ann.urlTitle}}</a>
                    </p>
                </ng-container>
                <ng-container *ngIf="ann.footer != null">
                    <p class="announcefooter annBackGround">{{ann.footer}}</p>
                </ng-container> -->
                <p class="announcetitle annBackGround" [innerHTML]="ann.header"></p>
                <p class="announcetext annBackGround" [innerHTML]="ann.message"></p>
                <ng-container *ngIf="ann.url != null && ann.urlTitle != null">
                    <p class="announceurl annBackGround" >
                        <a href="{{ann.url}}" target="_blank" [innerHTML]="ann.urlTitle"></a>
                    </p>
                </ng-container>
                <ng-container *ngIf="ann.footer != null">
                    <p class="announcefooter annBackGround" [innerHTML]="ann.footer"></p>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <!-- end announcements -->
</body>




<!-- 
    <body>
    <ng-container *ngIf="systemParameters.length > 0">
        <span class="pageHeader">WebERF Home</span>
        <p>Welcome to WebERF, the {{systemParameters[0].parameter_VALUE}} electronic Employer Report Form submission
            facility.
        </p>

        <br />
        <table width="100%">
            <tr>
                <td width="100%">
                    <p>Select an option below:</p>
                    <ul>
                        <ng-container *ngIf="hasEmployerOrStaffRole == 'YES'">
                            <li><a href="changePW">Change User ID / Password</a></li>
                        </ng-container>

                        <ng-container *ngIf="isRoleAdmin || isRoleAuditor">
                            <li><a href="selectEmployer">Select Employer</a></li>
                        </ng-container>

                        <li><a href="createNewReport">Create a New Report</a></li>

                        <li><a href="listReports">View List of Pended and Finalized Reports</a></li>

                        <ng-container *ngIf="isAffirmative(systemParameters[2].parameter_VALUE)">
                            <li><a href="adminReports">Employer Administrative Reports</a></li>
                        </ng-container>
                        <ng-container *ngIf="isAffirmative(systemParameters[3].parameter_VALUE)">
                            <li><a href="ErCbaView">View Agreements and Rates</a></li>
                        </ng-container>

                        <ng-container *ngIf="isAffirmative(systemParameters[4].parameter_VALUE)">
                            <ng-container *ngIf="hasEmployerOrStaffRole != 'YES'">
                                <li><a href="listProject">Add/Edit Employer Projects</a></li>
                            </ng-container>
                            <ng-container *ngIf="hasEmployerOrStaffRole == 'YES'">
                                <li><a href="listProject">Add/Edit Projects</a></li>
                            </ng-container>
                        </ng-container>

                        <ng-container
                            *ngIf="isAffirmative(systemParameters[5].parameter_VALUE) && hasEmployerRole == 'YES'">
                            <li><a href="staffSetup">Setup / Edit Staff User Accounts</a>
                        </ng-container>

                        <ng-container *ngIf="allowEFT && hasEmployerOrStaffRole == 'YES'">
                            <li>
                                <a href="UpdateErBankAccount">Setup / Edit Employer EFT Account Details</a>&nbsp;&nbsp;
                            </li>
                        </ng-container>

                        <ng-container *ngIf="systemParameters[1].parameter_VALUE != '' && isRoleEmployerOrStaff">
                            <li>
                                <div [innerHTML]="systemParameters[1].parameter_VALUE"></div>
                            </li>
                        </ng-container>
                    </ul>
                </td>
            </tr>
        </table>
    </ng-container>


    <div class="announcements annContainer">
        <p class="announcehead">Announcements</p>

        <ng-container *ngIf="announcements.length == 0">
            <p class="center">No Current Announcements</p>
        </ng-container>
        <ng-container *ngFor="let ann of announcements;let i = index">
            <p class="announcetitle">{{ann.header}}</p>
            <p class="announcetext">{{ann.message}}</p>
            <ng-container *ngIf="ann.url != null && ann.urlTitle != null">
                <p class="announceurl">
                    <a href="{{ann.url}}" target="_blank">{{ann.urlTitle}}</a>
                </p>
            </ng-container>
            <ng-container *ngIf="ann.footer != null">
                <p class="announcefooter">{{ann.footer}}</p>
            </ng-container>
        </ng-container>
    </div>

</body>



 -->