import { Announcement } from "./announcement";

export class Agreement {
    public cbaGUID: number | null;
    public masterAgreement: number | null;
    public active: boolean | null;
    public adminServiceEnvGUID: number | null;
    public announcement: Announcement;
    public effDate: string | null;
    public expDate: string | null;
    public guid: number | null;
    public mid: string | null;
    public obsolete:  boolean | null;
    public preview:  boolean | null;
    public updateRequired: boolean | null;

    constructor(){
        this.cbaGUID = null;
        this.masterAgreement = 1;
        this.active = true;
        this. adminServiceEnvGUID = null;
        this.announcement = new Announcement();
        this.effDate = null;
        this.expDate = null;
        this.guid = null;
        this.mid = null;
        this.obsolete = false;
        this.preview = false;
        this.updateRequired = true;
    }
}
